import React from 'react';
import styled from 'styled-components';
import { Typo, Flex } from '../../ui';
import { media } from '../../utils/media';

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 1rem;

  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:20px;
  }
`;

const PageHeader = ({ children, title }) => {

  return (
    <Header>
      {title && <Flex>
        <Typo.ContentSmallHeader>{title}</Typo.ContentSmallHeader>
      </Flex>}
      {children}
    </Header>
  );
};

export default PageHeader;
