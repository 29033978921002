import Reflux from 'reflux';

const DiscountActions = Reflux.createActions([
  'read',
  'readComplete',
  'create',
  'createComplete',
  'update',
  'updateComplete',
  'findById',
  'findByIdError',
  'findByIdComplete',
  'remove',
  'removeComplete',
  'removeError',
  'genericError'
]);

export default DiscountActions;
