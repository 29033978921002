import Reflux from 'reflux';

const SettingsActions = Reflux.createActions([
  'save',
  'saveComplete',
  'read',
  'saveFrontend',
  'saveFrontendComplete',
  'readComplete',
  'update',
  'updateComplete',
  'genericError'
]);

export default SettingsActions;
