import Reflux from 'reflux';

const AppActions = Reflux.createActions([
  'expandAppBar',
  'collapseAppBar',
  'toggleAppBar',
  'hideAppBar',
  'showSearchBar',
  'hideSearchBar',
  'setTitle',
  'search',
  'switchToAppMenu',
  'switchToStudentMenu',
  'genericError',
  'translatableMessage',
  'notify',
  'toggleToc',
  'showReportBugDialog',
  'hideReportBugDialog',
  'reportBug'
]);

export default AppActions;
