import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {  Flex, Modal, Textinput, Typo } from '../../../../ui';

const EditIntroduction = ({ open, info, onClose, addToPreview }) => {

  const { t } = useTranslation();
  
  const [introductionInfo, setIntroductionInfo] = useState();

  useEffect(() => {
    setIntroductionInfo(info)
  }, [info])
 
  const editIntroductionInfo = useCallback(e => {
    setIntroductionInfo({ ...introductionInfo, [e.target.dataset.propname]: e.target.value });
  }, [introductionInfo]);


  const onAccept = useCallback(() => {
    addToPreview(introductionInfo)
  }, [introductionInfo, addToPreview]);

  return (
    <Modal
      open={open} 
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel='Add to preview'
    >
      <Flex direction='column' width='100%' align='flex-start' gap='10'>
        <Typo.SectionHeader $marginBottom='10'>{t('webshop.build.introductionTitle')}</Typo.SectionHeader>
        <Textinput
          label={t('globals.title')}
          propName={'title'}
          defaultValue={introductionInfo?.title}
          onBlurValue={editIntroductionInfo}
        />
        <Textinput
          label={t('globals.description')}
          propName={'text'}
          defaultValue={introductionInfo?.text}
          onBlurValue={editIntroductionInfo}
          isMultiline
        />
      </Flex>
    </Modal> 
  );
};

export default EditIntroduction;
