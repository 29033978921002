import React from 'react';
import styled from 'styled-components'
// import { useTranslation } from 'react-i18next';

import Typo from '../Typo';
import { media } from '../../utils/media';
import Progress from '../Progress';
import Flex from '../Flex';
import { Avatar } from '..';
import { getUserThemeColor } from '../theme/colors';

const CourseInformation = styled.div`
  padding: ${({$extraPadding})=> $extraPadding ? '3rem 1rem 1rem': '1rem'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap:10px;
  

  ${media.lessThan('tablet')} {
    height: auto;
  }
`;

const Description = styled(Typo.SmallText)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
`;

const LogoWrapper = styled.figure`
    max-width: 80%;
    height: auto;
    max-height: 40px;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
`

const AvatarImg = styled.img`
    max-height:40px;
`

const CourseInfoWrapper = ({ course, showProgress, children, ownerAvatar, ownerColor, extraPadding }) => {
  // const { t } = useTranslation();

  const { name, description, progress } = course

  const themeColor = getUserThemeColor(ownerColor)
  const ownerName = course?.ownerName || course?.owner?.name

  return (
    <CourseInformation $extraPadding={extraPadding}>
      <Flex align='flex-start' direction='column' >
        {showProgress && <Progress progress={progress * 100} />}
        <Flex direction='column' width='100%' align='flex-start'>
          <LogoWrapper>{ownerAvatar ? <AvatarImg src={ownerAvatar} alt={ownerName} loading='lazy' /> : <Avatar bgcolor={themeColor} size='small'>{ownerName?.charAt(0)}</Avatar>}</LogoWrapper>
          <Typo.TextTitle>{name}</Typo.TextTitle>
          {ownerName && <Typo.Info>By {ownerName}</Typo.Info>}
        </Flex>
        {description && <Description>{description}</Description>}
      </Flex>
      { children}
    </CourseInformation >
  );
};

export default CourseInfoWrapper;
