import React, { useCallback } from 'react';
import styled from 'styled-components'
import { CourseCard } from '../../ui'
import { useSelector } from 'react-redux';
import { selectCurrentStoreHideVat, selectStoreName, selectUserTheme } from '../../features/webshop/globalSlice';
import { media } from '../../utils/media';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { selectFeatures } from '../../features/settings/settingsSlice';

//revisit it:
const AllCoursesWrapper = styled.div`
  width:100%;
  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;

  /* Supports Grid */
  display: grid; 
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 24px;

  ${media.lessThan('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.lessThan('phablet')} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ListCourses = ({
  items,
  addItem,
  refCourses,
  module,
  showProgress,
  showButtons,
  showPackageInfo,
  CardComponent,
}) => {
  // const { t } = useTranslation();

  const navigate = useNavigate();
  const storeName = useSelector(selectStoreName);
  const storeTheme = useSelector(selectUserTheme);
  const hideVAT = useSelector(selectCurrentStoreHideVat)
  const features = useSelector(selectFeatures);

  const goToCourse = useCallback((item, isBundle) => {
    if (module === 'dashboard') {
      !features.newCourseView ? navigate(`/course/${item.ownerSlug}/${item.slug}`, { state: item })
        : navigate(`/view/course/${item.id}`);
    }
    else navigate(`/store/${storeName}/${isBundle ? 'package/' : ''}${item.slug}`, { state: item });
  }, [navigate, module, storeName, features.newCourseView])

  const goToEdit = useCallback((item) => {
    navigate(`/course/${item.ownerSlug}/${item.slug}`, { state: item });
  }, [navigate])

  const goToCourseSettings = useCallback((item) => {
    navigate(`/courses/${item.id}/settings`);
  }, [navigate])

  const ComponentCourseCard = CardComponent || CourseCard
  
  return (
    <>
      {items?.length > 1 ?
        <AllCoursesWrapper ref={refCourses}>
          {items.map((item, index) =>
            <ComponentCourseCard
              key={`course-card${item.id}-${index}`}
              courseInfo={item}
              goToCoursePage={goToCourse}
              goToSettings={goToCourseSettings}
              addToCart={addItem}
              goToEditCourse={goToEdit}
              showButtons={showButtons}
              showProgress={showProgress}
              inGrid={true}
              showPackageInfo={showPackageInfo}
              storeTheme={storeTheme}
              hideVAT={hideVAT}
            />
          )}
        </AllCoursesWrapper>
        : !!items?.length ? <ComponentCourseCard
          key={`course-card${items[0]?.id}`}
          courseInfo={items[0]}
          goToCoursePage={goToCourse}
          goToEditCourse={goToEdit}
          addToCart={addItem}
          goToSettings={goToCourseSettings}
          showButtons={showButtons}
          showProgress={showProgress}
          showPackageInfo={showPackageInfo}
          ownerAvatar={storeTheme.logoUrl}
          ownerColor={storeTheme.color}
          storeTheme={storeTheme}
          hideVAT={hideVAT}
        /> : null
      }

    </>
  );
};

export default ListCourses;
