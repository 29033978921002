import dayjs from "dayjs"

export const ucpPeriods = [
  { label: 'Unlimited', id: 0, time: 0 },
  { label: 'oneMonth', id: 1, time: 2592000 },
  { label: 'threeMonths', id: 3, time: 7776000 },
  { label: 'sixMonths', id: 6, time: 15768000 },
  { label: 'oneYear', id: 12, time: 31536000 },
  { label: 'oneAndHalfYear', id: 18, time: 47304000 },
  { label: 'twoYears', id: 24, time: 63072000 },


  // 62208000
]

export const getPeriodByTime = (time) => {
  return time === 62208000 ?
    ucpPeriods[6] : time === 15552000 ?
      ucpPeriods[3] : time === 7889000 ?
        ucpPeriods[2] : time > 63072000 ?
          ucpPeriods[6] :
          ucpPeriods.find(period => period.time === time)
}

export const getTimeRemaining = (date) => {
  const today = dayjs()
  const expires = dayjs(date, "YYYY-MM-DD")
  if (date === 'Never expires') return null
  return expires.diff(today, 'days')
}
