import Reflux from 'reflux';

const DashboardActions = Reflux.createActions([
  'read',
  'readComplete',
  'searchRead',
  'searchReadComplete',
  'editCourse',
  'addCourseSkeleton',
  'saveCourse',
  'createCourseComplete',
  'updateCourseComplete',
  'removeCourse',
  'removeCourseCompleted',
  'initQuizSection',
  'duplicateCourse',
  'duplicateCourseComplete',
  'setSortMethod'
]);

export default DashboardActions;
