import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { Typo, ListMarker } from '../../../../../ui';
import { getDarkerThemeColor } from '../../../../../ui/theme/colors';
import { selectUserTheme } from '../../../globalSlice';

const TextFadded = styled.div`
  display:flex;
  flex-direction:column;
  gap:15px;
  width:100%;
  align-items: flex-start;
  max-height: ${({$heightLimit, $hideText})=> $hideText ? `${$heightLimit}px` : null };
  overflow: ${({$hideText})=> $hideText ? 'hidden' : 'visible'};
  mask-image: ${({$exceedLimit, $hideText})=> $exceedLimit && $hideText ? `linear-gradient(black 50%, transparent)`: ''};
`

const TextHidden = styled.div`
  display:flex;
  flex-direction:column;
  gap:15px;
  width:100%;
  visibility:hidden;
  position:absolute;
  top:0;
  left:0;
`

const FaddedText = ({ infoText, hideText, infoIcons, exceedLimit, heightLimit, $ref }) => {
  
  const userTheme = useSelector(selectUserTheme);

  const getContent = () => <>
    <Typo.Text>{infoText}</Typo.Text>
    <div>
      {!!infoIcons?.length && infoIcons.map((item, i) =>
          <ListMarker key={i} $color={getDarkerThemeColor(userTheme.color)}>
            <Typo.Text>{item.title}</Typo.Text>
          </ListMarker>
      )}
    </div>
  </>


  
  return (   
    <>
      <TextFadded $hideText={hideText} $exceedLimit={exceedLimit} $heightLimit={heightLimit}>
        {getContent()}
      </TextFadded>
      <TextHidden ref={$ref}>
        {getContent()}
      </TextHidden>
    </>
  );
};

export default FaddedText;
