import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '..';
import Typo from '../Typo';

const ConfirmDelete = ({ open, onAcceptDelete, onClose, description, title }) => {

  const closeHandler = () => {
    onClose()
  }

  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      showActions
      onAccept={onAcceptDelete}
      acceptLabel={t('globals.delete')}
      variant='action'
      title={title || t('globals.confirmDelete')} >
      <Typo.Text>{description}</Typo.Text>
    </Modal>
  );
};

export default ConfirmDelete;
