import React from 'react';
import styled from 'styled-components'
import Typo from '../Typo';
import Flex from '../Flex';

import InfoIcon from './InfoIcon';
import { media } from '../../utils/media';

const InfoWrapper = styled.div`
  margin-top: ${({ $isMini }) => $isMini ? 0 : '0.5rem'};
  // max-width: ${({ $isMini }) => $isMini ? '100%' : '250px'};
  width:100%;
`;

const IconWrapper = styled.div`
  display:flex;
  justify-content: ${({ $isMini }) => $isMini ? 'flex-start' : 'center'};
  margin-right:${({ $isMini }) => $isMini ? '1rem' : '0'};

  ${media.greaterThan('tablet')} {
    width:${({ $isMini }) => !$isMini && '100%'};;
    margin-right:0;
  }
`

const FlexStyled = styled(Flex)`
  flex-direction: ${({ $isMini }) => $isMini ? 'row' : 'column'};
  gap: ${({ $isMini }) => $isMini ? '10px' : '0'};
  
  ${media.greaterThan('tablet')} {
    align-items:flex-start;
  }
`

const InfoIconCard = ({ iconInfo, size }) => {
  const { title, text } = iconInfo;

  const isMini = size === 'small' || size === 'x-small'

  return (
    <FlexStyled size={size} $isMini={isMini}>
      {iconInfo.iconName && <IconWrapper $isMini={isMini}>
        <InfoIcon size={isMini ? 'small' : 'large'} iconName={iconInfo.iconName} />
      </IconWrapper>}
      <InfoWrapper $isMini={isMini}>
        {isMini ? <Typo.TextTitle >{title}</Typo.TextTitle> :
          <>
            <Typo.ContentSmallHeader $marginBottom={'15px'} >{title}</Typo.ContentSmallHeader>
            <Typo.Text fontWeight={400}>{text}</Typo.Text>
          </>
        }
      </InfoWrapper>
    </FlexStyled>
  );
};

export default InfoIconCard;
