import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { selectAuthState, selectUser } from '../../../../features/auth/authSlice';
import { Typo, Button } from '../../../../ui';
import GuestCheckout from './GuestCheckout';

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap:5px;
  align-items: flex-start;
  padding-top:10px;
`

const CheckoutUserInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authState = useSelector(selectAuthState);
  const user = useSelector(selectUser);
 
  const gotToProfile = useCallback(() => {
    navigate('/profile/info');
  }, [navigate]);

  return (
    !authState.loggedIn ?
      <GuestCheckout />
      : <Wrapper >
          <Typo.TextTitle>{t('webshop.checkout.myInfo')}</Typo.TextTitle>
          <Typo.Text>{t('globals.email')}: {user.email}</Typo.Text>
          <Button $asText onClick={gotToProfile}>{t('webshop.checkout.editMyInfo')}</Button>
      </Wrapper>
  );
};

export default CheckoutUserInfo;
