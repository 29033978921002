import Reflux from 'reflux';
import {
  PageModuleActions,
  CourseActions,
  AppActions,
  QuizActions
} from '../actions';
import SteelCompass from '../services/SteelCompass';
import Page from '../services/Page';
import { history } from '../helpers';
import { translate as _l } from 'oxygen-i18n';
import { enqueueSnackbar } from '../../../../modern/node_modules/notistack';

// addTranslations({
//   ['en-US']: {
//     'Diploma will be generated and mailed to your email addresss.':
//       'Diploma will be generated and mailed to your email addresss.',
//     'Diploma starts download': 'Diploma starts download'
//   },
//   ['sv-SE']: {
//     'Diploma will be generated and mailed to your email addresss.':
//       'Diplomet mailas inom kort till din inkorg.',
//     'Diploma starts download': 'Diplom börjar ladda ner'
//   }
// });

const checkCourse = course => {
  const settings = course.settings || {};
  const dripContent = settings.dripContent || {};
  if (dripContent.gatedAccess) {
    let prevPage;
    return {
      ...course,
      toc: course.toc.map(section => {
        return {
          ...section,
          pages: section.pages.map(page => {
            if (prevPage && !prevPage.isViewed) {
              const newPage = {
                ...page,
                isLocked: prevPage.isLocked || prevPage.progress === 0
              };
              prevPage = newPage;
              return newPage;
            }
            prevPage = page;
            return {
              ...page,
              isLocked: false
            };
          })
        };
      })
    };
  }
  return course;
};

var CourseStore = Reflux.createStore({
  listenables: [CourseActions, PageModuleActions, QuizActions],
  data: {
    search: [],
    tocEnabled: JSON.parse(localStorage.getItem('tocEnabled')) || true,
    layoutDesignEnabled: false,
    notesEnabled: false,
    notes: [],
    list: [],
    buildable: [],
    courses: [],
    toc: {
      loading: true,
      list: []
    },
    course: {},
    detail: {},
    page: {},
    slugMap: {},
    sort: {},
    filter: {},
    selected: null,
    selection: []
  },
  getInitialState() {
    return this.data;
  },
  triggerForLegacy(data) {
    this.trigger(data)
  },
  init() {
    if (localStorage) {
      var courses = JSON.parse(localStorage.getItem('courses')) || {};
      if (courses.filter) {
        this.data.filter = courses.filter;
      }
      if (courses.sort) {
        this.data.sort = courses.sort;
      }
      this.trigger(this.data);
    }
  },
  getFilter() {
    return this.data.filter;
  },
  hasFilter() {
    return !!this.data.filter.groupId || !!this.data.filter.userId;
  },
  search(text) {
    return SteelCompass.request('search', 'content', {
      courseId: this.data.course.id,
      token: `%${text}%`
    }).then(result => {
      this.data = {
        ...this.data,
        search: result
      }
      this.trigger(this.data)
    })
  },
  setCategory(courseId, categories) {
    return SteelCompass.request('course', 'update', {
      id: courseId,
      categories
    }).then(
      response => {
        if (this.data.course.id === courseId) {
          this.data = {
            ...this.data,
            course: {
              ...this.data.course,
              categories
            }
          };
        }
        if (this.data.selected.id === courseId) {
          this.data = {
            ...this.data,
            selected: {
              ...this.data.selected,
              categories
            }
          };
        }
        this.trigger(this.data);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  onResetFilter() {
    this.data.filter = {};
    this.trigger(this.data);
    this.serialize();
  },
  getToc(courseId) {
    this.data.toc = {
      ...this.data.toc,
      loading: true
    };
    this.trigger(this.data);
    return SteelCompass.request('course', 'getToc', { courseId }).then(
      toc => {
        CourseActions.getTocComplete(toc);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  getTocComplete(list) {
    this.data.toc = {
      ...this.data.toc,
      list,
      loading: false
    };
    this.trigger(this.data);
  },
  getGuestToc(courseId) {
    this.data.toc = {
      ...this.data.toc,
      loading: true
    };
    this.trigger(this.data);
    return SteelCompass.request('guest', 'getCourseToc', { courseId }).then(
      toc => {
        CourseActions.getGuestTocComplete(toc);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  getGuestTocComplete(list) {
    this.data.toc = {
      ...this.data.toc,
      list,
      loading: false
    };
    this.trigger(this.data);
  },
  openToc() {
    if (!this.data.tocEnabled) {
      this.data.tocEnabled = true;
      this.trigger(this.data);
      localStorage.setItem('tocEnabled', JSON.stringify(true));
    }
  },
  closeToc() {
    if (this.data.tocEnabled) {
      this.data.tocEnabled = false;
      this.trigger(this.data);
      localStorage.setItem('tocEnabled', JSON.stringify(false));
    }
  },
  toggleNotes() {
    this.data.notesEnabled = !this.data.notesEnabled;
    this.trigger(this.data);
  },
  findNotes(courseId = null) {
    return SteelCompass.request('note', 'findAll', {
      courseId
    }).then(
      response => {
        this.data = {
          ...this.data,
          notes: response
        };
        this.trigger(this.data);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  toggleToc() {
    if (this.data.tocEnabled) {
      this.closeToc();
    } else {
      this.openToc();
    }
  },
  openLayoutDesign() {
    if (!this.data.layoutDesignEnabled) {
      this.data.layoutDesignEnabled = true;
      this.trigger(this.data);
    }
  },
  closeLayoutDesign() {
    if (this.data.layoutDesignEnabled) {
      this.data.layoutDesignEnabled = false;
      this.trigger(this.data);
    }
  },
  toggleLayoutDesign() {
    if (this.data.layoutDesignEnabled) {
      this.closeLayoutDesign();
    } else {
      this.openLayoutDesign();
    }
  },
  onSetFilter(filter) {
    Object.assign(this.data.filter, filter);
    this.trigger(this.data);
    this.serialize();
  },
  onResetSort() {
    this.data.sort = {};
    this.trigger(this.data);
    this.serialize();
  },
  onSetSort(sort) {
    Object.assign(this.data.sort, sort);
    this.trigger(this.data);
    this.serialize();
  },
  serialize() {
    localStorage.setItem(
      'courses',
      JSON.stringify({ filter: this.data.filter, sort: this.data.sort })
    );
  },
  onFetchAll() {
    return SteelCompass.call('dashboard', 'read', {}, response => {
      this.data.courses = response.data;
      this.trigger(this.data);
    });
  },
  onFindBuildable() {
    return SteelCompass.request('course', 'findBuildable', {}).then(
      buildable => {
        CourseActions.findBuildableComplete(buildable);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  onFindBuildableComplete(buildable) {
    this.data.buildable = buildable;
    this.trigger(this.data);
  },
  onFilter(dontUseFilter) {
    var options = {
      sortBy: this.data.sort.property || 'name',
      sortMode: this.data.sort.direction || 'asc'
    };
    if (!dontUseFilter) {
      options.circleId = this.data.filter.groupId;
      options.userId = this.data.filter.userId;
    }
    return SteelCompass.call('search', 'filterCourses', options, response => {
      this.data.list = response.data;
      this.trigger(this.data);
    });
  },
  onFindBySlug(ownerSlug, courseSlug, statistics = false) {
    if (
      ownerSlug !== this.data.course.ownerSlug &&
      courseSlug !== this.data.course.slug
    ) {
      this.data = {
        ...this.data,
        page: {}
      };
      this.trigger(this.data);
    }
    return SteelCompass.request('course', 'findByUsernameAndSlug', {
      slug: courseSlug,
      username: ownerSlug
    }).then(
      course => {
        CourseActions.findBySlugComplete(course);
        if (statistics) {
          // analytics.initialize(
          //   (course.ownerExtra &&
          //     course.ownerExtra.ga &&
          //     course.ownerExtra.ga.id) ||
          //   undefined
          // );
          // analytics.pageview();
        }
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  onFindBySlugComplete(course) {
    this.data.course = checkCourse(course);
    this.trigger(this.data);
  },
  refetchToc() {
    const { id } = this.data.course;
    return SteelCompass.request('course', 'findById', { id }).then(
      data => {
        CourseActions.findByIdComplete(data);
      },
      error => {
        CourseActions.genericError(error);
      }
    );
  },
  onFindById(id) {
    if (id !== this.data.course.id) {
      this.data = {
        ...this.data,
        page: {}
      };
      this.trigger(this.data);
    }
    this.trigger(this.data);
    return SteelCompass.request('course', 'findById', { id }).then(
      data => {
        CourseActions.findByIdComplete(data);
      },
      error => {
        CourseActions.genericError(error);
      }
    );
  },
  onFindByIdComplete(course) {
    const checkedCourse = checkCourse(course);
    this.data.selected = checkedCourse;
    this.data.course = checkedCourse;
    this.trigger(this.data);
  },
  onUpdateComplete(data) {
    this.data.course = data;
    this.trigger(this.data);
  },
  retrieveDiploma(courseId) {
    const params = (new URL(document.location)).searchParams;
    const recipientEmail = params.get('recipientEmail');

    return SteelCompass.request('diploma', 'mail', { courseId, recipientEmail  }).then(
      data => {
        enqueueSnackbar(_l`Diploma will be generated and mailed to your email addresss.`, {variant: 'success'})
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  getUserProgress(courseId) {
    return SteelCompass.request('course', 'getUserProgress', { id: courseId }).then(
      data => {
        this.data.userProgress = data;
        this.trigger(this.data);
      },
      error => {
        AppActions.genericError(error);
      }
    )
  },
  downloadDiploma(courseId) {
    return SteelCompass.request('diploma', 'download', { courseId }).then(
      data => {
        enqueueSnackbar(_l`Diploma starts download.`, {variant: 'success'})
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement('a');
        const fileName = "diploma.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  saveSettings() {
    const { id, settings } = this.data.selected
      ? this.data.selected
      : this.data.course
        ? this.data.course
        : {}

    return SteelCompass.request('course', 'update', { id, settings }).then(
      data => { },
      error => {
        CourseActions.genericError(error);
      }
    );
  },
  updateDiploma(data) {
    const diploma =
      this.data.selected.settings && this.data.selected.settings.diploma
        ? this.data.selected.settings.diploma
        : {};
    const settings = {
      ...this.data.selected.settings,
      diploma: {
        ...diploma,
        ...data
      }
    };
    this.data = {
      ...this.data,
      selected: {
        ...this.data.selected,
        settings
      }
    };
    this.trigger(this.data);
  },
  removeDiploma(id) {
    const settings = {
      ...this.data.selected.settings,
      diploma: {
        fileId: null
      }
    };
    return SteelCompass.request('course', 'update', { id, settings }).then(
      data => {
        this.data = {
          ...this.data,
          selected: {
            ...this.data.selected,
            settings: data.settings
          }
        };
        this.trigger(this.data);
      },
      error => {
        CourseActions.genericError(error);
      }
    );
  },
  updateQuizSettings(data) {
    const course_settings =
      this.data.selected &&
        this.data.selected.settings &&
        this.data.selected.settings
        ? this.data.selected.settings
        : this.data.course.settings && this.data.course.settings
          ? this.data.course.settings
          : {};
    const settings = {
      ...course_settings,
      quiz: {
        ...course_settings.quiz,
        ...data
      }
    };
    this.data = {
      ...this.data,
      selected: {
        ...this.data.selected,
        settings
      },
      course: {
        ...this.data.course,
        settings
      }
    };
    this.trigger(this.data);
  },
  updateDripContent(data) {
    const dripContent =
      this.data.selected.settings && this.data.selected.settings.dripContent
        ? this.data.selected.settings.dripContent
        : {};
    const settings = {
      ...this.data.selected.settings,
      dripContent: {
        ...dripContent,
        ...data
      }
    };
    this.data = {
      ...this.data,
      selected: {
        ...this.data.selected,
        settings
      }
    };
    this.trigger(this.data);
  },
  getNextForPageId(pageId) {
    const filtered = this.data.course.toc ? this.data.course.toc.filter(s => s.type !== 'quiz') : [];
    const quizSettings = this.data.course.settings && this.data.course.settings.quiz
    const toc = quizSettings && quizSettings.enabled ? this.data.course.toc ? this.data.course.toc : [] : filtered;
    let foundPageIndex;
    const foundSectionIndex = toc.findIndex(section => {
      foundPageIndex = section.pages.findIndex(page => {
        return page.id === pageId;
      });
      return foundPageIndex > -1;
    });
    let foundSection = toc[foundSectionIndex];
    let foundPage;
    if (
      foundSection &&
      foundPageIndex === foundSection.pages.length - 1 &&
      foundSectionIndex < toc.length - 1
    ) {
      foundSection = toc[foundSectionIndex + 1];
      foundPage = foundSection.pages[0];
      return {
        chapter: foundSection,
        page: foundPage
      };
    } else if (foundSection && foundPageIndex < foundSection.pages.length - 1) {
      return {
        chapter: foundSection,
        page: foundSection.pages[foundPageIndex + 1]
      };
    }
    return null;
  },
  getPreviousForPageId(pageId) {
    const filtered = this.data.course.toc ? this.data.course.toc.filter(s => s.type !== 'quiz') : [];
    const quizSettings = this.data.course.settings && this.data.course.settings.quiz
    const toc = quizSettings && quizSettings.enabled ? this.data.course.toc ? this.data.course.toc : [] : filtered;
    let foundPageIndex;
    const foundSectionIndex = toc.findIndex(section => {
      foundPageIndex = section.pages.findIndex(page => {
        return page.id === pageId;
      });
      return foundPageIndex > -1;
    });
    let foundSection = toc[foundSectionIndex];
    let foundPage;
    if (foundPageIndex === 0 && foundSectionIndex > 0) {
      foundSection = toc[foundSectionIndex - 1];
      foundPage = foundSection.pages[foundSection.pages.length - 1];
      return {
        chapter: foundSection,
        page: foundPage
      };
    } else if (foundPageIndex > 0) {
      return {
        chapter: foundSection,
        page: foundSection.pages[foundPageIndex - 1]
      };
    }
    return null;
  },
  onGetPageBySlug(username, courseSlug, sectionSlug, pageSlug, callback) {
    return SteelCompass.request('page', 'findBySlug', {
      username,
      courseSlug,
      sectionSlug,
      pageSlug
    }).then(
      page => {
        CourseActions.getPageByIdComplete(page);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  getPageById(pageId, editor) {
    // this.data.page = null;
    // this.trigger(this.data);
    return SteelCompass.request('page', 'findById', { id: pageId }).then(
      page => {
        CourseActions.getPageByIdComplete(page, editor);
      },
      error => {
        if (this.data.page.id === pageId) {
          this.data.page = {};
          this.trigger(this.data);
        }
        // history.push('/dashboard');
        AppActions.genericError(error);
      }
    );
  },
  getPageByIdComplete(page, editor) {
    this.data.page = {
      ...page,
      pageRows: page.pageRows.filter(row => {
        if (row.pageModules.length > 0) {
          return true;
        }
        if (editor) {
          Page.deletePageRow(row.id);
        }
        return false;
      })
    };
    this.trigger(this.data);
    CourseActions.markPageViewed(this.data.course.id, page.id);
  },
  markPageViewed(courseId, pageId) {
    return SteelCompass.request('page', 'markViewed', {
      courseId,
      pageId
    }).then(
      () => {
        CourseActions.markPageViewedComplete(courseId, pageId);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  markPageViewedComplete(courseId, pageId) {
    const course = JSON.parse(JSON.stringify(this.data.course));
    if (course.id === courseId) {
      course.lastViewedPageId = pageId;
      course.toc = course.toc.map(section => {
        section.pages = section.pages.map(page => {
          if (page.id === pageId) {
            return {
              ...page,
              isViewed: 1
            };
          }
          return page;
        });
        return section;
      });
      this.data.course = checkCourse(course);
      this.trigger(this.data);
    }
  },
  resetSelection() {
    this.data.selection = [];
    this.trigger(this.data);
  },
  onFindByUserId(userId) {
    this.data.selectionLoading = true;
    this.trigger(this.data);
    return SteelCompass.request('search', 'filterCourses', {
      userId: userId,
      sortBy: 'name',
      sortMode: 'asc',
      showOld: true
    }).then(
      selection => {
        CourseActions.findByUserIdComplete(selection);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  onFindByUserIdComplete(selection) {
    this.data.selection = selection;
    this.data.selectionLoading = false;
    this.trigger(this.data);
  },
  onRemoveFromSelection(courseId) {
    var dirty = false;
    const { selection } = this.data;
    for (var i = selection.length; i--;) {
      if (selection[i].id === courseId) {
        selection.splice(i, 1);
        dirty = true;
      }
    }
    if (dirty) {
      this.trigger(this.data);
    }
  },
  onDuplicatePage(courseId, id) {
    return SteelCompass.request('page', 'duplicate', { id }).then(
      data => {
        CourseActions.duplicatePageComplete(courseId);
      },
      error => {
        CourseActions.genericError(error);
      }
    );
  },
  onDuplicatePageComplete(courseId) {
    CourseActions.findById(courseId);
  },
  onDuplicateSection(courseId, id) {
    return SteelCompass.request('section', 'duplicate', { id }).then(
      data => {
        CourseActions.duplicateSectionComplete(courseId);
      },
      error => {
        CourseActions.genericError(error);
      }
    );
  },
  onDuplicateSectionComplete(courseId) {
    CourseActions.findById(courseId);
  },
  onFindByGroupId(groupId, callback) {
    return SteelCompass.call(
      'search',
      'filterCourses',
      { circleId: groupId, sortBy: 'name', sortMode: 'asc' },
      response => {
        this.data.list = response.data || [];
        this.trigger(this.data);
        if (callback) {
          callback();
        }
      }
    );
  },
  // moveModule(fromRow, fromPosition, toRow, toPosition) {
  //   if (this.moduleToMove) {
  //     if (toRow === this.moduleToMove.toRow && toPosition === this.moduleToMove.toPosition) {
  //       return;
  //     }
  //     if (this._moveModuleTimer) {
  //       clearTimeout(this._moveModuleTimer);
  //       this._moveModuleTimer = null;
  //     }
  //   }
  //   if (this._moveModuleTimer) {
  //     return;
  //   }
  //   this.moduleToMove = {
  //     toRow,
  //     toPosition
  //   };
  //   console.log(this.moduleToMove);
  //   this._moveModuleTimer = setTimeout(() => {
  //     this.moveModuleFinalize(fromRow, fromPosition, toRow, toPosition);
  //     clearTimeout(this._moveModuleTimer);
  //     this._moveModuleTimer = null;
  //   }, 1000);
  // },
  moveModule(id, fromRow, fromPosition, toRow, toPosition) {
    const pageRows = this.data.page.pageRows.slice();
    if (!pageRows[fromRow] || !pageRows[toRow]) {
      return;
    }
    if (
      !pageRows[fromRow].pageModules[fromPosition] ||
      pageRows[fromRow].pageModules[fromPosition].id !== id
    ) {
      return;
    }
    if (fromRow === toRow) {
      const pageModules = pageRows[fromRow].pageModules.slice();
      pageModules[toPosition] = pageModules.splice(
        fromPosition,
        1,
        pageModules[toPosition]
      )[0];
      if (!pageModules[toPosition]) {
        return;
      }
      pageRows[fromRow] = {
        ...pageRows[fromRow],
        pageModules
      };
      pageModules.forEach((module, index) => {
        CourseActions.updatePageModule(module.id, { sortOrder: index + 1 });
      });
    } else if (fromRow !== toRow) {
      const fromModules = pageRows[fromRow].pageModules.slice(); // TODO
      const toModules = pageRows[toRow].pageModules.slice();
      const pageModule = fromModules.splice(fromPosition, 1)[0];
      if (!pageModule) {
        return;
      }

      toModules.splice(toPosition, 0, pageModule);
      if (pageRows[fromRow].pageModules.length < 1) {
        // CourseActions.deletePageRow(pageRows[fromRow].id);
      }
      pageRows[fromRow] = {
        ...pageRows[fromRow],
        pageModules: fromModules
      };
      pageRows[toRow] = {
        ...pageRows[toRow],
        pageModules: toModules
      };
      toModules.forEach((module, index) => {
        CourseActions.updatePageModule(module.id, {
          pageRowId: pageRows[toRow].id,
          sortOrder: index + 1
        });
      });
    }
    this.data.page = {
      ...this.data.page,
      pageRows
    };
    this.trigger(this.data);
  },
  deletePageRow(id) {
    SteelCompass.request('page-row', 'delete', { id }).then(
      () => {
        CourseActions.deletePageRowComplete(id);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  deletePageRowComplete(id) { },
  sanitizePage() {
    const pageRows = this.data.page.pageRows.slice().filter(row => {
      if (row.pageModules && row.pageModules.length > 0) {
        return true;
      }
      CourseActions.deletePageRow(row.id);
      return false;
    });
    this.data.page = {
      ...this.data.page,
      pageRows
    };
    this.trigger(this.data);
  },
  moveModuleToNewRow(rowIndex, index, toRowIndex) {
    const pageRows = this.data.page.pageRows.slice();
    if (!pageRows[rowIndex]) {
      return;
    }
    const pageModules = pageRows[rowIndex].pageModules.slice();
    const pageModule = pageModules.splice(index, 1)[0];
    if (!pageModule) {
      return;
    }
    if (pageRows[rowIndex].pageModules.length < 1) {
      // CourseActions.deletePageRow(pageRows[rowIndex].id);
    }
    pageRows[rowIndex] = {
      ...pageRows[rowIndex],
      pageModules
    };
    pageRows.splice(toRowIndex, 0, {
      id: Math.random(),
      pageModules: [pageModule]
    });
    this.data.page = {
      ...this.data.page,
      pageRows
    };
    SteelCompass.request('page-module', 'moveToNewPageRow', {
      id: pageModule.id,
      pageRowPosition: toRowIndex + 1
    }).then(
      pageRow => {
        CourseActions.moveModuleToNewRowComplete(toRowIndex, pageRow);
      },
      error => {
        AppActions.genericError(error);
      }
    );
    this.trigger(this.data);
  },
  moveModuleToNewRowComplete(rowIndex, pageRow) {
    const pageRows = this.data.page.pageRows.slice();
    pageRows[rowIndex] = pageRow;
    this.data.page = {
      ...this.data.page,
      pageRows
    };
    this.trigger(this.data);
    //pageRows.splice(rowIndex, 1, pageRow);
  },
  cancelNewRow() {
    if (this._newRowTimer) {
      clearTimeout(this._newRowTimer);
      this._newRowTimer = null;
    }
  },
  moveModuleToRow(moduleId, rowId, position) {
    // Remove first
    var page = this.data.page,
      toPageRow = null,
      fromPageRow = null,
      foundIndex = null,
      foundModule = null,
      fromRowIndex = null;
    page.pageRows.some((row, rowIndex) => {
      if (row.id === rowId) {
        toPageRow = row;
      }
      row.pageModules.some((module, index) => {
        if (module.id === moduleId) {
          foundIndex = index;
          fromPageRow = row;
          fromRowIndex = rowIndex;
          foundModule = module;
          return true;
        }
        return false
      });
      return foundModule !== null && toPageRow !== null;
    });
    fromPageRow.pageModules.splice(foundIndex, 1);
    toPageRow.pageModules.splice(position, 0, foundModule);
    if (fromPageRow.pageModules.length < 1) {
      page.pageRows.splice(fromRowIndex, 1);
    }
    this.trigger(this.data);
  },
  onMoveModuleAfterRow(moduleId, rowId) {
    var page = this.data.page,
      fromPageRow = null,
      foundIndex = null,
      foundModule = null,
      foundRowIndex = null,
      fromRowIndex = null;
    page.pageRows.some((row, rowIndex) => {
      if (row.id === rowId) {
        foundRowIndex = rowIndex;
      }
      row.pageModules.some((module, index) => {
        if (module.id === moduleId) {
          foundIndex = index;
          fromPageRow = row;
          fromRowIndex = rowIndex;
          foundModule = module;
          return true;
        }
        return false
      });
      return foundRowIndex !== null && foundIndex !== null;
    });
    fromPageRow.pageModules.splice(foundIndex, 1);
    page.pageRows.splice(foundRowIndex + 1, 0, {
      id: 3,//getNextId(),
      pageModules: [foundModule]
    });
    if (fromPageRow.pageModules.length < 1) {
      page.pageRows.splice(fromRowIndex, 1);
    }
    this.trigger(this.data);
  },
  onAddToTop(moduleId) {
    var page = this.data.page,
      fromPageRow = null,
      foundIndex = null,
      foundModule = null,
      fromRowIndex = null;
    page.pageRows.some((row, rowIndex) => {
      row.pageModules.some((module, index) => {
        if (module.id === moduleId) {
          foundIndex = index;
          fromPageRow = row;
          fromRowIndex = rowIndex;
          foundModule = module;
          return true;
        }
        return false
      });
      return foundIndex !== null;
    });
    fromPageRow.pageModules.splice(foundIndex, 1);
    page.pageRows.splice(0, 0, { id: 4, pageModules: [foundModule] });
    if (fromPageRow.pageModules.length < 1) {
      page.pageRows.splice(fromRowIndex, 1);
    }
    this.trigger(this.data);
  },
  deletePageModule(id) {
    return this.deleteModule(id);
  },
  deleteModule(id) {
    return SteelCompass.request('page-module', 'delete', { id }).then(
      () => {
        CourseActions.deleteModuleComplete(id);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  markModuleSaving(id) {
    const { page } = this.data;
    let dirty = false;
    const newPage = {
      ...page,
      pageRows: page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.map(module => {
              if (module.id === id && !module.saving) {
                dirty = true;
                return {
                  ...module,
                  saving: true
                };
              }
              return module;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    if (dirty) {
      this.data.page = newPage;
      this.trigger(this.data);
    }
  },
  changePageModule(moduleData, callback) {
    let newModule;
    const { page } = this.data;
    const newPage = {
      ...page,
      pageRows: page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.map(module => {
              if (module.id === moduleData.id && !module.saving) {
                newModule = {
                  ...module,
                  ...moduleData
                };
                return newModule;
              }
              return module;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    this.data.page = newPage;
    this.trigger(this.data);
    callback && callback(newModule);
  },
  savePageModule(id, content, data) {
    return SteelCompass.request('page-module', 'update', {
      id,
      content,
      params: data
    }).then(
      response => {
        PageModuleActions.savePageModuleComplete(id, content, data);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  savePageModuleComplete(id, content, data) {
    const { page } = this.data;
    this.data.page = {
      ...page,
      pageRows: page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.map(module => {
              if (module.id === id) {
                return {
                  ...module,
                  content,
                  data: {
                    ...module.data,
                    params: data
                  },
                  saving: false
                };
              }
              return module;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    this.trigger(this.data);
  },

  savePageModuleUserData(id, data) {
    return SteelCompass.request('page-module', 'saveUserData', {
      id,
      data
    }).then(
      response => {
        PageModuleActions.savePageModuleUserDataComplete(id, data);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  savePageModuleUserDataComplete(id, userdata) {
    const { page } = this.data;
    this.data.page = {
      ...page,
      pageRows: page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.map(module => {
              if (module.id === id) {
                return {
                  ...module,
                  data: {
                    content: module.content,
                    params: module.params,
                    userdata
                  }
                };
              }
              return module;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    this.trigger(this.data);
  },
  changePageModuleMode(id, mode) {
    const { page } = this.data;
    this.data.page = {
      ...page,
      pageRows: page.pageRows && page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.map(module => {
              if (module.id === id) {
                return {
                  ...module,
                  mode
                };
              }
              return module;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    this.trigger(this.data);
  },
  deleteModuleComplete(moduleId) {
    const { page } = this.data;
    this.data.page = {
      ...page,
      pageRows: page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.filter(module => {
              return module.id !== moduleId;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    this.trigger(this.data);
  },
  moveSection(courseId, dragIndex, hoverIndex) {
    const toc = this.data.course.toc.slice();
    toc[hoverIndex] = toc.splice(dragIndex, 1, toc[hoverIndex])[0];
    const course = {
      ...this.data.course,
      toc
    };

    this.data.course = course;
    this.trigger(this.data);

    if (this._reorderTimeout) {
      clearTimeout(this._reorderTimeout);
    }
    this._reorderTimeout = setTimeout(() => {
      this.reorderSections(
        courseId,
        this.data.course.toc.map(section => section.id)
      );
    }, 1000);
  },
  reorderSections(id, sectionIds) {
    SteelCompass.request('course', 'reorderSections', { id, sectionIds }).then(
      () => {
        CourseActions.reorderSectionsComplete(sectionIds);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  reorderSectionsComplete() { },
  reorderPages(id, pageIds) {
    SteelCompass.request('section', 'reorderPages', { id, pageIds }).then(
      () => {
        CourseActions.reorderPagesComplete(pageIds);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  reorderPagesComplete() { },
  movePage(courseId, sectionIndex, pageIndex, toSectionIndex, toPageIndex) {
    const toc = this.data.course.toc.slice();
    const pageId = toc[sectionIndex].pages[pageIndex].id;
    const sectionId = toc[toSectionIndex].id;
    const position = toPageIndex + 1;
    let course;
    if (sectionIndex === toSectionIndex) {
      const pages = toc[sectionIndex].pages.slice();
      pages[toPageIndex] = pages.splice(pageIndex, 1, pages[toPageIndex])[0];
      toc[sectionIndex] = {
        ...toc[sectionIndex],
        pages
      };
      // console.log(pages);
      course = {
        ...this.data.course,
        toc
      };
      this.data.course = course;
      this.trigger(this.data);
      if (this._pageReorderTimeout) {
        clearTimeout(this._pageReorderTimeout);
      }
      this._pageReorderTimeout = setTimeout(() => {
        this.reorderPages(sectionId, pages.map(page => page.id));
      }, 1000);
    } else if (sectionIndex !== toSectionIndex) {
      const fromPages = toc[sectionIndex].pages.slice();
      const toPages = toc[toSectionIndex].pages.slice();
      const page = fromPages.splice(pageIndex, 1)[0];
      toPages.splice(toPageIndex, 0, page);
      toc[sectionIndex] = {
        ...toc[sectionIndex],
        pages: fromPages
      };
      toc[toSectionIndex] = {
        ...toc[toSectionIndex],
        pages: toPages.map(el => {
          if (el.hasOwnProperty('mode')) {
            el.mode = '';
          }
          return el
        })
      };
      course = {
        ...this.data.course,
        toc
      };
      this.data.course = course;
      this.trigger(this.data);
      if (this._pageMoveTimeout) {
        clearTimeout(this._pageMoveTimeout);
      }
      this._pageMoveTimeout = setTimeout(() => {
        this.movePageToSection(pageId, sectionId, position);
      }, 1000);
    }
  },
  movePageToSection(pageId, sectionId, position) {
    return SteelCompass.request('page', 'moveToSection', {
      pageId,
      sectionId,
      position
    }).then(
      () => {
        CourseActions.movePageComplete();
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  movePageComplete() { },
  updatePageModule(pageModuleId, pageModuleData) {
    return SteelCompass.request('page-module', 'update', {
      ...pageModuleData,
      id: pageModuleId
    }).then(
      () => {
        CourseActions.updatePageModuleComplete();
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  updatePageModuleComplete() { },
  setPageModuleMode(pageModuleId, mode) {
    const { page } = this.data;
    this.data.page = {
      ...page,
      pageRows: page.pageRows.map(row => {
        return {
          ...row,
          pageModules: row.pageModules.map(module => {
            if (module.id === pageModuleId) {
              return {
                ...module,
                mode
              };
            }
            return module;
          })
        };
      })
    };
    this.trigger(this.data);
  },
  addPageModule(type, pageId, update = true) {
    return SteelCompass.request('page-module', 'create', {
      type,
      newRow: true,
      pageId
    }).then(
      response => {
        if (update) {
          CourseActions.addPageModuleComplete(response.pageRows);
        }
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  addPageModuleComplete(pageRows) {
    if (pageRows && pageRows.length > 0) {
      const { page } = this.data;
      this.data.page = {
        ...page,
        pageRows: page.pageRows.concat(
          pageRows.map(row => {
            row.pageModules = row.pageModules.map(module => {
              return {
                ...module,
                mode: 'edit'
              };
            });
            return row;
          })
        )
      };
      this.trigger(this.data);
    }
  },
  onGetFile(fileId, callback) {
    return SteelCompass.call(
      'file',
      'getFileInfo',
      { id: fileId },
      response => {
        //this.trigger(this.data);
        if (callback) {
          callback();
        }
      }
    );
  },
  updateTask(pageModuleId, task) {
    CourseActions.updateTaskComplete(pageModuleId, task);
    return SteelCompass.request('task', 'update', task).then(
      response => { },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  updateTaskCaption(task) {
    return SteelCompass.request('task', 'update', task).then(
      response => { },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  updateTaskComplete(pageModuleId, task) {
    const { page } = this.data;
    this.data.page = {
      ...page,
      pageRows: page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.map(module => {
              if (module.id === pageModuleId) {
                return {
                  ...module,
                  data: {
                    ...module.data,
                    params: {
                      ...module.data.params,
                      task: {
                        ...module.data.params.task,
                        ...task
                      }
                    }
                  },
                  saving: false
                };
              }
              return module;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    this.trigger(this.data);
  },
  note(pageModuleId, text, color) {
    return SteelCompass.request('page-module', 'note', {
      id: pageModuleId,
      note: { text, color }
    }).then(
      response => {
        CourseActions.noteComplete(pageModuleId, text, color);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  noteComplete(pageModuleId, text, color) {
    const page = this.data.page;
    if (page.pageRows) {
      page.pageRows.some(row => {
        return row.pageModules.some(module => {
          if (module.id === pageModuleId) {
            module.note = {
              text,
              color
            };
            this.trigger(this.data);
            return true;
          }
          return false
        });
      });
    }
  },
  answerTask(taskId, pageModuleId, body, bodyFiles = []) {
    return SteelCompass.request('task', 'answer', {
      taskId,
      pmid: pageModuleId,
      body
    })
      .then(response => {
        CourseActions.answerTaskComplete(
          taskId,
          pageModuleId,
          response.taskResult,
          bodyFiles
        );
      })
      .catch(AppActions.genericError);
  },
  answerTaskComplete(taskId, pageModuleId, taskResult, bodyFiles) {
    const { page } = this.data;
    this.data.page = {
      ...page,
      pageRows: page.pageRows
        .map(row => {
          return {
            ...row,
            pageModules: row.pageModules.map(module => {
              if (module.id === pageModuleId) {
                return {
                  ...module,
                  data: {
                    ...module.data,
                    params: {
                      ...module.data.params,
                      taskResults: [
                        ...(module.data.params.taskResults || []),
                        {
                          id: taskResult.id,
                          feedback: taskResult.feedback || {},
                          visible: taskResult.visible,
                          bodyFiles,
                          body: {
                            value: taskResult.body.value
                          }
                        }
                      ]
                    }
                  }
                };
              }
              return module;
            })
          };
        })
        .filter(row => {
          return row.pageModules.length > 0;
        })
    };
    this.trigger(this.data);
  },
  addPage(sectionId, afterPageId, pageName) {
    const { course } = this.data;
    let foundSection;
    /* eslint-disable-next-line */
    course.toc.every(section => {
      if (section.id === sectionId) {
        foundSection = section;
        return false;
      }
    });
    const name = foundSection
      ? pageName + ' ' + (foundSection.pages.length + 1)
      : pageName;
    return SteelCompass.request('page', 'create', {
      sectionId,
      afterPageId,
      name
    }).then(
      page => {
        CourseActions.addPageComplete(sectionId, afterPageId, page);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  addPageComplete(sectionId, afterPageId, page) {
    const { course } = this.data;
    const toc = course.toc.map(section => {
      if (section.id === sectionId) {
        const pages = section.pages.slice();
        const pageIndex =
          (afterPageId &&
            pages.findIndex(sectionPage => sectionPage.id === afterPageId)) ||
          0;
        pages.splice(pageIndex + 1, 0, {
          ...page,
          mode: 'edit'
        });
        return {
          ...section,
          pages
        };
      }
      return {
        ...section
      };
    });
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
    if (!this.data.page || !this.data.page.id) {
      history.push(`/course/${course.ownerSlug}/${course.slug}/${page.id}`);
    }
  },
  addQuizPageComplete(sectionId, afterPageId, page) {
    const { course } = this.data;
    const toc = course.toc.map(section => {
      if (section.id === sectionId) {
        const pages = section.pages.slice();
        const pageIndex =
          (afterPageId &&
            pages.findIndex(sectionPage => sectionPage.id === afterPageId)) ||
          0;
        pages.splice(pageIndex + 1, 0, {
          ...page,
          mode: 'edit'
        });
        return {
          ...section,
          pages
        };
      }
      return {
        ...section
      };
    });
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
  },
  updateSection(sectionData) {
    return SteelCompass.request('section', 'update', sectionData).then(
      () => {
        CourseActions.updateSectionComplete(sectionData);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  updateSectionComplete(sectionData) {
    const { course } = this.data;
    const toc = course.toc.map(section => {
      if (section.id === sectionData.id) {
        return {
          ...section,
          ...sectionData
        };
      }
      return {
        ...section
      };
    });
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
  },
  updatePage(page) {
    return SteelCompass.request('page', 'update', page).then(
      () => {
        CourseActions.updatePageComplete(page);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  updatePageComplete(page) {
    if (this.data.page.id === page.id) {
      this.data.page = {
        ...this.data.page,
        ...page
      };
    }
    // Update not only page inside data, but also inside sections
    this.data.course.toc.forEach((section, sectionI) => {
      section.pages.forEach((el, pageI) => {
        if (el.id === page.id) {
          this.data.course.toc[sectionI].pages[pageI] = { ...el, ...page }
        }
      });
    });
    this.trigger(this.data);
  },
  deleteSection(id) {
    if (this.data.course.toc && this.data.course.toc.length !== 1) {
      return SteelCompass.request('section', 'delete', { id }).then(
        () => {
          CourseActions.deleteSectionComplete(id);
        },
        error => {
          AppActions.genericError(error);
        }
      );
    }
  },
  deleteSectionComplete(sectionId) {
    const { course } = this.data;
    const toc = course.toc.slice();
    const index = toc.findIndex(section => section.id === sectionId);
    toc.splice(index, 1);
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
  },
  deletePage(id) {
    const { course } = this.data;
    const toc = course.toc.map(section => {
      const pageIndex = section.pages.findIndex(page => page.id === id);
      if (pageIndex >= 0) {
        return section.pages;
      }
      return null;
    });
    const pages = [].concat.apply([], toc).filter(x => !!x);
    if (pages.length !== 1) {
      let quizPages =
        this.data.course.settings &&
          this.data.course.settings.quiz &&
          this.data.course.settings.quiz.pages
          ? this.data.course.settings.quiz.pages
          : null;
      if (quizPages && quizPages[id]) {
        delete quizPages[id];
        CourseActions.updateQuizSettings({ pages: quizPages });
        CourseActions.saveSettings();
      }
      return SteelCompass.request('page', 'delete', { id }).then(
        () => {
          CourseActions.deletePageComplete(id);
        },
        error => {
          AppActions.genericError(error);
        }
      );
    }
  },
  deletePageComplete(pageId) {
    const { course } = this.data;
    const toc = course.toc.map(section => {
      const pageIndex = section.pages.findIndex(page => page.id === pageId);
      if (pageIndex > -1) {
        const pages = section.pages.slice();
        pages.splice(pageIndex, 1);
        return {
          ...section,
          pages
        };
      }
      return {
        ...section
      };
    });
    this.data.course = {
      ...course,
      toc
    };

    this.trigger(this.data);
  },
  addSection(courseId, afterSectionId, sectionName, pageName, type) {
    const name = sectionName + ' ' + (this.data.course.toc.length + 1);
    return SteelCompass.request('section', 'create', {
      courseId,
      afterSectionId,
      name,
      type
    }).then(
      section => {
        CourseActions.addSectionComplete(
          section,
          afterSectionId,
          pageName + ' 1'
        );
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  addSectionComplete(section, afterSectionId, pageName) {
    const { course } = this.data;
    const toc = course.toc.slice();
    const sectionIndex = toc.findIndex(
      courseSection => courseSection.id === afterSectionId
    );
    toc.splice(sectionIndex + 1, 0, {
      ...section,
      mode: 'edit',
      pages: section.pages || []
    });
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
    this.addPage(section.id, null, pageName);
  },
  addQuizSectionComplete(section, afterSectionId) {
    const { course } = this.data;
    const toc = course.toc.slice();
    const sectionIndex = toc.findIndex(
      courseSection => courseSection.id === afterSectionId
    );
    toc.splice(sectionIndex + 1, 0, {
      ...section,
      mode: 'edit',
      pages: section.pages || []
    });
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
  },
  increaseModuleSize(pageModuleId) {
    const { page } = this.data;
    const pageRows = page.pageRows.slice().map(row => {
      const pageModuleIndex = row.pageModules.findIndex(
        module => module.id === pageModuleId
      );
      if (pageModuleIndex > -1) {
        if (row.placement && row.placement.length < 1) {
          row.placement = [6, 6];
        }
        const placement = (row.placement || [6, 6]).map(weight => weight - 1);
        placement[pageModuleIndex] = placement[pageModuleIndex] + 2;
        CourseActions.updatePageRow(row.id, { placement });
      }
      return row;
    });
    this.data.page = {
      ...page,
      pageRows
    };
    this.trigger(this.data);
  },
  updatePageRow(id, pageRowData) {
    return SteelCompass.request('page-row', 'update', {
      ...pageRowData,
      id
    }).then(
      () => {
        CourseActions.updatePageRowComplete({ ...pageRowData, id });
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  updatePageRowComplete(row) {
    const { page } = this.data;
    const pageRows = page.pageRows.slice().map(pageRow => {
      if (pageRow.id === row.id) {
        return {
          ...pageRow,
          ...row
        };
      }
      return pageRow;
    });
    this.data.page = {
      ...page,
      pageRows
    };
    this.trigger(this.data);
  },

  pastePage(sectionId, page) {
    // SteelCompass.request('content', 'createFromReference', { parentId: sectionId, ref: { type: 'page', id: page.id } }).then(response => {
    //   console.log(response);
    // }, error => {
    //   console.log(error);
    // })
    const { course } = this.data;
    const toc = course.toc.map(section => {
      if (section.id === sectionId) {
        const pages = section.pages.slice();
        pages.push({
          ...page,
          id: --index
        });
        return {
          ...section,
          pages
        };
      }
      return section;
    });
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
  },
  pasteSection(section) {
    const { course } = this.data;
    const toc = course.toc.slice();
    toc.push({
      ...section,
      id: --index
    });
    this.data.course = {
      ...course,
      toc
    };
    this.trigger(this.data);
  },
  update(id, data) {
    return SteelCompass.request('course', 'update', { id, ...data }).then(
      response => {
        CourseActions.updateComplete(id, data);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  addTag(courseId, name) {
    return SteelCompass.request('tag', 'addLink', {
      objectId: courseId,
      objectType: 2,
      name
    }).then(
      response => {
        CourseActions.addTagComplete(courseId, name);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  removeTag(courseId, name) {
    return SteelCompass.request('tag', 'removeLink', {
      objectId: courseId,
      objectType: 2,
      name
    }).then(
      response => {
        CourseActions.removeTagComplete(courseId, name);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  updateComplete(id, data) {
    this.data = {
      ...this.data,
      list: this.data.list.map(course => {
        if (course.id === id) {
          return {
            ...course,
            ...data
          };
        }
        return course;
      })
    };
    if (this.data.selected.id === id) {
      this.data = {
        ...this.data,
        selected: {
          ...this.data.selected,
          ...data
        }
      };
    }
    this.trigger(this.data);
  },
  addTagComplete(courseId, name) {
    this.data = {
      ...this.data,
      list: this.data.list.map(course => {
        if (course.id === courseId) {
          const tagsMap = course.tagsMap || {};
          return {
            ...course,
            tagsMap: {
              ...tagsMap,
              [name]: true
            }
          };
        }
        return course;
      })
    };
    if (this.data.selected.id === courseId) {
      const tagsMap = this.data.selected.tagsMap || {};
      this.data = {
        ...this.data,
        selected: {
          ...this.data.selected,
          tagsMap: {
            ...tagsMap,
            [name]: true
          }
        }
      };
    }
    this.trigger(this.data);
  },
  removeTagComplete(courseId, name) {
    this.data = {
      ...this.data,
      list: this.data.list.map(course => {
        if (course.id === courseId) {
          const tagsMap = course.tagsMap || {};
          return {
            ...course,
            tagsMap: {
              ...tagsMap,
              [name]: false
            }
          };
        }
        return course;
      })
    };
    if (this.data.selected.id === courseId) {
      const tagsMap = this.data.selected.tagsMap || {};
      this.data = {
        ...this.data,
        selected: {
          ...this.data.selected,
          tagsMap: {
            ...tagsMap,
            [name]: false
          }
        }
      };
    }
    this.trigger(this.data);
  }
});

let index = -1;

export default CourseStore;
