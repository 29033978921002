import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { AccordionSummary } from '@mui/material';
import { Flex, theme, Typo } from '../../../../../ui';
import { getDarkerThemeColor } from '../../../../../ui/theme/colors';
import { selectUserTheme } from '../../../globalSlice';

const CourseContentSubject = styled(AccordionSummary)`
  padding: 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s linear !important;
  min-height: 0 !important;
  z-index: 99;

  p {
    transition: all 0.15s linear;
  }

  &:hover, &.active {
    background-color: ${() => theme.colors.NEUTRAL_0};
    border-radius: 12px;

    p {
      &:first-of-type {
        background-color: ${({ $color }) => !$color ? theme.colors.PRIMARY : $color};
        font-weight: 600;
        color: ${theme.colors.NEUTRAL_0}; 
      }

      &:last-of-type {
        color: ${() => theme.colors.NEUTRAL_PRIMARY};
        background-color: ${() => theme.colors.NEUTRAL_0};
      }
    }
  }
`;

const CourseContentNumber = styled(Typo.Text)`
  width: 30px;
  height: 28px;
  border-radius: 50%;
  background-color: ${() => theme.colors.NEUTRAL_20};
  text-align: center;
  font-weight: 400;
  margin-right: 10px;
`;

const ExpandIcon = styled.div`
  p {width:auto; }
`

const ShopCourseSubject = ({ handleClick, isActive, number, name, isMobile }) => {
  
  const userTheme = useSelector(selectUserTheme);
  const color = getDarkerThemeColor(userTheme.color)

  return (
    <CourseContentSubject
      onClick={handleClick}
      className={isActive}
      $color={color}
    >
      <Flex width={'100%'}>
        <CourseContentNumber>{number}</CourseContentNumber> 
        <Typo.Text>{name}</Typo.Text>
      </Flex>
      {isMobile &&<ExpandIcon exclude={true}>
        <Typo.Text >{isActive ? '-' : '+'}</Typo.Text>
      </ExpandIcon>
       }
    </CourseContentSubject>

  );
};

export default ShopCourseSubject;
