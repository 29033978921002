import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Typo } from '../../../ui';

const WrongStore = ({ errorMessage }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Typo.ContentBigHeader>{errorMessage}</Typo.ContentBigHeader>
      <Typo.Text $textAlign='center'>{t('webshop.noStoreToShow')} </Typo.Text>
      <Typo.Text $textAlign='center'>{t('webshop.contactSupportIfNoShopIsError')}</Typo.Text>
    </Section>
  );
};

export default WrongStore;
