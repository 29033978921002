import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { searchApi, statisticsApi, userApi } from '../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { enqueueSnackbar } from 'notistack'
import { getCourseAccessLevel } from '../mrWolf/mrWolfSlice';
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------



export const fetchUserById = createAsyncThunk('user/fetchUserById', async ({ id }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      id
    },
    method: 'findById'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const fetchUserStatistics = createAsyncThunk('user/fetchUserStatistics', async ({ userId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      userId
    },
    method: 'user'
  };

  const res = await httpClient.post(statisticsApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const fetchUserGroups = createAsyncThunk('user/fetchUserGroups', async ({ userId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      userId,
      sortBy: 'name',
      sortMode: 'asc'
    },
    method: 'filterCircles'
  };

  const res = await httpClient.post(searchApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const updateUserRoles = createAsyncThunk('user/updateUserRoles', async ({ id, roles }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      roles,
      id
    },
    method: 'update'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  // const message = res.isError ? res.errMsg : 'Successfully updated user roles'
  // const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  // enqueueSnackbar(message, { variant })

  return res.data;
});

export const setUserRoleInGroup = createAsyncThunk('user/setRoleInGroup', async ({ userId, circleId, role }, { getState, dispatch, rejectWithValue }) => {
  //role can be user or admin
  const body = {
    data: { userId, circleId, role },
    method: 'setCircleRole'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully updated user role in group.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

// ----------------- Reducers ---------------------------
const userGroupsAdapter = createEntityAdapter()
const userCoursesAdapter = createEntityAdapter()
const userStatisticsAdapter = createEntityAdapter()
const initialState = {
  status: 'idle',
  data: {},
  groups: {
    entities: {},
    ids: [],
  },
  courses: {
    entities: {},
    ids: [],
  },
  stats: {
    entities: {},
    ids: [],
  }

};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder

      .addCase(fetchUserById.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchUserById.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchUserGroups.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        userGroupsAdapter.setAll(state.groups, action.payload)
      })
      .addCase(fetchUserStatistics.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchUserStatistics.fulfilled, (state, action) => {
        // console.log('FETCH', action.payload.courses)
        userStatisticsAdapter.setAll(state.stats, action.payload.courses)
      })
      .addCase(updateUserRoles.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        enqueueSnackbar('Unable to update user roles', { variant: TOAST_ERROR });
      })
      .addCase(updateUserRoles.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        enqueueSnackbar('Successfully updated user roles', { variant: TOAST_SUCCESS });

      })
      .addCase(getCourseAccessLevel.fulfilled, (state, action) => {
        // usersAdapter.updateOne(state.course, { id: action.meta.arg.userId, changes: { accesslevel: { id: action.payload[0].id, type: action.payload[0].access } } })
      })
    // .addCase(updateCourseAccessLevel.fulfilled, (state, action) => {
    //   usersAdapter.updateOne(state.course, { id: action.meta.arg.userId, changes: { accesslevel: { id: action.meta.arg.acessLevelId, type: action.meta.arg.role } } })
    // })
  }
})

//   export const { } = usersSlice.actions

export default userSlice.reducer

// ----------------- Selectors --------------------------

export const {
  selectAll: selectUserGroups,
  selectById: selectUserGroupById,
} = userGroupsAdapter.getSelectors((state) => state.user.groups)

export const {
  selectAll: selectUserCourses,
  selectById: selectUserCourseById,
} = userCoursesAdapter.getSelectors((state) => state.user.courses)

export const {
  selectAll: selectCourseStatistics,
  selectById: selectCourseStatisticsById,
} = userStatisticsAdapter.getSelectors((state) => state.user.stats)

export const selectStatus = state => state.user.status;


// compare id with store data only return if match
export const selectUserById = state => state.user.data;


