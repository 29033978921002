import React from 'react';
import styled from 'styled-components';
import { IconAsButton, Typo } from '../../../ui';
import theme from '../../../ui/theme';
import { useDrag } from 'react-dnd'
import { useDispatch } from 'react-redux';
import { moveFile } from '../mediaSlice';
import FileThumbnail from './FileThumbnail';
import { media } from '../../../utils/media';
import { useTranslation } from 'react-i18next';

export const ItemTypes = {
  All: 'all',
}

const Content = styled.div`
  background-color: ${props => props.theme.colors.NEUTRAL_LIGHT};
  position: relative;
  transition: all .1s cubic-bezier(1, 0.06, 0.58, 1.2);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  img {
    pointer-events: none;
  }
`;
const TextWrapper = styled.div`
  max-width:182px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  ${media.lessThan('laptop')} {
    max-width:130px;
  }
  
  ${media.lessThan('phablet')} {  
    max-width:120px;
  }
`;

const FileInfo = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 5px 15px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    position: fixed;
    right: 0;
    top:0;
  }

  ${media.lessThan('tablet')} {
    padding: 3px;
  }
`;

const File = ({
  goToFile,
  file,
}) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();


  const fileName = file.name.split(".");

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.All,
    item: file,
    end: (item, monitor) => {
      const target = monitor.getDropResult()
      if (item && target) {
        dispatch(moveFile({ directoryId: target.id, id: item.id }))
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }))

  const opacity = isDragging ? 0.4 : 1

  return (<Content ref={drag} style={{ opacity }} key={file.id} >
    <FileThumbnail file={file} />
    <FileInfo>
      <TextWrapper>
        <Typo.Info color={theme.colors.NEUTRAL_LIGHT}>{fileName[0]}</Typo.Info>
        <Typo.Info color={theme.colors.NEUTRAL_LIGHT}>{t('globals.type')}: {fileName[fileName.length - 1]}</Typo.Info>
      </TextWrapper>
      <IconAsButton iconName='Info' tooltipLabel={t('globals.fileDetails')} color={theme.colors.NEUTRAL_LIGHT} clickHandle={() => goToFile(file)} />
    </FileInfo>
  </Content>)
}

export default File;
