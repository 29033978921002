import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, SubPage, Textinput, Flex, Typo } from '../../../ui';
import { CONTENT_MINI } from '../../../utils/media';
import { selectSettings, updateSettings } from '../settingsSlice';

const ButtonStyled = styled(Button)`
  align-self: flex-end; 
`

const Webinar = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const webinarRef = useRef({});
  const settings = useSelector(selectSettings)
  const [checked, setChecked] = useState();



  useEffect(() => {
    webinarRef.current['secret'].value = settings?.zroom?.secret || null;
    webinarRef.current['user'].value = settings?.zroom?.user || null;
    webinarRef.current['domain'].value = settings?.zroom?.domain || null;
    setChecked(settings?.zroom?.useCoursio);
  }, [settings])

  const saveZroom = () => {
    const { secret, user, domain } = webinarRef.current;

    const data = {
      zroom: { secret: secret.value, user: user.value, domain: domain.value, useCoursio: false }
    }
    dispatch(updateSettings({ settingsData: data }))

  }

  const toggleUseCoursio = (e) => {
    e.stopPropagation();
    const data = {
      zroom: {
        secret: null, user: null, domain: null,
        useCoursio: !checked
      }
    }
    dispatch(updateSettings({ settingsData: data }))
    setChecked(!checked)
  }

  return (
    <SubPage size={CONTENT_MINI}>
      <Flex direction='column' align='flex-start' gap='30' width='100%'>
        <Typo.ContentSmallHeader>{t('webinar.setupWebinar')}</Typo.ContentSmallHeader>
        <Textinput
          label={t('webinar.key')}
          autoFocus
          propName={'secret'}
          inputRef={ref => webinarRef.current['secret'] = ref}
        />
        <Textinput
          label={t('webinar.xroom')}
          autoFocus
          propName={'user'}
          inputRef={ref => webinarRef.current['user'] = ref}
        />
        <Textinput
          label={t('webinar.domain')}
          autoFocus
          propName={'domain'}
          inputRef={ref => webinarRef.current['domain'] = ref}
        />
        <ButtonStyled onClick={saveZroom}>{t('globals.save')}</ButtonStyled>

        <Divider style={{ width: '100%' }} ><Typo.Text>{t('globals.or')}</Typo.Text></Divider>

        <Accordion>
          <AccordionSummary >
            <Flex gap='20' width='100%' justify='space-between'>
              <Flex gap='10' direction='column' align='flex-start'>
                <Typo.TextTitle>{t('webinar.useCoursio')}</Typo.TextTitle>
                <Typo.MediumText>{t('webinar.turnCoursio')}</Typo.MediumText>
              </Flex>
              <Button onClick={toggleUseCoursio}>{checked ? t('webinar.turnOff') : t('webinar.turnOn')}</Button>
            </Flex>
          </AccordionSummary>
          <AccordionDetails>
            <Typo.MediumText>{t('webinar.enjoy')}</Typo.MediumText>
          </AccordionDetails>
        </Accordion>
      </Flex>
    </SubPage>
  );
};

export default Webinar;
