import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Icon, Typo, Page } from '../../ui';
import { fetchNotes, selectNotes } from './notesSlice';
import styled from 'styled-components';
import theme from '../../ui/theme';
import { CONTENT_SMALL } from '../../utils/media';
import { selectIsIncarnated } from '../auth/authSlice';
import { useTranslation } from 'react-i18next';

const CourseBlock = styled.div`
  margin: 2rem 0;
`
const ContentWrapper = styled.div`
  width:100%;

`
const NoteBlock = styled.div`
  margin: 1.5rem;  
  h4 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    padding-left:0.5rem;
  }
`

const Notes = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const notes = useSelector(selectNotes)
  const isIncarnated = useSelector(selectIsIncarnated);

  useEffect(() => {
    dispatch(fetchNotes());
  }, [dispatch, isIncarnated])

  return (
    <Page size={CONTENT_SMALL}>
      {notes.length ?
        <ContentWrapper>{notes.map((note) =>
          <CourseBlock key={note.id} >
            <Typo.ContentBigHeader $textAlign='left' >{note.name}</Typo.ContentBigHeader>
            {note.sections.map((section) =>
              section.pages.map((page) =>
                page.notes.map((pageNote, index) =>
                  <NoteBlock key={`${page.id}-${index}`}>
                    <Flex align='center' >
                      <Icon.Label size='small' color={theme.colors.NEUTRAL_20} />
                      <Typo.ContentSmallHeader $textAlign='left'>{pageNote.body}</Typo.ContentSmallHeader>
                    </Flex>
                    <Flex justify='space-between'>
                      <Typo.Info>{section.name} - {page.name}</Typo.Info>
                      <Typo.Info>{pageNote.ts}</Typo.Info>
                    </Flex>
                  </NoteBlock>
                )
              )
            )}
          </CourseBlock>
        )}
        </ContentWrapper>
        : <Typo.ContentSmallHeader>{t('notes.emptyStateMsg')}</Typo.ContentSmallHeader>}
    </Page>
  );
};

export default Notes;
