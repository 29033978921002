import Reflux from 'reflux';

const ExportActions = Reflux.createActions([
  'exportIncomingPayment',
  'exportIncomingPaymentComplete',
  'requestExport',
  'requestExportComplete',
]);

export default ExportActions;
