import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Typo } from '../../../ui';

const NoStoreToShow = ({ ownerName }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Typo.ContentBigHeader>{t('webshop.emptyStore')}</Typo.ContentBigHeader>
      <Typo.Text $textAlign='center'>{t('webshop.soonWillHaveSomething', { ownerName: ownerName })}</Typo.Text>
    </Section>
  );
};

export default NoStoreToShow;
