import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Typo, Flex, Button, Icon, theme, Modal } from '../../../ui';
import { selectInvitationById } from '../invitesSlice';
import { useTranslation } from 'react-i18next';

const ActionsWrapper = styled(Flex)`
  margin-top:3rem;
  justify-content:flex-end;
  gap:30;
`

const FlexFlow = styled(Flex)`
  padding: 0 1rem;
  flex-direction:column; 
  align-items:flex-start;
`;


const InfoRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  svg {
    margin-right: 10px;
  }
`;

const InviteDetails = ({ resendInviteHandler, copyLink, inviteId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const invite = useSelector((state) => selectInvitationById(state, inviteId));
  const [activeStep, setActiveStep] = useState(0);
  const flowStatus = invite?.flow && invite?.flow[inviteId];

  useEffect(() => {
    const step = flowStatus?.IsOpen ? 2 : flowStatus?.IsDelivered || flowStatus?.IsBounced ? 1 : 0
    setActiveStep(step)
  }, [flowStatus])

  const onCloseDetails = useCallback(() => {
    navigate(-1)
  }, [navigate]);


  const steps = [
    {
      label: flowStatus?.IsSent ? t('invites.sent') : t('invites.queue'),
      description: flowStatus?.TimeSent,
      id: 0
    },
    {
      label: flowStatus?.IsBounced ? t('invites.bounced') : t('invites.delivered'),
      description: flowStatus?.IsBounced ? flowStatus?.TimeBounced : flowStatus?.TimeDelivered,
      id: 1
    },
    {
      label: t('invites.inviteOpen'),
      description: flowStatus?.TimeOpen,
      id: 2
    },
    {
      label: t('invites.userAccepted'),
      id: 3
    },
  ];

  const isStepFailed = (step) => {
    return step.id === 1 && flowStatus?.IsBounced;
  };


  return (
    <Modal
      onClose={onCloseDetails}
      open={!!inviteId}
      width='800px'
      titleHasLine
      titleChildren={
        invite?.hasOwnProperty('hasExpired') ? <Flex gap='20' >
          <Typo.ContentSmallHeader>
            {invite.hasExpired ? t('invites.expired') : t('invites.valid')}
          </Typo.ContentSmallHeader>
          {invite.hasExpired ? <Icon.NotValid /> : <Icon.Valid color={theme.colors.SUCCESS_GREEN} />}
        </Flex> : <Typo.ContentSmallHeader>{t('invites.details')}</Typo.ContentSmallHeader>
      }
    >
      {!!invite && <div>
        <InfoRow>
          <Icon.Email />
          <Typo.Text> {invite.email}</Typo.Text>
        </InfoRow>
        <InfoRow>
          <Icon.Time />
          <Typo.Text>{t('invites.expirationDate', { expires: invite.expires })}</Typo.Text>
        </InfoRow>
        <InfoRow>
          <Icon.Course />
          {!!invite.course?.name && <Typo.Text>{t('invites.invCourse', { courseName: invite.course?.name })}</Typo.Text>}
        </InfoRow>
        <InfoRow>
          <Icon.Groups />
          {invite.circle?.name && <Typo.Text>{t('invites.invGroup', { groupName: invite.circle?.name })}</Typo.Text>}
        </InfoRow>
        {!!invite.flow && !!flowStatus && <div>
          <InfoRow>
            <Icon.Info />
            <Typo.Text>{t('globals.details')}:</Typo.Text>
          </InfoRow>
          <FlexFlow >

            <Stepper activeStep={activeStep} orientation="vertical"  >
              {steps.map((step, index) => {
                const labelProps = {};
                if (isStepFailed(step)) {
                  labelProps.optional = (
                    <Typo.Info color="error">
                      Alert message
                    </Typo.Info>
                  );
                  labelProps.error = true;
                } else if (step.id < activeStep) {
                  if (step.description) {
                    labelProps.optional = (
                      <Typo.Info color="error">
                        {step.description}
                      </Typo.Info>);
                  }
                }
                return <Step key={step.label} >
                  <StepLabel {...labelProps}>
                    <Typo.Text>{step.label}</Typo.Text>
                  </StepLabel>
                  <StepContent>
                    <Typo.SmallText>{step.description}</Typo.SmallText>
                  </StepContent>
                </Step>
              })}
            </Stepper>
          </FlexFlow>
        </div>}
        <ActionsWrapper  >
          {!invite?.hasExpired && invite?.url &&
            <Button onClick={(e) => copyLink(e, invite)}>{t('invites.copyLink')}</Button>
          }
          <Button $variant='primary' onClick={(e) => resendInviteHandler(e, invite)}>{t('invites.resendInvitation')}</Button>
        </ActionsWrapper>

      </div>}
    </Modal >

  );
}

export default InviteDetails;
