import Reflux from 'reflux';

const GroupActions = Reflux.createActions([
  'findBuildable',
  'findBuildableComplete',
  'fetchAll',
  'filter',
  'setFilter',
  'resetFilter',
  'setSort',
  'resetSort',
  'create',
  'createComplete',
  'update',
  'updateComplete',
  'findByUserId',
  'findByUserIdComplete',
  'findByCourseId',
  'findByCourseIdComplete',
  'findById',
  'findByIdComplete',
  'genericError',
  'countParticipants',
  'delete',
  'removeFromCourse',
  'removeFromCourseComplete',
  'removeFromSelection',
  'addToCourse',
  'addToCourseComplete'
]);

export default GroupActions;
