import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typo from '../Typo';

const Filter = ({ info, handleFilter, selected }) => {
  const { t } = useTranslation();

  const handleClick = (e) => {
    handleFilter(e.currentTarget.value)
  }

  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleClick}
      aria-label="filter"
    >
      {info?.map(filter => <ToggleButton key={filter.value} value={filter.value}>
        <Typo.SmallText>{t(filter.label)}</Typo.SmallText>
      </ToggleButton>)}
    </ToggleButtonGroup>
  )
}

Filter.displayName = 'Filter';

export default Filter;
