import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_SUCCEEDED, STATUS_LOADING, countryCodes } from '../../utils/constants';

import { storeApi, paymentApi, discountApi } from '../../utils/urls';
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------



export const fetchStore = createAsyncThunk('global/fetchStore', async ({ store, discountCode }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      discountCode,
      hostname: 't-app.s.coursio.com',
      name: store,
      skipItems: false,
    },
    method: 'read'
  };

  const res = await httpClient.post(storeApi(), body, getState, dispatch, rejectWithValue);

  return discountCode ? { ...res.data, discountCode: discountCode } : res.data;
})

export const fetchProviders = createAsyncThunk('global/fetchProviders', async (_, { getState, dispatch, rejectWithValue }) => {

  const body = {
    method: 'getProviders'
  };
  const res = await httpClient.post(paymentApi(), body, getState, dispatch, rejectWithValue);

  return res.data
})

export const fetchDiscount = createAsyncThunk('global/discount', async (unknown, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'read'
  };

  const res = await httpClient.post(discountApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

// ----------------- Reducers -----------------------------

const initialState = {
  status: 'idle',
  storeName: null,
  paymentType: 9,
  canIEdit: false,
  owner: {},
  theme: {},
  providers: [],
  discountCode: null,
  hideVAT: false,
  docs: '',
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setStoreName(state, action) {
      state.storeName = action.payload
    },
    setPaymentType(state, action) {
      state.paymentType = action.payload
    },
    setDiscountCode(state, action) {
      state.discountCode = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStore.pending, (state) => {
        state.status = STATUS_LOADING
      })
      .addCase(fetchStore.rejected, (state) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchStore.fulfilled, (state, action) => {
        if (action.payload.error) {
          state.status = STATUS_FAILED
          return
        }
        state.status = STATUS_SUCCEEDED;
        const { owner, theme, canIEdit, discountCode, countryCode, docs, business, ga } = action.payload
        state.ga = ga
        state.discountCode = discountCode;
        state.canIEdit = canIEdit;
        state.owner = owner;
        state.theme = theme;
        state.countryCode = countryCode;
        state.paymentType = countryCodes.includes(countryCode) ? 9 : 6;
        state.docs = docs
        state.hideVAT = business?.exVat

      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.providers = action.payload
      })

  }
})

export const { setStoreName, setPaymentType, setDiscountCode } = globalSlice.actions

export default globalSlice.reducer

// ----------------- Selectors -----------------------------

export const selectStoreName = state => state.webshop.global.storeName;
export const selectUserTheme = state => state.webshop.global.theme;
export const selectPaymentType = state => state.webshop.global.paymentType;
export const selectCountry = state => state.webshop.global.countryCode;
export const selectStoreStatus = state => state.webshop.global.status;
export const selectGA = state => state.webshop.global.ga;

//Can I edit exactly what? all store? specific courses?
export const selectCanIEdit = state => state.webshop.global.canIEdit;

export const selectStoreOwner = state => state.webshop.global.owner;
export const selectProviders = state => state.webshop.global.providers;
export const selectOwnerName = state => state.webshop.global.owner.name;
export const selectDocs = state => state.webshop.global.docs;
export const selectDiscountCode = state => state.webshop.global.discountCode;
export const selectCurrentStoreHideVat = state => state.webshop.global.hideVAT;
