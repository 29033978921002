import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Textinput, Typo, Modal } from '../../ui';
import styled from 'styled-components';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FlexContainer = styled.div`
  display:flex;
  align-items: center;
`;

const ContentSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:1rem;
`

const QuizEdit = ({ isDialogOpen, closeDialog, quizInfo, onSave }) => {
  const { t } = useTranslation();
  const [repeatableSwitch, setRepeatableSwitch] = useState(false);
  const [hideAnswersSwitch, setHideAnswersSwitch] = useState(false);
  const quizSettingsRef = useRef({});

  useEffect(() => {
    if (Object.keys(quizInfo).length > 0) {
      setRepeatableSwitch(quizInfo.repeatable);
      setHideAnswersSwitch(quizInfo.hideAnswers);
    }
  }, [quizInfo])

  const handleCloseDialog = useCallback(() => {
    closeDialog()
  }, [closeDialog]);

  const handleCreateQuiz = useCallback(() => {
    const {
      quizName, repeatable, hideAnswers,
      welcomeText, description, successText,
      failText, threshold
    } = quizSettingsRef.current;
    onSave({
      name: quizName.value,
      repeatable: repeatable.checked,
      hideAnswers: hideAnswers.checked,
      welcomeText: welcomeText.value,
      descriptionText: description.value,
      successText: successText.value,
      failText: failText.value,
      threshold: threshold.value
    });
    quizSettingsRef.current = {};
    handleCloseDialog();
  }, [handleCloseDialog, onSave])

  const handleSwitch = useCallback((setter, e) => {
    setter(e.target.checked)
  }, []);

  return (
    <Modal
      open={isDialogOpen}
      showActions
      width='600px'
      onClose={handleCloseDialog}
      onAccept={handleCreateQuiz}
      acceptLabel={!!Object.keys(quizInfo).length ? t('globals.save') : t('globals.create')}
      title={t('quiz.newQuiz')}
    >
      <>
        <div>
          <Typo.Text>{t('quiz.quizName')}</Typo.Text>
          <Textinput
            innerRef={el => quizSettingsRef.current['quizName'] = el}
            defaultValue={quizInfo?.name}
          />
        </div>
        <ContentSwitch>
          <Typo.Text>{t('quiz.allowToRetake')}</Typo.Text>
          <FlexContainer>
            <Switch
              inputRef={el => quizSettingsRef.current['repeatable'] = el}
              size="small"
              checked={repeatableSwitch}
              onChange={e => handleSwitch(setRepeatableSwitch, e)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typo.Info>{repeatableSwitch ? t('globals.enabled') : t('globals.disabled')}</Typo.Info>
          </FlexContainer>
        </ContentSwitch>
        <ContentSwitch>
          <Typo.Text>{t('quiz.hideAnswers')}</Typo.Text>
          <FlexContainer>
            <Switch
              size="small"
              inputRef={el => quizSettingsRef.current['hideAnswers'] = el}
              checked={hideAnswersSwitch}
              onChange={e => handleSwitch(setHideAnswersSwitch, e)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typo.Info>{hideAnswersSwitch ? t('globals.enabled') : t('globals.disabled')}</Typo.Info>
          </FlexContainer>
        </ContentSwitch>
        <div>
          <Typo.Text>{t('quiz.welcomeMsg')}</Typo.Text>
          <Textinput
            isMultiline
            innerRef={el => quizSettingsRef.current['welcomeText'] = el}
            defaultValue={quizInfo.welcomeText}
          />
        </div>
        <div>
          <Typo.Text>{t('globals.description')}</Typo.Text>
          <Textinput
            innerRef={el => quizSettingsRef.current['description'] = el}
            defaultValue={quizInfo.descriptionText}
            isMultiline
          />
        </div>
        <div>
          <Typo.Text>{t('quiz.correctMsg')}</Typo.Text>
          <Textinput
            innerRef={el => quizSettingsRef.current['successText'] = el}
            defaultValue={quizInfo.successText}
          />
        </div>
        <div>
          <Typo.Text>{t('quiz.wrongMsg')}</Typo.Text>
          <Textinput
            propName={'failText'}
            innerRef={el => quizSettingsRef.current['failText'] = el}
            defaultValue={quizInfo.failText}
          />
        </div>
        <div>
          <Typo.Text>{t('quiz.minPercentage')}</Typo.Text>
          <Textinput
            propName={'threshold'}
            innerRef={el => quizSettingsRef.current['threshold'] = el}
            defaultValue={quizInfo.threshold}
          />
        </div>
      </>
    </Modal>
  )
};

export default QuizEdit;
