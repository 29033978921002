import React from 'react'
import theme from '../theme';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

const ButtonsComponent = ({ label, bgColor, variant, otherProps }) => {
  const { t } = useTranslation();
  
  return (
    <Button $bgcolor={bgColor} $variant={variant} {...otherProps} >{t(label)}</Button>
  )
};


const Buttons = {
  Cancel: (props) =>  <ButtonsComponent label='globals.cancel' bgColor={theme.colors.NEUTRAL_20} variant='customized' otherProps={props} />
  
}

export default Buttons;
