import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Modal, Textinput, Typo } from '../../../../ui';

const EditSectionTitles = ({ open, info, onClose, context, addToPreview, isPackage }) => {

  const { t } = useTranslation();

  const [sectionTitlesInfo, setSectionTitlesInfo] = useState({});

  const titles = {
  course:{
    coursesIncluded: 'Courses included in the package',
    infoPointsTitle: 'Information points with icons',
    courseContentTitle: 'Course content',
    relatedCourses: 'List of courses',
  }, 
  home:{
    occasionCourses:'Highlight courses',
    infoPointsTitle: 'Information points',
    popularCourse: 'promote a course',
    coursesTitle: 'list of courses',
  }}

  useEffect(() => {
    if (info) setSectionTitlesInfo(info);
  }, [info]);

  const handleSectionTitle = (e, prop)=>{
    setSectionTitlesInfo({...sectionTitlesInfo, [prop]:e.currentTarget.value})
  }

  const onAccept = useCallback(() => {
    addToPreview(sectionTitlesInfo);
  }, [sectionTitlesInfo, addToPreview]);

  return (
    <Modal
      open={open}
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel='Add to preview'
      titleChildren={<>
        <Typo.ContentSmallHeader>Edit your section titles</Typo.ContentSmallHeader>
        <Typo.Text>Each section with a title is editable, if you leave them empty it will show the default text</Typo.Text>
      </>
      }
    >
      <Flex direction='column' width='80%' align='flex-start' gap='20'>
      {Object.keys(titles[context]).map((key)=>
        !isPackage && key === 'coursesIncluded' ? <></> :
        <Flex direction='column' width='100%' key={key}>
          <Typo.Text>{titles[context][key]}</Typo.Text>
          <Textinput
            label={`Default title: ${t(`webshop.${context}.${key}`)}`}
            propName={`section-title-${key}`}
            defaultValue={sectionTitlesInfo[key] || ''}
            onBlurValue={(e) => handleSectionTitle(e, key)}
          />
        </Flex>
      
      )}
      </Flex>
    </Modal>
  );
};

export default EditSectionTitles;

