import React from 'react';
import styled from 'styled-components'

const CourseCardWrapperStyled = styled.div`
  width: 100%;
  max-width: ${(props) => props.$inGrid ? '100%' : '375px'}; 
  max-height: 640px; //needed for course settings so it does not grow indefetly
  position: relative;
  box-shadow: ${(props) => props.theme.shadows.BOX_SHADOW_MINI};
  display: flex;
  flex-direction: column;
  // border-radius: 26px;
  overflow: hidden;
`;

const CourseCardWrapper = ({ children, onClick, inGrid }) => {
  return (
    <CourseCardWrapperStyled onClick={onClick} $inGrid={inGrid}>
      {children}
    </CourseCardWrapperStyled>
  );
};

export default CourseCardWrapper;
