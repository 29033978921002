import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Flex, Typo } from '../../../ui';
import { selectQuestions } from '../../tasks/tasksSlice';
import AnswerStatusIcon from './AnswerStatusIcon';
import TaskModal from './TaskModal';
import ContentWrapper from './ContentWrapper';

const TasksByUser = ({ userTasks, open, handleClose, courseId }) => {
  const questions = useSelector(selectQuestions)

  const getQuestion = (task) => {
    const question = questions.find((item) => +item.taskId === +task.id)
    return question?.main
  }

  return (
    <TaskModal
      open={open}
      onClose={handleClose}
      title={
        <Flex gap='10' width='100%'>
          <Avatar size='small' name={userTasks?.name} url={userTasks?.avatar} hasBadge />
          <Typo.TextTitle>{userTasks?.name}</Typo.TextTitle>
        </Flex>
      }
    >
      {!!userTasks &&
        Object.values(userTasks?.tasks).map((task) => {
          return <ContentWrapper
            key={task.id + 'accordion'}
            item={task}
            userName={userTasks.name}
            courseId={courseId}
            taskType={task.type}
          >
            <Flex gap='10' width='100%'>
              <AnswerStatusIcon grade={task.lastAnswerGrade} taskType={task.type} />
              <Flex direction='column' align='flex-start'>
                <Typo.TextTitle>{getQuestion(task)}</Typo.TextTitle>
                <Typo.Info>{`${task.lastUpdate.type === 'response' ? 'response submited' : 'feedback given'}: ${task.lastUpdate.date}`}</Typo.Info>
              </Flex>
            </Flex>
          </ContentWrapper>
        })
      }
    </TaskModal>
  );
};

export default TasksByUser;
