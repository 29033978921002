import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Accordion, AccordionSummary } from '@mui/material';
import { Icon } from '../../../ui';
import { saveTaskFeedback } from '../../tasks/tasksSlice';

import AnswersList from './AnswersList';
import EditFeedback from './EditFeedback';

const ContentWrapper = ({ item, userName, children, courseId, taskType }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState();
  const [modalContent, setModalContent] = useState({});

  const onCloseEditFeedback = () => {
    setModalContent({})
  }

  const handleOpenFeedback = (options) => {
    setModalContent({ ...options })
  }

  const expandContent = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  const saveFeedback = feedbackData => {
    dispatch(saveTaskFeedback({ feedbackData, courseId }))
    onCloseEditFeedback()
  };

  return (
    <>
      <Accordion
        sx={{ width: '100%' }}
        onChange={expandContent(item.id)}
        expanded={+expanded === +item.id}
      >
        <AccordionSummary expandIcon={<Icon.ExpandMore />}>
          {children}
        </AccordionSummary>
        <AnswersList
          answers={item.answers}
          parentId={item.id}
          taskType={taskType}
          userName={userName}
          handleOpenFeedback={handleOpenFeedback}
        />
      </Accordion>
      <EditFeedback
        open={!!modalContent.response}
        onClose={onCloseEditFeedback}
        content={modalContent}
        onAccept={saveFeedback}
      />
    </>
  );
};

export default ContentWrapper;
