import React from 'react';
import styled, { css } from 'styled-components';
import { Flex, IconAsButton, theme, Typo } from '../../ui';

const NotificationsItemWrapper = styled.li`
  list-style-type: none;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.NEUTRAL_60};
  padding: 25px 20px;

  ${({ $isNew }) =>
    $isNew === true && css`
      background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT};
    `
  } 
`;

const NotificationItem = ({ info, selectClick }) => {
  const { message, isRead, created } = info

  //use just message when we go live for everyone
  // const betaMessage = message.replace(/app/g, 'beta')

  const handleClick = (e) => {
    if (e.target instanceof HTMLAnchorElement)
      selectClick()
  }


  return (
    <NotificationsItemWrapper $isNew={isRead === 0} onClick={handleClick}>
      <Flex justify='space-between'>
        <Flex direction='column' gap='5' align='flex-start'>
          <Typo.Info>{created}</Typo.Info>
          <Typo.MediumText fontWeight={isRead === 0 ? '600' : undefined} dangerouslySetInnerHTML={{ __html: message }} ></Typo.MediumText>

        </Flex>
        <IconAsButton color={theme.colors.PRIMARY} clickHandle={selectClick} iconName={isRead === 0 ? 'Archive' : 'Unarchive'} />
      </Flex>
    </NotificationsItemWrapper>
  )
};

export default NotificationItem;
