import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourseByUserSlag, selectCourse } from './courseSlice';
import { Outlet, useNavigate } from 'react-router-dom';

import lazyLegacyRoot from "../../lazyLegacyRoot";
//LEGACY
import { useSlugName, useWebshopName } from '../../hooks/readFromUrls';
const LegacyCourse = lazyLegacyRoot(() => import('../../../../legacy/src/js/components/Route/Course'), 'empty-div')

const CoursePreviewContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector(selectCourse);
  const slug = useSlugName();
  const ownerSlug = useWebshopName()

  useEffect(() => {
    dispatch(fetchCourseByUserSlag({ username: ownerSlug, slugName: slug }))
  }, [dispatch, slug, ownerSlug])

  return (
    <>
      <LegacyCourse
        course={course}
        params={course}
        navigate={navigate}
      />
      <Outlet />
    </>
  );
}

export default CoursePreviewContainer;
