import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Textinput, Modal, Figure } from '../../../ui';
import CourseImageWrapper from '../../../ui/CourseCard/CourseImageWrapper';
import ImageUploader from '../../media/uploaders/ImageUploader';
import { createCourse } from '../courseSlice';

const CreateCourseModel = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseInfo, setCourseInfo] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [courseName, setCourseName] = useState('');

  const closeModal = () => {
    setCourseInfo(null);
    setIsModalOpen(false);
    onClose();
  }

  useEffect(() => {
    setIsModalOpen(open);
  }, [open])

  useEffect(() => {
    
    setIsDisabled(!courseName?.length > 0)
    
  }, [courseName])

  const changeCourseName = (e) => {
    setCourseName(e.target.value);
  }

  const saveImage = useCallback(image => {
    if (image) setCourseInfo({ ...courseInfo, coverImage: image });
  }, [courseInfo])

  const handleCreateCourse = () => {    
    dispatch(createCourse({ name: courseName, coverImage: courseInfo?.coverImage }))
    closeModal()
  };

  const imageUrl = !!courseInfo?.coverImage && (courseInfo?.coverImage?.url || courseInfo?.coverImage[0]?.url)

  return (
    <Modal
      open={isModalOpen}
      showActions
      acceptIsDisabled={isDisabled}
      onAccept={handleCreateCourse}
      acceptLabel={t('globals.save')}
      onClose={closeModal}
      title={t('course.createNewCourse')}
      width='650px'
    >
      <CourseImageWrapper >
        <ImageUploader
          type={'single'}
          id={'courseEdit'}
          saveAction={saveImage}
          imageToPreview={imageUrl}
          label={t('webshop.addCoverImage')}
          iconName='Add'
        >
          {imageUrl && <Figure.Rectangle><img alt={'imageToPreview'} src={imageUrl} loading='lazy' /></Figure.Rectangle>}
        </ImageUploader>
      </CourseImageWrapper>
      <Textinput
        label={ t('course.courseName')}
        propName={'name'}
        onChange={changeCourseName}
        value={courseName}
      />
    </Modal>
  );
};

export default CreateCourseModel;
