import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components'
import Papa from 'papaparse';
import { Flex, Icon, Modal, theme, Toggler, Typo, Button } from '../../../ui';
import { fetchCoursesByGroupId, fetchEditableCourses, selectCourses } from '../../courses/coursesSlice';
import { addUserToGroup, fetchReaders, fetchUsersByGroupId, selectUsers } from '../../users/usersSlice';
import { fetchEditableGroups, selectGroups, addGroupToCourse } from '../../groups/groupsSlice';
import { selectCourseDurations } from '../../course/courseSlice';
import { selectIsInvitesModalOpen, selectModalProps, sendInvitations, setInviteModalIsOpen } from '../invitesSlice';
import UcpPeriodSelector from '../../../components/UcpPeriodSelector';
import SearchSelect from '../../../ui/SearchSelect';
import { useTranslation } from 'react-i18next';
import Buttons from '../../../ui/Buttons';
import FileUploader from '../../media/uploaders/FileUploader';

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap:1rem;
`

const Controls = styled(Flex)` 
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  padding-top:15px;
`

const SendInvite = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isGroup, setIsGroup] = useState(false);
  const [duration, setDuration] = useState({});
  const [contentValid, setContentValid] = useState(false);

  const courseDurations = useSelector(selectCourseDurations);
  const courses = useSelector(selectCourses)
  const users = useSelector(selectUsers)
  const groups = useSelector(selectGroups)
  const isModalOpen = useSelector(selectIsInvitesModalOpen)
  const modalProps = useSelector(selectModalProps)

  const { group, course, select, callbackFunc } = modalProps

  useEffect(() => {
    if (isModalOpen) {
      dispatch(fetchEditableCourses({}))
      dispatch(fetchEditableGroups({ disableCounts: true }))
      dispatch(fetchReaders())
    }
  }, [dispatch, isModalOpen])

  useEffect(() => {
    let isValid = true
    const hasUCP = duration.hasOwnProperty('id')
    if (!group && !course)
      isValid = !!hasUCP && !!selectedCourses.length && !!(selectedGroups.length || selectedUsers.length);
    else if (select === 'courses') isValid = !!hasUCP && !!selectedCourses.length
    else isValid = selectedUsers.length

    setContentValid(isValid);
  }, [duration, selectedGroups, selectedCourses, select, selectedUsers, course, group])

  const handleGroupOrUser = useCallback((checked) => {
    setIsGroup(checked)
  }, [])

  const updateLists = useCallback(() => {
    if (callbackFunc === 'UsersByGroupId')
      dispatch(fetchUsersByGroupId({ circleId: group.id }))
    else if (callbackFunc === 'CoursesByGroupId')
      dispatch(fetchCoursesByGroupId({ circleId: group.id }))
  }, [callbackFunc, dispatch, group])

  const clearState = useCallback(() => {
    setSelectedGroups([])
    setSelectedCourses([])
    setSelectedUsers([])
    setIsGroup(false)
    setDuration({})
    setContentValid(false)
  }, [])

  const closeDialog = useCallback(() => {
    dispatch(setInviteModalIsOpen(false))
    clearState();
  }, [dispatch, clearState])

  const sendInvitation = useCallback(() => {
    const expires = duration.id !== 0 ? dayjs().add(duration.id, 'month').format('YYYY-MM-DD') : '0000-00-00';
    const courseIds = selectedCourses?.map((course) => course.id)
    const groupsById = selectedGroups.map((group) => group.id)
    const usersById = selectedUsers.map((user) => user.id).filter(Boolean)
    const emails = selectedUsers.map((user) => user.inputValue || (!user.id && (user.email || user))).filter(Boolean)

    if (group?.id) {
      select === 'users' && usersById.length && dispatch(addUserToGroup({ userId: usersById, circleId: group?.id })).then(() => !!callbackFunc && updateLists())
      select === 'courses' && courseIds.length && dispatch(addGroupToCourse({ ucpPeriod: expires, circleId: group.id, courseIds: courseIds })).then(() => !!callbackFunc && updateLists())
      emails.length && dispatch(sendInvitations({ expires, byWhat: 'emails', withWhat: emails, circleId: group.id })).then(() => !!callbackFunc && updateLists())
    } else {
      const coursesToBeInvited = course?.id ? [course?.id] : courseIds
      groupsById.length && isGroup && dispatch(sendInvitations({ expires, byWhat: 'groups', withWhat: groupsById, courseIds: coursesToBeInvited })).then(() => !!callbackFunc && updateLists())
      usersById.length && !isGroup && dispatch(sendInvitations({ expires, byWhat: 'users', withWhat: usersById, courseIds: coursesToBeInvited })).then(() => !!callbackFunc && updateLists())
      emails.length && dispatch(sendInvitations({ expires, byWhat: 'emails', withWhat: emails, courseIds: coursesToBeInvited })).then(() => !!callbackFunc && updateLists())
    }

    closeDialog()
  }, [duration, selectedGroups, selectedCourses, select, selectedUsers, dispatch, isGroup, closeDialog, course, group, callbackFunc, updateLists])

  const changeHandler = useCallback((file) => {
    if(file)
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: function (header) {
          return header.toLocaleLowerCase()
        },
        complete: function (results) {
          const selectedUsersList = [];
          if (results.data.length) {
            results.data.forEach(user => {
              selectedUsersList.push({
                email: user.email.trim(),
                name: user.hasOwnProperty('name') ? user.name.trim() : user.email.trim()
              })
            })
          } else {
            return;
          }
          setSelectedUsers(selectedUsersList)
        },
      });
  }, []);

  return (
    <Modal
      open={isModalOpen}
      onClose={closeDialog}
      width={'525px'}
      title={t('invites.sentTitle')}
    >
      <Wrapper>
      {course?.id &&
        <div>
          <Flex gap='10'>
            <Icon.Course color={theme.colors.NEUTRAL_60} />
            <Typo.TextTitle>{t('invites.invCourse', { courseName: course.name })}</Typo.TextTitle>
          </Flex>
        </div>}
      {!!group?.id ?
        <div>
          <Flex gap='10'>
            <Icon.Groups />
            <Typo.TextTitle>{t('invites.invGroup', { groupName: group.name })}</Typo.TextTitle>
          </Flex>
        </div>
        : <div>
          <Typo.TextTitle>{t('invites.invUserOrGroup')}</Typo.TextTitle>
          <Toggler leftText={t('globals.users')}  rightText={t('globals.groups')} onClick={handleGroupOrUser} />
        </div>
      }
      {isGroup ?
        <div>
          <Typo.TextTitle>{t('invites.selectGroups')}</Typo.TextTitle>
          <SearchSelect
            multiple={true}
            setValue={setSelectedGroups}
            selectedOptions={selectedGroups}
            id='groups'
            options={groups.map((item) => ({ name: item.name, id: item.id }))}
          />
        </div>
        : select !== 'courses' &&
        <div>
          <Typo.TextTitle>{t('invites.addUsers')}</Typo.TextTitle>
          <SearchSelect
            creatable
            optionType={'email'}
            multiple={true}
            setValue={setSelectedUsers}
            selectedOptions={selectedUsers}
            id='users'
            options={users}
            label={t('invites.addUserIfDoesntExist')}
            renderOption={(props, option) => {
              return <li {...props} key={option.id || option}>
                <div >
                  <Typo.Text >{option.name}</Typo.Text>
                  <Typo.Info >{option.email}</Typo.Info>
                </div>
              </li>
            }}
          />
          <Flex gap='10'>
            <Typo.SmallText $marginBottom='5px'>{t('invites.invCsv')} {t('invites.csvInstruction')}</Typo.SmallText>
            <FileUploader variant='customized' bgcolor={theme.colors.COURSIO_NEW_EXTRA}
              accept='.csv' 
              id={'inviteUploadfile'} 
              saveAction={changeHandler} 
              label={t('invites.csvUpload')} 
              iconName='Upload' 
              noMediaLibrary/>
          </Flex>
        </div>
      }
      {(select !== 'users' && !course) &&
        <div>
          <Typo.TextTitle>{t('invites.selectCourses')}</Typo.TextTitle>
          <SearchSelect
            multiple={true}
            setValue={setSelectedCourses}
            selectedOptions={selectedCourses}
            id='courses'
            label={t('invites.courseInstruction')}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={courses.map((item) => ({ name: `${item.name} (${item.id})`, id: item.id }))}
          />
        </div>
      }
      {/* this should be presented with course is present not for ading users to groups */}
      { !(select === 'users' && group?.id) && <div>
        <UcpPeriodSelector
          setDuration={setDuration}
          duration={duration}
          options={courseDurations}
        />
      </div>}

      </Wrapper>

      <Controls>
        <Buttons.Cancel onClick={closeDialog} />
        <Button onClick={sendInvitation} disabled={!contentValid}>{t('globals.send')}</Button>
      </Controls>
    </Modal>
  );
}

export default SendInvite;
