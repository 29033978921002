import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { SubPage, IconAsButton, ListTable, Typo, Loading } from '../../../ui';
import { STATUS_LOADING } from '../../../utils/constants';
import { CONTENT_MINI } from '../../../utils/media';
import { selectIsIncarnated } from '../../auth/authSlice';
import { downloadDiploma, emailDiploma, getDiplomaList, selectDiplomaList, selectStatus } from '../../diploma/diplomaSlice';

const ProfileDiplomas = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const diplomaList = useSelector(selectDiplomaList)
  const status = useSelector(selectStatus)
  const isIncarnated = useSelector(selectIsIncarnated);


  useEffect(() => {
    dispatch(getDiplomaList())
  }, [dispatch, isIncarnated])

  const downloadPDF = (pdf) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = 'diploma.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const emailMeDiploma = (courseId) => dispatch(emailDiploma({ courseId }))

  const handleDownloadDiploma = (courseId) => {
    dispatch(downloadDiploma({ courseId }))
      .then((res) => {
        if (!!res.payload)
          downloadPDF(res.payload)
      })
  }

  const tableInfo = [
    { label: t('globals.course'), data: (item) => item.courseName },
    {
      label: t('profile.download'),
      data: (item) =>
        <IconAsButton clickHandle={(e) => handleDownloadDiploma(item.courseId)} iconName='Download' tooltipLabel={t('profile.download')} />
    },
    {
      label: t('profile.emailMe'),
      data: (item) =>
        <IconAsButton clickHandle={(e) => emailMeDiploma(item.courseId)} iconName='Email' tooltipLabel={t('profile.sendBy')} />
    },
  ]

  return (
    <SubPage size={CONTENT_MINI} align='flex-start' >
      {status === STATUS_LOADING ? <Loading />
        : !!diplomaList.length ? <ListTable
          tableInfo={tableInfo}
          data={diplomaList}
          tableName={'diplomas'}
        />
          : <Typo.BigText>{t('profile.noDiploma')}</Typo.BigText>}
    </SubPage >
  );
};

export default ProfileDiplomas;
