export const CONTENT_FULL = '100%';
export const CONTENT_WIDE = '1570';
export const CONTENT_STANDARD = '1170';
export const CONTENT_SMALL = '870';
export const CONTENT_MINI = '750';

export const breakpoints = {
  mobile: 384,
  phablet: 560,
  tablet: 768,
  laptop: 1200,
  desktop: 1440,
};

function lessThan(maxKey) {
  return `@media (max-width: ${breakpoints[maxKey] - 1}px)`;
}

function greaterThan(minKey) {
  return `@media (min-width: ${breakpoints[minKey]}px)`;
}

function between(minKey, maxKey) {
  return `@media (min-width: ${breakpoints[minKey]}px) and @media (max-width: ${breakpoints[maxKey] - 1}px)`;
}

export const media = {
  lessThan,
  greaterThan,
  between,
}

// example of usage:

// import {media} from 'utils/media'

// const Item = styled.div `
//   ${media.between('mobile', 'tablet')} {

//   }
//   ${media.greaterThan('tablet')} {

//   }
// `
