import React, { useEffect, useState } from 'react';
import { getCookie } from '../../helpers/cookie';
import { CookiesBanner } from '../../ui';
import dayjs from 'dayjs';


const ManageCookiesBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false)

  //Missing: save the strictly always, manage those selected making sure those not accepted get cleared

  useEffect(() => {
    setAcceptedCookies(getCookie('cookie_notice_accepted'));
  }, [])

  const accept = (cookiesLevel) => {
    document.cookie = `cookie_notice_accepted=${JSON.stringify(cookiesLevel)};expires=${dayjs().add(1, 'year')}`;
    setAcceptedCookies(cookiesLevel);
  }

  return (
    <CookiesBanner visible={!acceptedCookies} accept={accept} />
  );
}

export default ManageCookiesBanner;
