import Reflux from 'reflux';

const InviteActions = Reflux.createActions([
  'setCourses',
  'setStudents',
  'setCourseAccess',
  'setGroups',
  'setStudentInviteMode',
  'reset',
  'disableSteps',
  'nextStep',
  'inviteByCircleIds',
  'inviteByUserIds',
  'inviteByEmails',
  'inviteToCircle'
]);

export default InviteActions;
