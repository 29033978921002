import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Typo, ListTable, Flex, Icon, theme, SubPage } from '../../../ui';
import { Switch } from '@mui/material';
import { fetchCourseById, selectCourse, selectCourseQuizSettings, updateCourse } from '../courseSlice';
import QuizEdit from '../../quizes/QuizEdit';
import { createQuiz, fetchQuizes, resetUserQuiz, selectQuizesInfo } from '../../quizes/quizesSlice';
import { durationLike, toRelativeCalendar } from '../../../helpers/format';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import QuizResultsModal from './QuizResultsModal';

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
`;

const QuizInfo = styled.div`
  padding: 2rem 2rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.NEUTRAL_40};
  border-radius: 4px;
  padding: 0.9rem 0.9rem 0 0.9rem;
  align-items: ${({ align }) => align};
  margin: 0 10px;
  box-shadow: ${({ selected }) => selected ? `${theme.shadows.BOX_SHADOW};` : 'undefined;'}
  cursor: ${({ $hasAction }) => $hasAction ? 'pointer;' : 'undefined;'};
`;

const QuizCardsContainer = styled.div`
  display: flex;
  margin: 50px 0;
`;

const CourseQuiz = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const course = useSelector(selectCourse);
  const quizes = useSelector(selectQuizesInfo);
  const quizPages = useSelector(selectCourseQuizSettings);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [useQuizProgress, setUseQuizProgress] = useState(false);
  const [hasQuiz, setHasQuiz] = useState(false);
  const [quizInfo, setQuizInfo] = useState({});
  const [quizAction, setQuizAction] = useState('');
  const [activeQuiz, setActiveQuiz] = useState(null);
  const [tableInfo, setTableInfo] = useState({});
  const [userAnswers, setUserAnswers] = useState(null);
  const [userToReset, setUserToReset] = useState(null);

  useEffect(() => {
    if (course?.id) {
      dispatch(fetchQuizes({ courseId: course.id }))
    }
  }, [course, dispatch])

  useEffect(() => {
    const { settings } = course;

    if (settings?.hasOwnProperty('quiz')) {
      const { quiz } = settings;

      setUseQuizProgress(quiz.diplomaByQuizProgress)
      setHasQuiz(quiz.enabled);
    }
  }, [course]);

  const handleToggleDialog = useCallback(quizArg => {
    quizArg?.hasOwnProperty('type') && setQuizAction(quizArg.type);
    setIsDialogOpen(!isDialogOpen);
    quizArg?.hasOwnProperty('quizId') && setQuizInfo(quizes.find((quiz) => quiz.id === quizArg.quizId));
  }, [quizes, isDialogOpen, setQuizAction, setQuizInfo]);

  const closeQuizDialog = () => {
    setIsDialogOpen(false);
    setQuizInfo({});
  };

  const handleCreateQuiz = (quizData) => {
    handleToggleDialog(null);
    dispatch(createQuiz({ ...quizData, courseId: course.id, quizPages: quizPages, settings: course.settings })).then(() => {
      dispatch(fetchCourseById({ courseId: course.id }))
    })
  };

  const handleResetUserQuiz = () => {
    dispatch(resetUserQuiz({ userId: userToReset.id, quiz: activeQuiz })).then(() => setUserToReset(null))
  }

  const updateQuizProgress = useCallback((e) => {
    setUseQuizProgress(e.target.checked);
    const courseData = {
      id: course.id,
      settings: {
        quiz: {
          ...course.settings.quiz,
          diplomaByQuizProgress: e.target.checked,
        }
      }
    }
    dispatch(updateCourse({ courseData }))
  }, [dispatch, course]);

  const activateQuizStats = quiz => {
    if (quiz.usersCount > 0) {
      setActiveQuiz(quiz);
      setTableInfoWithQuiz(quiz);
    }
  };

  const setTableInfoWithQuiz = useCallback(quiz => {
    setTableInfo([
      // { label: t('globals.user'), hasSorting: { sortedMethod: 'string', propVal: 'name' }, data: (user) => user.name },
      { label: t('globals.user'), hasSorting: false, data: (user) => user.name },
      { label: t('globals.finished'), hasSorting: false, data: (user) => toRelativeCalendar(user.statistics && user.endTime) },
      { label: t('globals.status'), hasSorting: false, data: (user) => user.status === 'QUIZ_SUCCESS' ? t('globals.success') : t('globals.failed') },
      { label: t('globals.score'), hasSorting: false, data: (user) => user.statistics && user.score },
      { label: t('globals.duration'), hasSorting: false, data: (user) => durationLike(user.statistics && user.duration) },
      {
        label: 'Actions', menuOptions: () => [
          { label: t('course.seeTaskAnswers'), onClick: (item) => setUserAnswers(item) },
          { label: t('course.clearTaskAnswers'), onClick: (item) => setUserToReset(item) },
        ]
      },
    ]);
  }, [t]);

  const saveQuizEdit = useCallback(quizEditData => {
    const courseData = {
      id: course.id,
      settings: {
        quiz: {
          ...course.settings.quiz,
          // Should user be able to disable quiz ??
          enabled: true,
          pages: {
            ...quizPages,
            [quizInfo.id]: {
              ...quizEditData
            }
          }
        }
      }
    }
    dispatch(updateCourse({ courseData }))
  }, [dispatch, quizInfo, course, quizPages]);

  useEffect(() => {
    if (quizes.length && quizes[0].usersCount) {
      setActiveQuiz(quizes[0]);
      setTableInfoWithQuiz(quizes[0]);
    } else {
      setActiveQuiz(null)
    }
  }, [quizes, course, setTableInfoWithQuiz]);

  return (
    <>
      <SubPage staticElements={
        <Header>
          <Button icon='Edit' onClick={() => handleToggleDialog({ type: 'create' })}>{t('course.createQuiz')}</Button>
        </Header>
      }>
        <Flex align='center' justify='center' gap={30} width='100%'>
          <Typo.Text >{t('course.useQuizForDiploma')}</Typo.Text>
          <Flex width={'10%'} >
            <Switch
              size="small"
              checked={useQuizProgress}
              onChange={updateQuizProgress}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typo.Info>{useQuizProgress ? t('globals.enabled') : t('globals.disabled')}</Typo.Info>
          </Flex>
        </Flex>
        {!hasQuiz ?
          <Flex><Typo.TextTitle>{t('course.noQuizInCourse')}</Typo.TextTitle></Flex>
          : <QuizCardsContainer >
            {quizes.map((quiz) =>
              <Content key={quiz.id} $hasAction={!!quiz.usersCount && quizes.length > 1} onClick={() => activateQuizStats(quiz)} selected={quiz.id === activeQuiz?.id} >
                <Flex>
                  <Typo.ContentSmallHeader textAlign='left'>{quiz.name}</Typo.ContentSmallHeader>
                  <Icon.Edit size='x-small' onClick={() => handleToggleDialog({ quizId: quiz.id, type: 'edit' })} />
                </Flex>
                <QuizInfo>
                  <Typo.SmallText>{t('course.quizUserPassed')} {quiz.totalPassed || 0}  ({quiz.usersCount || 0})</Typo.SmallText>
                  <Typo.SmallText>{t('course.questions')} {quiz.tasks?.count}</Typo.SmallText>
                  <Typo.SmallText>{quiz.id}</Typo.SmallText>
                  <Typo.SmallText>{!!quiz.repeatable ? t('globals.repeatable') : t('globals.notRepeatable')}</Typo.SmallText>
                </QuizInfo>
              </Content>
            )}
          </QuizCardsContainer>
        }
        {activeQuiz && <Flex width='100%' direction='column'>
          <Typo.TextTitle $marginBottom='30px' $textAlign='left' >{activeQuiz.name}</Typo.TextTitle>

          <ListTable
            tableInfo={tableInfo}
            data={activeQuiz.users}
            onRowClick={setUserAnswers}
            tableName={'courseQuiz'}
            // resultSetter={setActiveQuiz}
            tableSetter={setTableInfo}
            additionalData={activeQuiz}
            listDataProp={'users'}
          />
        </Flex>}

        {/*
          <div>Use quiz for progress - updates course wiht diplomaByQuizProgress</div>
          <div>Enable quiz - creates a section and a page named quiz, update course with quiz enabled and page just created </div>
          <div>Allows users to retake quiz - updates course page with repeatable </div>
          <div>Hide answers when quiz completed - updates course page with hideAnswers</div>
        */}

        <QuizEdit 
          isDialogOpen={isDialogOpen}
          quizInfo={quizInfo}
          closeDialog={closeQuizDialog}
          onSave={quizAction === 'edit' ? saveQuizEdit : handleCreateQuiz} 
        />
        <QuizResultsModal 
          userAnswers={userAnswers} 
          questions={activeQuiz?.questions}
          onClose={() => setUserAnswers(null)}
        />
        <ConfirmDelete
          onClose={setUserToReset}
          onAcceptDelete={handleResetUserQuiz}
          open={!!userToReset?.id}
          description={t('course.clearQuizResultsWarning', { userToReset: userToReset?.name })}
        />

      </SubPage>
    </>
  )
};

export default CourseQuiz;
