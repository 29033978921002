import Reflux from 'reflux';

const AuthActions = Reflux.createActions([
  'login',
  'loginSuccess',
  'loginRestored',
  'loginFailed',
  'loginError',
  'logout',
  'incarnate',
  'wakeUp',
  'genericError',
  'readerMode',
  'builderMode',
  'register',
  'registerComplete',
  'setFacebookLoginUrl',
  'tryHashLogin',
  'requestNewPassword',
  'requestNewPasswordComplete',
  'restorePassword',
  'restorePasswordComplete',
  'setLocale'
]);

export default AuthActions;
