import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { SearchBar } from '../../../ui';
import { useTranslation } from 'react-i18next';

const SearchMediaData = ({ setQuery }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const searchRef = useRef();

  const clearSearch = useCallback(() => {
    searchRef.current.value = '';
    setQuery('')
  }, [searchRef, setQuery]);

  const handleSearch = useCallback(debounce((e) => {
    const value = searchRef.current.value;
    setQuery(value)

  }, 1000), [dispatch, searchRef]);

  return (
    <SearchBar
      placeholder={t('mediaLibrary.searchPlaceholder')}
      handleSearch={handleSearch}
      handleClear={clearSearch}
      disableClear={!searchRef.current?.value}
      inputRef={searchRef}
    />
  );
};

export default SearchMediaData;
