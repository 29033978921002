import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cloneDeep from 'lodash.clonedeep';

import { selectMyStore } from '../../auth/authSlice';
import { useSlugName } from '../../../hooks/readFromUrls';
import { Button, Flex, Typo, theme } from '../../../ui';
import { fetchCourse, saveCourse, selectLayout } from '../courseSlice';

import ShopCourseLayout from '../ShopViewer/components/ShopCourseLayout';
import { List, ListItem, ListItemButton } from '@mui/material';
import CheckboxButton from '../../../ui/CheckboxButton';
import CoverMediaLayout from './components/CoverMediaLayout';
import EditMainDescription from './components/EditMainDescription';
import EditCtaBanner from './components/EditCtaBanner';
import EditTeacher from './components/EditTeacher';
import BuildInfoIcons from '../components/InfoIconSection/BuildInfoIcons';
import SettingsButton from './SettingsButton';
import { media } from '../../../utils/media';
import EditSectionTitles from './components/EditSectionTitles';
import CustomFile from "./components/CustomFile";


const Content = styled(Flex)`
  padding:15px;
  overflow: hidden;
  width: 100%;

  display: flex;
  gap: 20px;
  flex-direction: column;
`
const Wrapper = styled(Flex)`
  ${media.lessThan('tablet')} {
    flex-direction:column;
    align-items: stretch;

  }
`
const PreviewContainer = styled.div`
  width:80%;
  border: 2px solid gray;
  height: calc(100vh - ${theme.headerSize} - 64px);
  overflow-y: scroll;

  ${media.lessThan('tablet')} {
    width:100%;
    height: 100%;
    overflow-y: visible;
    border: none;
  }
`

const EditShopCourseLayout = ({ isPackage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const slug = useSlugName()

  const myStore = useSelector(selectMyStore)
  const layoutStructure = useSelector(selectLayout)

  const [course, setCourse] = useState()

  const [openMainDescription, setOpenMainDescription] = useState(false);
  const [openCoverMedia, setOpenCoverMedia] = useState(false);
  const [openCtaBanner, setOpenCtaBanner] = useState(false);
  const [openInfoIcons, setOpenInfoIcons] = useState(false);
  const [openInfoPoints, setOpenInfoPoints] = useState(false);
  const [openTeacher, setOpenTeacher] = useState(false);
  const [openEditTitles, setOpenEditTitles] = useState(false);
  const [openCustomFile, setOpenCustomFile] = useState(false);
  const [needToBeSaved, setNeedToBeSaved] = useState(false);

  useEffect(() => {
    if (layoutStructure) {

      const tempLayout = cloneDeep(layoutStructure)
      tempLayout['activeModules'] = {
        teacher: !!tempLayout?.teacher && !!Object.keys(tempLayout?.teacher)?.length,
        infoPoints: !!tempLayout?.infoPoints && !!Object.keys(tempLayout?.infoPoints)?.length,
        infoIcons: !!tempLayout?.infoIcons && !!Object.keys(tempLayout?.infoIcons)?.length,
        coverMedia: !!tempLayout?.coverMedia?.image ||  !!tempLayout?.coverMedia?.video,
        highlightedInfo: !!tempLayout?.highlightedInfo && !!Object.keys(tempLayout?.highlightedInfo)?.length,
        sectionTitles: !!tempLayout?.sectionTitles && !!Object.keys(tempLayout?.sectionTitles)?.length,
        ctaBanner: true,
        customFile: !!tempLayout?.customFile && !!Object.keys(tempLayout?.customFile)?.length,
        toc: !tempLayout?.hideToc
      }

      setCourse(tempLayout)
    }
  }, [layoutStructure]);

  useEffect(() => {
    let needSave = false
    if (JSON.stringify(course?.teacher) !== JSON.stringify(layoutStructure.teacher) ||
      JSON.stringify(course?.infoPoints) !== JSON.stringify(layoutStructure.infoPoints) ||
      JSON.stringify(course?.infoIcons) !== JSON.stringify(layoutStructure.infoIcons) ||
      JSON.stringify(course?.highlightedInfo) !== JSON.stringify(layoutStructure.highlightedInfo) ||
      JSON.stringify(course?.coverMedia) !== JSON.stringify(layoutStructure?.coverMedia) ||
      JSON.stringify(course?.ctaBanner) !== JSON.stringify(layoutStructure?.ctaBanner) ||
      JSON.stringify(course?.sectionTitles) !== JSON.stringify(layoutStructure?.sectionTitles) ||
      JSON.stringify(course?.customFile) !== JSON.stringify(layoutStructure.customFile) ||
      JSON.stringify(course?.hideToc) !== JSON.stringify(layoutStructure?.hideToc)
      //this will not work since we dont have active modules in layout
      // JSON.stringify(course?.activeModules) !== JSON.stringify(layoutStructure?.activeModules)
    ) needSave = true
    setNeedToBeSaved(needSave)
  }, [layoutStructure, course])

  const removeMockedIds = useCallback((data) => {
    return data.map(el => {
      if (el.hasOwnProperty('id')) delete el.id;
      return el;
    })
  }, [])

  const saveStructure = useCallback(() => {
    const baseData = {
      id: layoutStructure.id,
      videoUrl: course?.coverMedia?.video?.url,
    };

    const layoutData = {
      courseCoverMedia: course?.activeModules?.coverMedia ? course?.coverMedia : null,
      infoPoints: course?.activeModules?.infoPoints ? removeMockedIds(cloneDeep(course?.infoPoints)) : null,
      infoIcons: course?.activeModules?.infoIcons ? removeMockedIds(cloneDeep(course?.infoIcons)) : null,
      teacher: course?.activeModules?.teacher ? course?.teacher : null,
      highlightedInfo: course?.activeModules?.highlightedInfo ? course?.highlightedInfo : null,
      ctaBanner: course?.activeModules?.ctaBanner ? course?.ctaBanner : null,
      hideToc: !course?.activeModules?.toc,
      customFile: !course?.activeModules?.customFile ? course.customFile : null,
      sectionTitles: course?.sectionTitles,
      // activeModules: null,
    };

    const newLayoutData = isPackage ? { ...baseData, layout: layoutData  } :
      { ...baseData, settings: { ...layoutStructure.dontDeleteSettings, layout: layoutData } };

    dispatch(saveCourse({ newData: newLayoutData, isPackage })).then(() => {
      if (myStore && slug) {
        dispatch(fetchCourse({ storeName: myStore, slugName: slug, type: isPackage ? 2 : 1 }));
      }
    });
  }, [dispatch, course, layoutStructure, removeMockedIds, myStore, slug, isPackage]);

  const addToPreviewCtaBanner = (info) => {
    setCourse({...course, ctaBanner: info})
    setOpenCtaBanner(false)
  }

  const checkIfHasData = (target, info) => {
    if(target === 'coverMedia') return !!info?.image ||  !!info?.video
    else return !!info && (!!info.length || !!Object.keys(info)?.length)
  }

  const addElementToPreview = (info, target, openModuleFnc) => {
    const hasData = checkIfHasData(target, info)
    setCourse({...course, [target]: info, activeModules: {...course.activeModules, [target]: hasData}})
    openModuleFnc && openModuleFnc(false)
  }

  const updatePreviewShowElement = (e, target, openModuleFnc) => {
    const checked = e.target.checked
    checked && !!openModuleFnc && !checkIfHasData(target, course[target]) && openModuleFnc(true)

    setCourse({...course, activeModules: {...course.activeModules, [target]: checked}})
  }

  const MenuItem = ({labelText, checked, onChange, openModule, disabled}) =>
    <ListItem component="div" disablePadding>
      <ListItemButton disableGutters >
        { !disabled ? <CheckboxButton labelText={labelText}
          checked={checked}
          handleChange={onChange}
        /> : <Typo.Text>{labelText}</Typo.Text>}
      </ListItemButton>
      {!!openModule && <SettingsButton onClick={openModule}/>}
    </ListItem>

  return (
    <Content direction='column' width='100%' >
      <Flex justify='space-between' width='100%' gap='30' >
        <Button $asText onClick={() => navigate(`/store/${myStore}/edit/courses`)} >
          {`< ${t('course.goBackToList')}`}
        </Button>
      </Flex>
      <Wrapper width='100%' align='flex-start' gap='20' justify='flex-start' >
        {/* SETTINGS MENU */}
        <div>
          <List dense subheader={<Typo.TextTitle>Activate modules</Typo.TextTitle>}>
            <MenuItem
              labelText='Cover Media'
              checked={course?.activeModules?.coverMedia}
              onChange={(e) => updatePreviewShowElement(e, 'coverMedia', setOpenCoverMedia)}
              openModule={() => setOpenCoverMedia(true)}
            />
            <MenuItem
              labelText='CTA Banner'
              checked={true}//{course?.activeModules?.ctaBanner}
              disabled={true}
              onChange={(e) => updatePreviewShowElement(e, 'ctaBanner', setOpenCtaBanner)}
              openModule={() => setOpenCtaBanner(true)}
            />
            <MenuItem
              labelText='Main Description'
              checked={course?.activeModules?.highlightedInfo}
              onChange={(e) => updatePreviewShowElement(e, 'highlightedInfo', setOpenMainDescription)}
              openModule={() => setOpenMainDescription(true)}
            />
            <MenuItem
              labelText='Description points'
              checked={course?.activeModules?.infoIcons}
              onChange={(e) => updatePreviewShowElement(e, 'infoIcons', setOpenInfoIcons)}
              openModule={() => setOpenInfoIcons(true)}
            />
            <MenuItem
              labelText='Downloadable file'
              checked={course?.activeModules?.customFile}
              onChange={(e) => updatePreviewShowElement(e, 'customFile', setOpenCustomFile)}
              openModule={() => setOpenCustomFile(true)}
            />
           { !isPackage && <MenuItem
              labelText='Course teacher/coach'
              checked={course?.activeModules?.teacher}
              onChange={(e) => updatePreviewShowElement(e, 'teacher', setOpenTeacher)}
              openModule={() => setOpenTeacher(true)}
            />   }
            <MenuItem
              labelText='Information points with icons'
              checked={course?.activeModules?.infoPoints}
              onChange={(e) => updatePreviewShowElement(e, 'infoPoints', setOpenInfoPoints)}
              openModule={() => setOpenInfoPoints(true)}
            />

            {/* does not have settings */}
            { !isPackage &&
            <MenuItem
              labelText='Show Course content'
              checked={course?.activeModules?.toc}
              onChange={(e) => updatePreviewShowElement(e, 'toc')}
            /> }

            <MenuItem
              labelText={'Edit section titles'}
              openModule={()=> setOpenEditTitles(true)}
              disabled={true}
            />

          </List>
          <Button icon='Save' onClick={saveStructure} disabled={!needToBeSaved} >{t('globals.save')}</Button>

        </div>

        {/* PREVIEW */}
        <PreviewContainer>
          <ShopCourseLayout
            course={course}
            {...course}
            isPackage={isPackage}
          />
        </PreviewContainer>

      </Wrapper>

      {/* EDIT MODULES*/}
      <CoverMediaLayout
        open={openCoverMedia}
        info={course?.coverMedia}
        onClose={() => {
          addElementToPreview(course.coverMedia, 'coverMedia')
          setOpenCoverMedia(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'coverMedia', setOpenCoverMedia)}
      />
      <EditCtaBanner
        open={openCtaBanner}
        info={course?.ctaBanner}
        onClose={() => {
          addElementToPreview(course.ctaBanner, 'ctaBanner')
          setOpenCtaBanner(false)
        }}
        addToPreview={addToPreviewCtaBanner}
      />
      <EditMainDescription
        open={openMainDescription}
        info={course?.highlightedInfo}
        onClose={() => {
          addElementToPreview(course.highlightedInfo, 'highlightedInfo')
          setOpenMainDescription(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'highlightedInfo', setOpenMainDescription)}
      />
      <CustomFile
        open={openCustomFile}
        info={course?.customFile}
        onClose={() => {
          addElementToPreview(course.customFile, 'customFile')
          setOpenCustomFile(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'customFile', setOpenCustomFile)}
      />
      <BuildInfoIcons
        open={openInfoIcons}
        info={course?.infoIcons}
        onClose={() => {
          addElementToPreview(course.infoIcons, 'infoIcons')
          setOpenInfoIcons(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'infoIcons', setOpenInfoIcons)}
        isMini={true}
      />
      <EditTeacher
        open={openTeacher}
        info={course?.teacher}
        onClose={() => {
          addElementToPreview(course.teacher, 'teacher')
          setOpenTeacher(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'teacher', setOpenTeacher)}
      />
      <BuildInfoIcons
        open={openInfoPoints}
        info={course?.infoPoints}
        onClose={() => {
          addElementToPreview(course.infoPoints, 'infoPoints')
          setOpenInfoPoints(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'infoPoints', setOpenInfoPoints)}
      />
      <EditSectionTitles
        context='course'
        isPackage={isPackage}
        open={openEditTitles}
        info={course?.sectionTitles}
        onClose={() => {
          addElementToPreview(course.sectionTitles, 'sectionTitles')
          setOpenEditTitles(false)
        }}
        addToPreview={(info) => addElementToPreview(info, 'sectionTitles', setOpenEditTitles)}
      />
    </Content >
  );
};

export default EditShopCourseLayout;
