import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components'
import theme from './ui/theme';
import { IconAsButton } from './ui';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n} >
        <SnackbarProvider
          maxSnack={5}
          dense
          hideIconVariant
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          action={(snackbarId) => (
            <IconAsButton size='small' color='white' iconName='Close' clickHandle={() => closeSnackbar(snackbarId)} />
          )}
        >
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
