import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components'
import { Accordion, AccordionDetails } from '@mui/material';
import { Flex, theme, Typo } from '../../../../../ui';
import { breakpoints, media } from '../../../../../utils/media';
import ShopCourseSubject from './ShopCourseSubject';

const CourseContentSubjectWrapper = styled.div`
  min-width: 300px;
  max-width: 500px;

  ${media.lessThan('phablet')} {
    margin: 0 auto;
  }
`;

const CourseContentCurrentWrapper = styled.div`
  min-height: 500px;
  background-color: ${(props) => props.theme.colors.NEUTRAL_0};
  padding: 40px 44px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  // flex-grow: 1;
  width:500px;
  border-radius: 20px;
  ${media.lessThan('phablet')} {
    margin: 0 auto;
    width:100%;
  }
`;

const CourseContentDetails = styled(AccordionDetails)`
  padding: 16px;
`;

const CourseContentDetailsWrapper = styled.div`
  border-radius: 12px;
  background-color:white;
  margin-top:4px;
`;

const CourseContentAccordion = styled(Accordion)`
  background-color: transparent !important;
  margin: 0 !important;
  box-shadow: none !important;

  &:before {
    display: none;
  }

  &:first-of-type {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  &:last-of-type {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
`;

const ShopCourseToc = ({ tocData }) => {
  const [currentSubject, setCurrentSubject] = useState({ index: 0, item: {} });

  const handleActiveSubject = useCallback((idx, item) => {
    setCurrentSubject({ index: idx, item });
  }, [setCurrentSubject]);

  useEffect(() => {
    const selectedItem = tocData.length > 1 ? 1 : 0
    setCurrentSubject({ index: selectedItem, item: tocData[selectedItem] })
  }, [tocData])


  const isMobile = window.innerWidth <= breakpoints.phablet;

  if(!Object.keys(tocData)?.length || !tocData?.length) return null

  return (
    <Flex width={'100%'} align='flex-start' justify='center' gap={40}>
      <CourseContentSubjectWrapper>
        {tocData?.map((item, i) => {
          return (
            <CourseContentAccordion key={i} expanded={currentSubject.index === i}>
              <ShopCourseSubject
                handleClick={() => handleActiveSubject(i, item)}
                isActive={i === currentSubject.index ? 'active' : ''}
                number={i + 1}
                name={item.name}
                isMobile={isMobile}
                themeColor={theme.colors.NEUTRAL_20}
              />
              { isMobile && <CourseContentDetailsWrapper>
                {item.pages.map((item, i) => {
                  return (
                    <CourseContentDetails key={i}>
                      <Typo.Text >{item.name}</Typo.Text>
                    </CourseContentDetails>
                  )
                })}
              </CourseContentDetailsWrapper>
              }
            </CourseContentAccordion>
          );
        })}
      </CourseContentSubjectWrapper>

      {!isMobile &&
        <CourseContentCurrentWrapper >
          {currentSubject?.item?.pages?.map((item, i) => <Typo.MediumText key={i} fontWeight={400}>{item.name}</Typo.MediumText>)}
        </CourseContentCurrentWrapper>
      }
    </Flex>
  );
};

export default ShopCourseToc;
