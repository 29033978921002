import Reflux from 'reflux';

const PageLayoutActions = Reflux.createActions([
  'enable',
  'disable',
  'toggle',
  'closeModuleMenu',
  'openModuleMenu',
  'toggleModuleMenu',
  'overRow',
  'resetDragging',
  'setDraggingPos',
  'endDragging',
  'addPageModule',
  'changePageModuleData',
  'savePageModule',
  'selectPageModule',
  'uploadComplete',
  'setPageModuleFile',
  'savePageModuleCaption'
]);

export default PageLayoutActions;
