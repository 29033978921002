import React from 'react';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Typo, Flex, Icon } from '../../ui';

const StyledAccordion = styled(Accordion)`
  width:100%;
`

const HowToUpdateSettings = ({ expanded, handleChange }) => {

  // const { t } = useTranslation();

  return (
    <>
      <StyledAccordion expanded={expanded === 'howToUpdateSettings1'} onChange={handleChange('howToUpdateSettings1')}>
        <AccordionSummary expandIcon={<Icon.ExpandMore />}>
          <Typo.TextTitle>How to update store settings</Typo.TextTitle>
        </AccordionSummary>
        <AccordionDetails>
          <Flex direction='column' gap='20'>

            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <Typo.Text>Step 1 - Navigate to settings in menu</Typo.Text>
              <Typo.Text>Step 2 - Edit your settings</Typo.Text>
            </Flex>
            <Flex direction='column' gap='10' align='flex-start' width='100%'>
              <div>
                <Typo.TextTitle>Branding</Typo.TextTitle>
                <Typo.Text>Select your theme color from our themes or select your own brand color. This will personalize your store and courses, making it look professional and attractive.</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Store</Typo.TextTitle>
                <Typo.Text>Choose your store's currency</Typo.Text>
                <Typo.Text>choose to present store values with or without VAT (not available yet)</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Terms & conditions</Typo.TextTitle>
                <Typo.Text>Personalize your Terms & Conditions. These will be shown in your store.</Typo.Text>
              </div>
              <div>
                <Typo.TextTitle>Features</Typo.TextTitle>
                <Typo.Text>Manage which features should be available for you. Select or unselect each feature to be able to include them in your courses or store</Typo.Text>
              </div>
            </Flex>
          </Flex>
        </AccordionDetails>
      </StyledAccordion>

    </>
  );
};

export default HowToUpdateSettings;
