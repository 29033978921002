import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { STATUS_SUCCEEDED } from '../../utils/constants';
import { fetchStore } from './globalSlice'
import { createCoursePackage, deleteCoursePackage, saveCourse } from './courseSlice';

// ----------------- Thunks -----------------------------




// ----------------- Reducers ---------------------------

const coursesAdapter = createEntityAdapter();

const initialState = {
  status: 'idle',
  ids: [],
  entities: {},
}

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStore.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        coursesAdapter.setAll(state, action.payload.items)
      })
      .addCase(createCoursePackage.fulfilled, (state, action) => {
        coursesAdapter.addOne(state, action.payload)
      })
      .addCase(deleteCoursePackage.fulfilled, (state, action) => {
        coursesAdapter.removeOne(state, action.meta.arg)
      })
      .addCase(saveCourse.fulfilled, (state, action) => {
        coursesAdapter.upsertOne(state, action.payload)
      })
  }
})

export const { reset } = coursesSlice.actions

export default coursesSlice.reducer

// ----------------- Selectors --------------------------
export const {
  selectAll: selectCourses,
  selectById: selectCourseById,
} = coursesAdapter.getSelectors((state) => state.webshop.courses)

export const selectAllCourseIds = (state) => state.webshop.ids;
export const selectStatus = state => state.webshop.status;


export const selectWebshopCourses = createSelector(
  [selectCourses],
  (allCourses) => allCourses.filter(course => !!course.public)
)

const selectSlug = (state, slug) => slug
export const selectCourseNameBySlug = createSelector(
  [selectCourses, selectSlug],
  (allCourses, slug) => {
    return allCourses.find((course) => course.slug === slug)
  }
)

const selectHighlightedCourses = (state) => state.webshop.home.highlightedCourses;
export const selectHighlightedCoursesInfo = createSelector(
  [selectCourses, selectHighlightedCourses],
  (allCourses, selectedCoursesIds) =>
    allCourses.filter(course => selectedCoursesIds.includes(course.id))
)

export const selectItemsByArray = (state, arrayIds) =>
  arrayIds?.map(id => selectCourseById(state, id))
