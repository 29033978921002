import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { statisticsApi, userApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const salesExport = createAsyncThunk('user/export', async ({ customStoreId, startDate, endDate }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { userId: customStoreId, dateFrom: startDate, dateTo: endDate },
    method: 'getCharges'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);
  return res.data;
});

export const sendExport = createAsyncThunk('statistics/export', async ({ exportData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: exportData,
    method: 'requestExport'
  };

  const res = await httpClient.post(statisticsApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Export was successfully sent'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return message;
});

export const userExport = createAsyncThunk('user/export', async ({ exportData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: exportData,
    method: 'export'
  };

  const res = await httpClient.post(userApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Export was successfully sent'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});


// ----------------- Reducers -----------------------------

const initialState = {
  status: 'idle',
  exportMsg: '',
}

const exportsSlice = createSlice({
  name: 'exports',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(sendExport.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(sendExport.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(sendExport.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.exportMsg = action.payload;
      })
      .addCase(userExport.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
      })
  }
})

export default exportsSlice.reducer

// ----------------- Selectors -----------------------------

export const selectExportMsg = state => state.exports.exportMsg;

