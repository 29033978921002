import Reflux from 'reflux';

export default Reflux.createActions([
  'paymentStarted',
  'paymentCompleted',
  'change',
  'genericError',
  'start',
  'cmoplete'
]);
