import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsIncarnated } from '../../auth/authSlice';
import { useTranslation } from 'react-i18next';
import { selectUsers, selectStatus, removeUserFromStore, fetchTeachers } from '../usersSlice';
import { ListTable, SubPage, Button, IconAsButton } from '../../../ui';
import { STATUS_LOADING } from '../../../utils/constants';
import Loading from '../../../ui/Loading';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import InviteTeacher from '../../invites/components/InviteTeacher';
import UserModal from './UserModal';
import PageHeader from '../../../ui/Page/PageHeader';
import PageControls from '../../../ui/Page/PageControls';

const TeachersList = ({ modal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const users = useSelector(selectUsers);
  const isIncarnated = useSelector(selectIsIncarnated);
  const loadStatus = useSelector(selectStatus);

  const [localUsers, setLocalUsers] = useState('');
  const [userToDelete, setUserToDelete] = useState(null);
  const [isModalOpen, setIsModelOpen] = useState(false);

  const openUser = useCallback((obj) => {
    navigate(`/users/teachers/${obj.id}`)
  }, [navigate]);

  useEffect(() => {
    setLocalUsers(users);
  }, [users, isIncarnated])

  const handleSearch = useCallback((value) => {
    setLocalUsers(users.filter((el) => el.name.toLowerCase().includes(value)))
  }, [users, setLocalUsers]);

  const clearSearch = useCallback(() => {
    setLocalUsers(users);
  }, [users, setLocalUsers]);

  useEffect(() => {
    dispatch(fetchTeachers());
  }, [dispatch, isIncarnated])

  const openDeleteConfirmation = (item) => {
    setUserToDelete(item)
  }
  const handleDeleteUser = () => {
    dispatch(removeUserFromStore({ userId: userToDelete.id }))
    setUserToDelete(null)
  }

  const tableInfo = [
    { label: t('globals.name'), data: (item) => item.name },
    { label: t('globals.email'), data: (item) => item.email },
    { label: t('globals.deleteUser'), data: (item) => <IconAsButton clickHandle={(e) => openDeleteConfirmation(item)} iconName='Delete' /> }
  ]

  const openInviteDialog = () => {
    setIsModelOpen(true)
  }

  return (
    <SubPage staticElements={
      <PageHeader>
        <PageControls
          onClearSearch={clearSearch}
          onSearch={handleSearch}
          placeholder={t('course.searchUser')}
          openModelButton={
            <Button icon='Invite' onClick={openInviteDialog}>{t('invites.addTeacher')}</Button>
          }
        />
      </PageHeader>
    }>
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <ListTable
          tableInfo={tableInfo}
          data={localUsers}
          onRowClick={openUser}
          tableName={'users'}
        />}
      <UserModal open={!!modal} isTeacher={true} />
      <ConfirmDelete
        onClose={() => setUserToDelete(null)}
        onAcceptDelete={handleDeleteUser}
        open={!!userToDelete}
        description={t('users.removeTotallyFromAccount', { userName: userToDelete?.name })}
      />
      <InviteTeacher isModalOpen={isModalOpen} closeModal={setIsModelOpen} />
    </SubPage>
  );
};

export default TeachersList;
