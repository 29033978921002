import Reflux from 'reflux';
import SteelCompass from '../services/SteelCompass';
import {
  StoreActions,
  AppActions,
  AuthActions,
  SettingsActions
} from '../actions';
import { history } from '../helpers';
import Config from '../Config';
import Google from '../services/Google';

var AuthStore = Reflux.createStore({
  listenables: [AuthActions, StoreActions, SettingsActions],
  data: {
    loading: false,
    loggedIn: false,
    incarnated: true,
    locale: 'en_US',
    role: {},
    roleMap: {},
    message: null
  },
  getInitialState() {
    return this.data;
  },
  readComplete(store) {
    if (!this.data.loggedIn) {
      this.data = {
        ...this.data,
        locale: (store && store.owner && store.owner.locale) || 'en_US'
      };
      this.trigger(this.data);
    }
  },
  setLocale(locale) {
    if (!this.data.loggedIn) {
      this.data = {
        ...this.data,
        locale
      };
      this.trigger(this.data);
    }
  },
  init() {
    if (localStorage) {
      var _user = localStorage.getItem('user');
      if (_user) {
        this.data = JSON.parse(_user);
        if (!this.data.roleMap) {
          this.data.roleMap = Object.assign({}, this.data.role);
        }
        this.data.locale = this.data.locale || 'en_US';
        this.trigger(this.data);
      }
    } else {
      this.data = { loggedIn: false, locale: 'en_US' };
      this.trigger(this.data);
    }
  },
  updateComplete(settings) {
    const locale = settings.language;
    if (this.data.locale !== locale) {
      this.data.locale = locale;
      this.trigger(this.data);
    }
    this.serialize();
  },
  loginRestored(data) {
    const user = { ...data.user } || {};
    user.loggedIn = true;
    this.data = user;
    this.data.incarnated = data.incarnated || false;
    this.data.features = user.features || {};
    this.data.roles = data.roles;
    this.data.role = data.roles.reduce((roleMap, item) => {
      roleMap[item] = true;
      return roleMap;
    }, {});
    this.data.roleMap = Object.assign({}, this.data.role);
    this.serialize();
    this.trigger(this.data);
    this.data.loading = false;
    SteelCompass.setAuthToken(data.sessionId);
    this.trigger(this.data);
  },
  loginSuccess(data) {
    AppActions.translatableMessage('login.success');
    this.loginRestored(data);
  },
  loginFailed() {
    AppActions.translatableMessage('login.failed');
    this.data = {
      ...this.data,
      loggedIn: false,
      loading: false,
      role: {},
      locale: 'en_US',
      user: null
    };
    // history.push('/login');
    this.trigger(this.data);
  },
  isReader() {
    return this.data.roleMap.reader;
  },
  isTester() {
    return this.data.roleMap.tester;
  },
  isTeacher() {
    return this.data.roleMap.teacher;
  },
  isEditor() {
    return this.data.roleMap.editor;
  },
  isAccountant() {
    return this.data.roleMap.account;
  },
  isAssistant() {
    return this.data.roleMap.assistant;
  },
  isOwner() {
    return this.data.roleMap.owner;
  },
  isDarkAdmin() {
    return this.data.roleMap['dark-admin'];
  },
  getRole() {
    return this.data.role;
  },
  onReaderMode() {
    this.data.role = {
      reader: true
    };
    this.trigger(this.data);
  },
  onBuilderMode() {
    this.data.role = this.data.roles.reduce((roleMap, item) => {
      roleMap[item] = true;
      return roleMap;
    }, {});
    this.trigger(this.data);
  },
  onLoginError(error) {
    this.data = {
      loggedIn: false,
      loading: false
    };
    AppActions.genericError(error);
    this.trigger(this.data);
  },
  onLogin(username, password) {
    this.data.loading = true;
    this.trigger(this.data);
    if (username && password) {
      SteelCompass.login(username, password).then(
        response => {
          if (response.user) {
            AuthActions.loginSuccess(response);
          } else {
            AuthActions.loginFailed();
          }
        },
        error => {
          this.data.loading = false;
          this.trigger(this.data);
          AppActions.genericError(error);
        }
      );
    } else {
      SteelCompass.request('auth', 'status').then(response => {
        if (response.user) {
          AuthActions.loginRestored(response);
        } else {
          AuthActions.loginFailed();
        }
      });
    }
  },
  // TODO: Remove after coursio 2.0 is shutdown
  tryHashLogin() {
    if (document.location.search.split('welcome%3D')[1]) {
      const c2hash = document.location.search.split('welcome%3D')[1];

      SteelCompass.loginCdk(c2hash).then(
        response => {
          if (response.user) {
            AuthActions.loginSuccess(response);
          } else {
            AuthActions.loginFailed();
          }
        },
        error => {
          AppActions.genericError(error);
        }
      );
    }
  },
  register(email, password, firstName, lastName, locale) {
    return SteelCompass.request('auth', 'register', {
      email,
      password,
      firstName,
      lastName,
      locale
    }).then(
      response => {
        AuthActions.loginSuccess(response);
      },
      error => {
        console.log('Error', error);
        AppActions.genericError(error);
      }
    );
  },
  logout() {
    this.data = {
      ...this.data,
      loggedIn: false,
      user: null
    };
    this.trigger(this.data);
    this.serialize();
    SteelCompass.logout().then(() => {
      SteelCompass.setAuthToken(null);
      AppActions.translatableMessage('login.loggedOut');
    });
    Google.signOut();
  },
  onIncarnate(userId, callback) {
    SteelCompass.call('auth', 'incarnate', { userId }, response => {
      this.loginSuccess({
        ...response.data,
        incarnated: true
      });
      if (callback) {
        callback();
      }
    });
  },
  onWakeUp(callback) {
    SteelCompass.call('auth', 'wakeUp', {}, response => {
      this.loginSuccess({
        ...response.data,
        incarnated: false
      });
      if (callback) {
        callback();
      }
    });
  },
  serialize() {
    try {
      const incarnated = JSON.parse(localStorage.getItem('user')).incarnated
      this.data.incarnated = incarnated
      localStorage.setItem('user', JSON.stringify(this.data));
      if (this.data.displayName) {
        localStorage.setItem('displayName', this.data.displayName);
      } else {
        localStorage.setItem('displayName', 'No Display Name Exists on User');
      }
    } catch (e) {
      console.error(e);
    }
  },
  requestNewPassword(email) {
    return SteelCompass.request('auth', 'requestNewPassword', {
      email,
      callbackUrl: document.location.protocol + Config.thisHost + '/restore-password/'
    }).then(
      response => {
        AuthActions.requestNewPasswordComplete(response);
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  requestNewPasswordComplete(response) {
    AppActions.translatableMessage('password.restoreLinkMailed');
  },
  restorePassword(key, password, passwordVerify) {
    return SteelCompass.request('auth', 'restorePassword', {
      key,
      password,
      passwordVerify
    }).then(
      response => {
        AuthActions.restorePasswordComplete();
      },
      error => {
        AppActions.genericError(error);
      }
    );
  },
  restorePasswordComplete() {
    AppActions.translatableMessage('password.changed');
    history.push('/login');
  },
  getUser() {
    return this.data;
  },
  isAuthenticated() {
    return this.data.loggedIn;
  },
  isIncarnated() {
    return this.data.incarnated;
  },
  getLocale() {
    return this.data.locale;
  },
  setFacebookLoginUrl(url) {
    this.data = {
      ...this.data,
      facebookLoginUrl: url
    };
    this.trigger(this.data);
  }
});

export default AuthStore;
