import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Typo, Button, Flex, Textinput, theme } from '../../ui';
import { registrationAndLogin, selectIsLoggedIn, selectUserName } from '../../features/auth/authSlice';
import { acceptInvitation, checkInvitation, selectRedeemInvitation } from '../../features/invites/invitesSlice';
import { useNavigate } from 'react-router';
import { setLoginModal } from '../../features/global/globalSlice';
import RegistrationForm from '../RegistrationForm';
import { useTranslation } from 'react-i18next';

const ButtonStyled = styled(Button)`
  align-self:flex-end; 
`

const GiftForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName)
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const inputRef = useRef();
  const [registerFormOpen, setRegisterFormOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const invitationFeedback = useSelector(selectRedeemInvitation)
  const { course, used, usedByMe, errMsg, giftCode } = invitationFeedback;

  const handleCheckGiftCode = () => {
    dispatch(checkInvitation({ giftCode: inputRef.current.value })).then(action => {
      const payload = action.payload
      const { expired, used, giftCode } = payload[0] || []
      const error = payload.errorCode ? payload.response.errMsg : null
      if (!isLoggedIn && !used && !expired && !error) setRegisterFormOpen(true)
      else if (isLoggedIn && !used && !expired && !error) handleAccept(giftCode)
      else if (used) setErrorMessage(t('gift.used'))
      else if (expired) setErrorMessage(t('gift.expired'))
      else if (error) setErrorMessage(error)
    })
  }

  const handleKeyPress = (event) => {
    const newCode = inputRef.current.value
    if (event.key === 'Enter' && newCode !== giftCode) {
      setErrorMessage(null)
      handleCheckGiftCode()
    }
  }

  const handleAccept = (code) => {
    if (code)
      dispatch(acceptInvitation({ giftCode: code })).then(action => {
        !action.payload.errorCode ? navigate(`/dashboard`) : setErrorMessage(action.payload.response.errMsg)
      })
    else setErrorMessage(t('globals.genericError'))
  }

  const handleLogin = () => {
    dispatch(setLoginModal(true))
  }


  return (
    <>
      <Typo.ContentSmallHeader>{t('gift.hasGiftCode')}</Typo.ContentSmallHeader>

      <Typo.Text>{t('gift.acceptSubText')}</Typo.Text>
      <Flex direction='column' width='100%' align='flex-start'>
        <Textinput inputRef={inputRef} onKeyPress={handleKeyPress} />
        {errorMessage && <Typo.Info color={theme.colors.ERROR_RED} fontWeight='400'>{errorMessage}</Typo.Info>}
      </Flex>
      {isLoggedIn &&
        <Flex direction='column' align='flex-start' gap='10'>
          <Typo.Text>{t('gift.loggedIn', { userName: userName })}</Typo.Text>
          {!used && <Typo.Text>{t('gift.courseAdded')}</Typo.Text>}
        </Flex>
      }
      {!registerFormOpen && (!course || errorMessage) && <ButtonStyled icon='Gift' onClick={handleCheckGiftCode}>{t('globals.gift')}</ButtonStyled>}
      {registerFormOpen && !isLoggedIn && !errMsg && <RegistrationForm handleLogin={handleLogin} createAccountCall={registrationAndLogin} />}
      {!isLoggedIn && !registerFormOpen &&
        <Flex direction='column'>
          <Typo.Info>{t('gift.needCoursioAccount')}</Typo.Info>
          <Typo.Info>{t('gift.coursioExplanation')}</Typo.Info>
        </Flex>
      }

      {isLoggedIn && usedByMe && <Typo.Text>{t('gift.courseLocation')}</Typo.Text>}
      {isLoggedIn && !used && course && <ButtonStyled onClick={() => handleAccept(giftCode)}>{t('gift.acceptCourse')}</ButtonStyled>}
    </>
  );
}

export default GiftForm;
