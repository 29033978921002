import React from 'react';
import { truncate } from '../../../helpers/stringHelpers';
import { Flex } from '../../../ui';
import DownloadableFile from '../../../ui/DownloadableFile';

const DownloadableAttach = ({ files }) => {
  // Convert files to an array if it's a single object, ignore if it's empty
  const fileList = Array.isArray(files) ? files : files && Object.keys(files).length > 0 ? [files] : [];

  return (
    !!fileList.length && (
      <Flex direction={'column'} align={'baseline'}>
        {fileList.map((file, index) => (
          <Flex align={'center'} gap={10} key={`${file.id}-${index}`}>
            <DownloadableFile.AsAttach label={truncate(file.name, 50)} file={file} />
          </Flex>
        ))}
      </Flex>
    )
  );
};

export default DownloadableAttach;
