import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Filter, Flex, Loading, Typo } from '../../../ui';
import { selectMediaTypes, selectStatus } from '../mediaSlice';
import { media } from '../../../utils/media';
import { STATUS_FAILED, STATUS_LOADING } from '../../../utils/constants';
import File from './File';
import { useTranslation } from 'react-i18next';

const FilesWrapper = styled.main`
  height: ${({ $elemHeight }) => `calc(100vh - ${$elemHeight}px - 160px)`}; 
`;

const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  margin-top: 25px;
  overflow: auto;

  ${media.lessThan('laptop')} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${media.lessThan('tablet')} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.lessThan('phablet')} {  
    grid-template-columns: repeat(2, 1fr);
  }

`;

const Scrollable = styled.div` 
  overflow-y: scroll;
  height:100%;
`

const FilesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:10px;
  }
`;

const FilesSection = ({ currentDirectory, data, selectedFilter, setSelectedFilter }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const ref = useRef(null)
  const mediaStatus = useSelector(selectStatus)
  const [height, setHeight] = useState(0)
  const mediaTypes = useSelector(selectMediaTypes)

  useEffect(() => {
    setHeight(ref?.current?.clientHeight)
  }, [])

  const goToFile = useCallback((obj) => {
    navigate(`/media-library/${currentDirectory}/${obj.id}`);
  }, [currentDirectory, navigate]);

  const handleMediaTypes = useCallback((value) => {
    setSelectedFilter(value)
  }, [setSelectedFilter])

  return (
    <FilesWrapper $elemHeight={height}>
      <FilesHeader ref={ref}>
        <Flex>
          <Typo.ContentSmallHeader>{t('mediaLibrary.media')}</Typo.ContentSmallHeader>
          {/* <Count number={data?.length} /> */}
        </Flex>
        <Filter info={mediaTypes} handleFilter={handleMediaTypes} selected={selectedFilter} />
      </FilesHeader>
      <Scrollable>
        {mediaStatus === STATUS_LOADING ? <Loading /> : mediaStatus === STATUS_FAILED ?
          <Typo.Text $textAlign='center' >{t('globals.genericError')}</Typo.Text> :
          !!data?.length ?
            <FilesGrid>
              {data?.map(file => <File file={file} goToFile={goToFile} key={file.id} />)}
            </FilesGrid >
            : <Typo.Text $textAlign='center' >{
              selectedFilter !== 'all' ? t('mediaLibrary.noFilesOfType', { selectedFilter })
                : t('mediaLibrary.noFilesUploaded')
            }
            </Typo.Text>}
      </Scrollable>
    </FilesWrapper>
  )
}

export default FilesSection;
