import React from 'react';
import { useSelector } from 'react-redux';
import { selectStoreOwner, selectUserTheme } from '../webshop/globalSlice';

import { Footer } from '../../ui'

const FooterContainer = () => {

  const date = new Date();
  const owner = useSelector(selectStoreOwner)
  const userTheme = useSelector(selectUserTheme)

  return (
    <Footer themeColor={userTheme.color} ownerName={owner?.name} currentYear={date.getFullYear()} />
  );
};

export default FooterContainer;
