import Reflux from 'reflux';

const QuizActions = Reflux.createActions([
  'initialize',
  'setNextStep',
  'next',
  'prev',
  'setQuestion',
  'calculateTest',
  'updateAnswer',
  'reset'
]);

export default QuizActions;
