import React, { useCallback, useEffect } from 'react';

const KlarnaCheckout = ({ htmlSnippet }) => {

  const renderCheckoutSnippet = useCallback(() => {
    const checkoutContainer = document.getElementById('my-checkout-container');
    checkoutContainer.innerHTML = htmlSnippet ? htmlSnippet : '';
    const scriptsTags = checkoutContainer.getElementsByTagName('script');
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode;
      const newScriptTag = document.createElement('script');
      newScriptTag.type = 'text/javascript'
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    }
  }, [htmlSnippet]);

  useEffect(() => {
    renderCheckoutSnippet();
  }, [renderCheckoutSnippet])

  return <div id="my-checkout-container" style={{ marginTop: 20 }} />
};

export default KlarnaCheckout;
