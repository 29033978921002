import styled from 'styled-components'
import theme from '../theme';
import { getUserThemeContrastColor } from '../theme/colors';

const Typo = {

  //HEADING FONTS
  PageHeader: styled.h1`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.HEADING_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: 600;
    line-height: 40px;
    font-size: ${theme.fontSizes.h1};
    text-align: ${(props) => props.$textAlign || 'center'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};

  `,
  SectionHeader: styled.h2`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.HEADING_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: 600;
    line-height: 30px;
    font-size: ${theme.fontSizes.h2};
    text-align: ${(props) => props.$textAlign || 'center'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};

  `,
  ContentBigHeader: styled.h3` //to be used wih only one item
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.HEADING_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: 600;
    line-height: 30px;
    font-size: ${theme.fontSizes.h3};
    text-align: ${(props) => props.$textAlign || 'center'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};

  `,
  ContentSmallHeader: styled.h4` //to be used wih multiple items
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.HEADING_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: 600;
    line-height: 30px;
    font-size: ${theme.fontSizes.h4};
    text-align: ${(props) => props.$textAlign || 'center'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};

  `,
  TextTitle: styled.p`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.HEADING_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: 600;
    line-height: 24px;
    font-size: ${theme.fontSizes.medium};
    text-align: ${(props) => props.$textAlign || 'left'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};
    white-space: ${(props) => props.whiteSpace}
  `,

  //BODY FONTS
  BigText: styled.p`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: ${(props) => props.fontWeight || '300'};
    line-height: 34px;
    font-size: ${theme.fontSizes.h3};
    text-align: ${(props) => props.$textAlign || 'left'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};
    a, a:hover, a:visited{
      font-weight: 400; 
      cursor:pointer;
      color:${theme.colors.PRIMARY};
    }
  `,
  Text: styled.p`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: ${(props) => props.fontWeight || '300'};
    line-height: 28px;
    width:100%;
    font-size: ${theme.fontSizes.regular};
    text-align: ${(props) => props.$textAlign || 'left'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};
    white-space: pre-wrap;
    a, a:hover, a:visited{
      font-weight: 400; 
      cursor:pointer;
      color:${theme.colors.PRIMARY};
    }
  `,
  MediumText: styled.p`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor,)};
    font-weight: ${(props) => props.fontWeight || '300'};
    line-height: 22px;
    font-size: ${theme.fontSizes.medium};
    text-align: ${(props) => props.$textAlign || 'left'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};
    a, a:hover, a:visited{
      font-weight: 400; 
      cursor:pointer;
      color:${theme.colors.PRIMARY};
    }
  `,
  SmallText: styled.p`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: ${(props) => props.fontWeight || '300'};
    line-height: 22px;
    font-size: ${theme.fontSizes.small};
    text-align:${(props) => props.$textAlign || 'left'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};
    a, a:hover, a:visited{
      font-weight: 400; 
      cursor:pointer;
      color:${theme.colors.PRIMARY};
    }
  `,
  Info: styled.p`
    margin: 0 0 ${(props) => props.$marginBottom || '0'} 0;
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    font-weight: ${(props) => props.fontWeight || '400'};
    line-height: 18px;
    font-size: ${theme.fontSizes.xSmall};
    text-align: ${(props) => props.$textAlign || 'left'};
    text-transform: ${(props) => props.$caps ? 'uppercase' : 'none'};
    a, a:hover, a:visited{
      font-weight: 400; 
      cursor:pointer;
      color:${theme.colors.PRIMARY};
    }
  `,
  Wrapper: styled.div`
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${(props) => props.color || getUserThemeContrastColor(props.$themeColor)};
    text-align: ${(props) => props.$textAlign || 'left'};
  `

  // -------------------------
}

export default Typo;
