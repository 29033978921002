import React, { useEffect, useState } from 'react';
import Typo from '../Typo';
import RadioButton from '../RadioButton';
import RadioButtonGroup from '../RadioButtonGroup';

const Toggler = ({ onClick, leftText, rightText, label, checked }) => {

  const [selectedValue, setSelectedValue] = useState('a');

  useEffect(()=>{
    setSelectedValue(checked ? 'b' : 'a')

  },[checked])

  const handleChange = (event) =>{
    setSelectedValue(event.target.value);
    onClick(event.target.value === 'b' ? true : false)
  }

  return (
    <>
      {label && <Typo.Text>{label}</Typo.Text>}
      <RadioButtonGroup handleChange={handleChange} selectedValue={selectedValue} orientation='row'>
        <RadioButton value='a' labelText={leftText} />
        <RadioButton value='b' labelText={rightText} />
      </RadioButtonGroup>
    </>
  )
}

export default Toggler;
