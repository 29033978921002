import Reflux from 'reflux';

const StoreActions = Reflux.createActions([
  'read',
  'readComplete',
  'create',
  'createComplete',
  'findById',
  'findBySlug',
  'findByIdComplete',
  'remove',
  'removeComplete',
  'genericError',
  'save',
  'saveComplete',
  'changeStoreItem',
  'pushStoreItem',
  'setDiscountCode',
  'joinForFree'
]);

export default StoreActions;
