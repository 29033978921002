import React from 'react';
import styled from 'styled-components';
import { Typo } from '../../../ui';
import theme from '../../../ui/theme';

import FileThumbnail from './FileThumbnail';
import { media } from '../../../utils/media';
import { useTranslation } from 'react-i18next';

export const ItemTypes = {
  All: 'all',
}

const Content = styled.div`
  background-color: ${props => props.theme.colors.NEUTRAL_LIGHT};
  position: relative;
  

  img {
    pointer-events: none;
  }
`;
const TextWrapper = styled.div`
  max-width:182px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  ${media.lessThan('laptop')} {
    max-width:130px;
  }
  
  ${media.lessThan('phablet')} {  
    max-width:120px;
  }
`;

const FileInfo = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 5px 15px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    position: fixed;
    right: 0;
    top:0;
  }

  ${media.lessThan('tablet')} {
    padding: 3px;
  }
`;

const SimpleFile = ({ file, ...props }) => {

  const { t } = useTranslation();


  const fileName = file.name.split(".");

  return (<Content key={file.id} {...props}>
    <FileThumbnail file={file} />
    <FileInfo>
      <TextWrapper>
        <Typo.Info color={theme.colors.NEUTRAL_LIGHT}>{fileName[0]}</Typo.Info>
        <Typo.Info color={theme.colors.NEUTRAL_LIGHT}>{t('globals.type')}: {fileName[fileName.length - 1]}</Typo.Info>
      </TextWrapper>
    </FileInfo>
  </Content>)
}

export default SimpleFile;
