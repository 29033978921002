import Reflux from 'reflux';

const TaskActions = Reflux.createActions([
  'start',
  'readTasks',
  'readTasksComplete',
  'findTaskStudents',
  'findTaskStudentsComplete',
  'findTaskGroups',
  'findTaskGroupsComplete',
  'removeUploaded',
  'findTaskCourses',
  'findTaskCoursesComplete',
  'clearTaskSelection',
  'setTaskFilter',
  'setGroupFilter',
  'setStudentFilter',
  'setCourseFilter',
  'assessTask',
  'assessTaskComplete',
  'toggleFilterMode',
  'toggleExpand',
  'toggleCorrected'
]);

export default TaskActions;
