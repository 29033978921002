import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { Typo, Flex, theme, Button, Symbols } from '../../../../ui';
import { setIsAGift } from '../../paymentsSlice';
import { useTranslation } from 'react-i18next';
import Buttons from '../../../../ui/Buttons';
import { selectUserTheme } from '../../globalSlice';

const Wrapper = styled(Flex)`
  margin-bottom: 1rem;   
  border-top: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  border-bottom: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  padding: 1rem 0;
`

const BuyAsGift = ({ isAGift, afterPayment }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userTheme = useSelector(selectUserTheme);

  const handleBuyAsGift = () => {
    dispatch(setIsAGift(!isAGift))
  }

  const imOfferingText = afterPayment ? t('webshop.checkout.imOfferingAfter') : t('webshop.checkout.imOffering')
  const emailMeCodeText = afterPayment ? t('webshop.checkout.emailMeCodeAfter') : t('webshop.checkout.emailMeCode')

  return (
    <Wrapper direction='column' align='left' onClick={handleBuyAsGift}>
      <Flex gap='10'>
        <Symbols.Gift size='medium' $withBgColor={theme.colors.PRIMARY_LIGHT} color={theme.colors.PRIMARY} />
        <div>
          <Typo.Text >
            {!isAGift ? t('webshop.checkout.iWantToOffer') : imOfferingText}
          </Typo.Text>
          {isAGift && <Typo.Info>{emailMeCodeText}</Typo.Info>}
        </div>
        {isAGift ?  
        <Buttons.Cancel />
        : <Button $themeColor={userTheme.color} $variant='themed'>Give away</Button>}
      </Flex>
    </Wrapper>

  );
};

export default BuyAsGift;
