import React, { useState } from 'react';
import { Flex, Button, theme, IconAsButton } from '../../../ui';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import { truncate } from '../../../helpers/stringHelpers';
import { useTranslation } from 'react-i18next';

const EditableAttach = ({ files, handleDelete }) => {
  const { t } = useTranslation();
  const [fileToDelete, setFileToDelete] = useState(false);

  const onFileClick = (url) => {
    window.open(url, '_blank noreferrer');
  }

  const acceptDelete = () => {
    handleDelete(fileToDelete);
    setFileToDelete(null);
  }

  // Normalize `files` to always be an array of non-empty objects
  const normalizedFiles = (Array.isArray(files) ? files : [files]).filter(
    file => file && typeof file === 'object' && Object.keys(file).length > 0
  );

  return (
    <Flex direction={'column'} align={'baseline'}>
      {!!normalizedFiles.length && normalizedFiles.map(file =>
        <Flex align={'center'} gap={10} key={file.id}>
          <Button $astext icon='AttachFile' onClick={() => onFileClick(file.url)}>
            {truncate(file.name, 50)}
          </Button>
          <IconAsButton
            iconName='Delete'
            placement="right"
            variant='action'
            color={theme.colors.ACTION}
            clickHandle={() => setFileToDelete(file)}
          />
        </Flex>
      )}
      {fileToDelete && <ConfirmDelete
        onClose={setFileToDelete}
        onAcceptDelete={acceptDelete}
        open={!!fileToDelete}
        description={t('tasks.removeConfirm', { fileToDelete: fileToDelete.name })}
      />}
    </Flex>
  );
};

export default EditableAttach;
