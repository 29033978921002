import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Flex, SubPage } from '../../../../ui';
import { selectCourse, selectCourseToc, selectCourseDurations, updateCourse } from '../../courseSlice';
import CourseCardEdit from '../../../../ui/CourseCard/CourseCardEdit';
import CourseInfoEdit from './CourseInfoEdit';
import CourseDripContentEdit from './CourseDripContentEdit';
import CourseDiplomaEdit from './CourseDiplomaEdit';
import { useTranslation } from 'react-i18next';

const CourseSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const course = useSelector(selectCourse)
  const courseToc = useSelector(selectCourseToc)
  const courseDurations = useSelector((state) => selectCourseDurations(state, true));

  const onSaveCourseInfo = useCallback((updatedCourse) => {
    const courseData = {
      ...updatedCourse,
      id: course.id
    };
    dispatch(updateCourse({ courseData }));
  }, [course, dispatch]);

  const saveImage = useCallback(image => {
    if (image && course) {
      const courseData = {
        id: course.id,
        coverImage: { id: image.id }
      };
      dispatch(updateCourse({ courseData }));
    }
  }, [dispatch, course])

  return (
    <SubPage align='left'>
      <Flex align='flex-start' gap={30} breakPoint={{ size: 'tablet', value: 'flex-direction: column; align-items:center' }}>
        <CourseCardEdit
          key={course.id}
          saveImage={saveImage}
          course={course}
          label={t('settings.addImage')}
        />
        <Flex direction='column' gap='50' align='flex-start' width='100%'>
          <CourseInfoEdit course={course} courseDurations={courseDurations} onSaveInfo={onSaveCourseInfo} />
          <CourseDiplomaEdit course={course} />
          <CourseDripContentEdit course={course} courseToc={courseToc} />
        </Flex>
      </Flex>
    </SubPage >
  );
};

export default CourseSettings;
