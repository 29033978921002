import React from 'react';
import styled from 'styled-components';
import { theme } from '..';
import { media } from '../../utils/media';

const Wrapper = styled.div`
  background-color: ${theme.colors.NEUTRAL_0};
  padding: 30px 50px 20px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  gap:30px;

  ${media.lessThan('phablet')} {
    padding: 30px;
  }
`

const PageBoxContent = ({ children }) => {

  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

export default PageBoxContent;
