import React, { useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import debounce from 'lodash.debounce';
import { setPaymentEmail, selectPaymentEmail} from '../../paymentsSlice';
import { setLoginModal } from '../../../../features/global/globalSlice';
import { Textinput, Typo, Flex, theme } from '../../../../ui';
import emailReg from '../../../../helpers/emailValidation';

const Wrapper = styled.div`
  padding-top:20px;
  width:100%;
`
const LoginWrapper = styled(Flex)`
  padding-bottom: 5px;
  span {
    font-weight: 400;
    cursor: pointer;
    color:${theme.colors.PRIMARY};
    text-decoration: underline;
  }
`;

const GuestCheckout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const paymentEmail = useSelector(selectPaymentEmail);
  const emailRef = useRef();

  const handleDebouncedEmail = useCallback(debounce(() => {
    const isValidEmail = emailRef.current && emailReg.test(emailRef.current.value)
    isValidEmail && dispatch(setPaymentEmail(emailRef.current.value))
  }, 1500)
    , [dispatch, emailRef]);

  const openLoginModal = useCallback(() => {
    dispatch(setLoginModal(true))
  }, [dispatch]);

  return (
    <Wrapper>
      <Typo.TextTitle>Check out as a guest</Typo.TextTitle>
      <Typo.SmallText>Faster checkout - add only your e-mail</Typo.SmallText>
      <Textinput
        defaultValue={paymentEmail}
        placeholder='E-mail'
        variant='outlined'
        innerRef={emailRef}
        onChange={handleDebouncedEmail}
      />
      <LoginWrapper justify='space-between'>
        <Typo.SmallText>{t('webshop.checkout.AlreadyHaveAccount')}<span onClick={openLoginModal}> {t('auth.login')}</span></Typo.SmallText>
      </LoginWrapper>
    </Wrapper>
  );
};

export default GuestCheckout;

