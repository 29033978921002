import Reflux from 'reflux';

const BundleActions = Reflux.createActions([
  'addTag',
  'addTagComplete',
  'removeTag',
  'removeTagComplete',
  'read',
  'readComplete',
  'create',
  'createComplete',
  'update',
  'updateComplete',
  'findById',
  'findByIdError',
  'findByIdComplete',
  'remove',
  'removeComplete',
  'removeError',
  'genericError'
]);

export default BundleActions;
