import Reflux from 'reflux';

const NotificationActions = Reflux.createActions([
  'read',
  'readComplete',
  'markRead',
  'countUnreadNotifications',
  'countUnreadNotificationsComplete'
]);

export default NotificationActions;
