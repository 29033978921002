import Reflux from 'reflux';

const QuizDetailActions = Reflux.createActions([
  'start',
  'readQuizzes',
  'findStudents',
  'findCourses',
  'findTaskStudents',
  'selectPage',
  'resetPage',
  'selectCourse',
  'selectUser',
  'export'
]);

export default QuizDetailActions;
