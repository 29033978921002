import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Loading, Page, PageBoxContent, PageContainerVCenter, PageHeaderLogo, theme, Typo } from '../../ui';
import { getGuestInfo, guestRegister, selectAuthState, selectGuestInfo, selectGuestInfoStatus } from '../auth/authSlice';
import { STATUS_LOADING } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import RegistrationForm from '../../components/RegistrationForm';

const ResumeRegistration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams();
  const authState = useSelector(selectAuthState);
  const guestInfo = useSelector(selectGuestInfo);
  const guestInfoStatus = useSelector(selectGuestInfoStatus);

  useEffect(() => {
    dispatch(getGuestInfo({ id }))
  }, [id, dispatch])

  useEffect(() => {
    if (authState.loggedIn) {
      const userInfo = authState.userInfo?.user
      if (userInfo.email === guestInfo?.email)
        navigate('/dashboard')
    }

  }, [authState, guestInfo, navigate])

  return (
    <Page backgroundColor={theme.colors.PRIMARY} fullheight={true}>
      <PageHeaderLogo />
      <PageContainerVCenter>
        <PageBoxContent>
          {guestInfoStatus === STATUS_LOADING ? <Loading /> :
            !guestInfo ?
              <Typo.TextTitle>{t('globals.genericError')}</Typo.TextTitle>
              :
              !authState.loggedIn ?
                <RegistrationForm email={guestInfo?.email} handleLogin={() => navigate('/login')} createAccountCall={guestRegister} />
                :
                <div>
                  <Typo.TextTitle>{t('registration.courseAddedToEmail')}: {guestInfo?.email}</Typo.TextTitle>
                  <Typo.Text>{t('registration.wrongAccount')}</Typo.Text>
                </div>
          }
        </PageBoxContent>
      </PageContainerVCenter>
    </Page>

  );
};

export default ResumeRegistration;
