import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Flex, Modal, Page, Textinput, theme, Typo, PageHeaderLogo, PageContainerVCenter, PageBoxContent } from '../../ui';
import { checkDiplomaInfo, selectDiploma } from './diplomaSlice';

const ButtonStyled = styled(Button)`
  align-self:flex-end; 
`

const ValidateDiploma = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [diplomaCode, setDiplomaCode] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const diplomaInfo = useSelector(selectDiploma)
  const { course, user } = diplomaInfo;

  // const testCode = '5ARZGC' 3Z5B24 

  const handleCheckDiplomaCode = () => {
    setErrorMessage(null)
    dispatch(checkDiplomaInfo({ code: inputRef.current.value })).then(action => {
      const payload = action.payload
      const error = payload.errorCode ? payload.response.errMsg : null
      setDiplomaCode(inputRef.current.value)
      if (error) setErrorMessage(error)
      else setIsOpen(true)
    })
  }

  const handleKeyPress = (event) => {
    const newCode = inputRef.current.value

    if (event.key === 'Enter' && newCode !== diplomaCode) {
      handleCheckDiplomaCode()
    }
  }

  const isNameSameAsEmail = user?.name === user?.email

  return (
    <Page noHeader backgroundColor={theme.colors.PRIMARY} fullheight={true}>
      <PageHeaderLogo />
      <PageContainerVCenter>
        <PageBoxContent>
          <Typo.ContentSmallHeader>{t('globals.diploma')}</Typo.ContentSmallHeader>
          <Flex direction='column' width='100%' align='flex-start'>
            <Textinput inputRef={inputRef} onKeyPress={handleKeyPress} />
            {errorMessage && <Typo.Info color={theme.colors.ERROR_RED} fontWeight='400'>{errorMessage}</Typo.Info>}
          </Flex>
          <ButtonStyled onClick={handleCheckDiplomaCode}>{t('diploma.labelSubmit')}</ButtonStyled>

        </PageBoxContent>
      </PageContainerVCenter >

      <Modal open={diplomaInfo.user && isOpen} onClose={() => setIsOpen(false)}>
        <Flex direction='column' gap='40'>
          <PageHeaderLogo lightBackground />
          <Flex direction='column' gap='20'>
            <Typo.BigText $textAlign='center'>{t('diploma.descritionPart1')}</Typo.BigText>
            <Typo.TextTitle $textAlign='center'>{`${user?.name} ${!isNameSameAsEmail ? '(' + user?.email + ')' : ''}`}</Typo.TextTitle>
            <Typo.BigText $textAlign='center'>{t('diploma.descritionPart2')}</Typo.BigText>
            <Typo.TextTitle $textAlign='center'>{course?.name}</Typo.TextTitle>
            <Typo.Text $textAlign='center'>{t('diploma.descritionPart3')}</Typo.Text>
            <Typo.TextTitle $textAlign='center'>{course?.lastDate}</Typo.TextTitle>
          </Flex>
          <Typo.Text $textAlign='center'>{diplomaCode}</Typo.Text>
        </Flex>
      </Modal>
    </Page>
  );
}

export default ValidateDiploma;
