import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


const AddItemWrapper = styled.div`
  width: 100%;
  min-height: 150px;
  border: 3px dashed rgba(0, 0, 0, .08);
  position: relative;
  overflow: hidden;
  color: rgba(0, 0, 0, .08);
  cursor: pointer;
  transition: all .25s linear;

  &:before {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: currentColor;
    line-height: 0;
  }

  &:after {
    content: '${({ $text }) => `${$text}`}';
    position: absolute;
    color: currentColor;
    font-size: ${(props) => props.theme.fontSizes.h4};
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(calc(-50% + 35px));
    transition: all .25s linear;
    opacity: 0.85;
  }

  &:hover {
    color: rgba(0, 0, 0, .25);
    border-color: rgba(0, 0, 0, .25);
    &:after {
      transform: translateY(calc(-50% + 25px));
      opacity: 1;
    }
  }
`;

const AddItem = ({ text, handleClick }) => {
  const { t } = useTranslation();

  return <AddItemWrapper onClick={handleClick} $text={`${t('globals.addNew')} ${text}`} />
};

export default AddItem;
