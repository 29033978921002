import React from 'react';
import styled from 'styled-components'
import Symbols from '../Symbols';
import theme from '../theme';

export const listOfIcons = [
  'BarChart',
  'Books',
  'BulletedList',
  'Calendar',
  'Claps',
  'Clock',
  'Check',
  'Diploma',
  'Download',
  'File',
  'Fire',
  'Flash',
  'Food',
  'Group',
  'Health',
  'Idea',
  'Image',
  'Kpi',
  'Money',
  'Nature',
  'Notepad',
  'Pen',
  'Professor',
  'Profile',
  'ProsCons',
  'Puzzle',
  'Questions',
  'Settings',
  'Online',
  'SpellCheck',
  'Star',
  'StepByStep',
  'Student',
  'Tree',
  'Video',
  'Certified',
  'Appartment',
  'Baking',
  'Bidding',
  'Chef',
  'Contract',
  'Cooking',
  'Curtains',
  'Gift',
  'House',
  'Sofa',
]

const sizes = { 'x-small': '18px', 'small': '22px', 'medium': '30px', 'large': '48px' }

// export const findSymbolUrl = (name) => {
//   const symbolObject = listOfIcons.find(element => element.name === name)
//   return symbolObject?.url
// }

const SelectedIcon = styled.div`
  width: ${({ size }) => sizes[size] || '70px'};
  height: ${({ size }) => sizes[size] || '70px'};
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoIcon = ({ iconName, size }) => {
  // const iconUrl = findSymbolUrl(iconName)
  const Component = Symbols[iconName];

  return (
    <SelectedIcon size={size}>
      {Component && <Component color={theme.colors.NEUTRAL_PRIMARY} size={size || 'large'} />}
    </SelectedIcon>
  );
};

export default InfoIcon;
