import React from 'react';
import styled from 'styled-components';
import { media } from '../../utils/media';

const Container = styled.div` 
  display:flex;
  align-items: center;
  justify-content: center; 
  flex-direction: column;

  margin: auto;
  
  ${media.lessThan('phablet')} {
    height: 100%;
    margin-top:30px; 
  }
`

const PageContainerVCenter = ({ children }) => {

  return (
    <Container>
      {children}
    </Container >
  );
}

export default PageContainerVCenter;
