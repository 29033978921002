import Reflux from 'reflux';

const MessageActions = Reflux.createActions([
  'clearMessage',
  'notify',
  'info',
  'error'
]);

export default MessageActions;
