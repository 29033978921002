import React from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from '@mui/material';

import { Icon, theme, Typo } from '../../../ui';
import { useTranslation } from 'react-i18next';

const Bredcrumb = styled.div`
  border-radius: 22px;
  padding: 5px 15px;
  min-width: 50px; 
  background-color: ${({ $lastItem }) => !$lastItem && theme.colors.NEUTRAL_LIGHT}; 
  cursor: ${({ $lastItem }) => !$lastItem && 'pointer'};
  &:hover{
    background-color: ${({ $lastItem }) => !$lastItem && theme.colors.NEUTRAL_20};
  }
`

const MediaBreadCrumbs = ({ onOpenFolder, breadcrumbs }) => {

  const { t } = useTranslation();


  return (
    <Breadcrumbs separator={<Icon.Next fontSize="small" />} aria-label="breadcrumb">
      <Bredcrumb onClick={onOpenFolder} $lastItem={breadcrumbs.length === 0}>
        <Typo.Text $textAlign='center'>{t('mediaLibrary.rootFolderName')}</Typo.Text>
      </Bredcrumb>
      {breadcrumbs?.map((folder, index) => {
        const lastItem = index === breadcrumbs.length - 1;
        return <Bredcrumb onClick={() => !lastItem ? onOpenFolder(folder) : null} key={folder.id} $lastItem={lastItem}>
          <Typo.Text $textAlign='center'>{folder.name}</Typo.Text>
        </Bredcrumb>
      }
      )}
    </Breadcrumbs>
  );
};

export default MediaBreadCrumbs;
