import React from 'react';
import styled from 'styled-components';
import { Button, Icon, theme } from "../../ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CancelContainer = styled.div`
  height: 100%;
  justify-content: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PaymentCancelled = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CancelContainer>
      <Icon.DoNotDisturb size={'big'} color={theme.colors.PRIMARY} />
      <div>{t('webshop.checkout.paymentCancelled')}</div>
      <Button $asText onClick={() => navigate('/courses')}>
        {`< ${t('webshop.checkout.backToDashboard')}`}
      </Button>
    </CancelContainer>
  );
};

export default PaymentCancelled;
