import React from 'react';
import styled from 'styled-components';
import GiftForm from '../../../components/ReclaimGift/GiftForm';

const Container = styled.div` 
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
`

const Wrapper = styled.div`
  padding: 30px 50px 20px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  gap:30px;
`
const ProfileGiftCode = () => {
  return (
    <Container>
      <Wrapper>
        <GiftForm />
      </Wrapper>
    </Container >
  );
}

export default ProfileGiftCode;
