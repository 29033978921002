import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Tab, Tabs } from '@mui/material';
import { media } from '../../utils/media';
import { theme } from '..';

const TabWrapper = styled.div`
  margin:1rem 0;
  width: 100%;
  ${media.lessThan('phablet')} {
    margin: 0;
  }
  .MuiTabs-indicator {
    background-color:transparent !important;
  }
`
const TabStyled = styled(Tab)`
  gap:5px;
  font-family:${theme.fonts.HEADING_FONT}, sans-serif !important;
  font-weight: 600 !important;
  font-size: ${(props) => props.theme.fontSizes.medium} !important;
  &:hover {
    background-color:${theme.colors.PRIMARY_LIGHT} !important;
  }
  &.Mui-selected {
    color:${theme.colors.NEUTRAL_0} !important;
    background-color:${theme.colors.PRIMARY} !important;
  }
`

const SubMenu = ({ navigationItems, pathnameSplitIndex }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [selectedTab, setSelectedTab] = useState(0);


  useEffect(() => {
    const pathnameSplit = pathname.split('/')
    const pathModuleTab = pathnameSplit[pathnameSplitIndex]

    let matchIndex = 0;

    if (!pathModuleTab) {
      navigate(navigationItems[matchIndex]['value'], { replace: true })
    } else {
      matchIndex = navigationItems.findIndex((item) =>
        item['value'] === pathModuleTab
      )
    }
    setSelectedTab(matchIndex)

  }, [navigate, navigationItems, pathname, pathnameSplitIndex])

  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const onTabClick = useCallback((event) => {
    event.preventDefault();
    navigate(event.target.getAttribute("aria-label"))
  }, [navigate])

  return (
    <TabWrapper>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={'auto'}
        allowScrollButtonsMobile
      >
        {navigationItems.map((item, index) => {
          return <TabStyled 
            component='a' 
            key={index} 
            onClick={onTabClick} 
            label={item.label} 
            aria-label={item['value']} 
            icon={item.icon}
            iconPosition="end"
          />
        })}
      </Tabs>
    </TabWrapper>
  );
};

export default SubMenu;
