import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsIncarnated } from '../../auth/authSlice';
import { useTranslation } from 'react-i18next';
import { fetchReaders, selectUsers, selectStatus, removeUserFromStore } from '../usersSlice';
import { setInviteModalIsOpen } from '../../invites/invitesSlice';
import UserModal from '../components/UserModal';
import { ListTable, SubPage, Button } from '../../../ui';
import { STATUS_LOADING } from '../../../utils/constants';
import Loading from '../../../ui/Loading';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import PageHeader from '../../../ui/Page/PageHeader';
import PageControls from '../../../ui/Page/PageControls';

const UsersList = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const users = useSelector(selectUsers);
  const isIncarnated = useSelector(selectIsIncarnated);
  const loadStatus = useSelector(selectStatus);

  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortMode, setSortMode] = useState('asc');
  const [userToDelete, setUserToDelete] = useState(null);
  // const [length, setLength] = useState(25)
  const length = 1000
  const navigate = useNavigate();

  const openUser = useCallback((obj) => {
    navigate(`/users/all/${obj.id}`)
  }, [navigate]);

  const handleSearch = useCallback((value) => {
    setSearchQuery(value)
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const handleSort = useCallback((val) => {
    setSortBy(val);
    if (sortBy === val) {
      setSortMode('desc')
    }
    if (sortMode === 'desc') {
      setSortMode('asc')
    }
  }, [setSortBy, sortBy, setSortMode, sortMode]);

  //Add 25 more items to api call
  // const loadMore = useCallback(() => {
  //   setLength((prevLength) => prevLength + 25)
  // }, [setLength]);

  useEffect(() => {
    dispatch(fetchReaders({ pageLength: length, searchText: searchQuery, sortBy: sortBy, sortMode: sortMode }));
  }, [dispatch, isIncarnated, searchQuery, sortBy, sortMode, length])

  const openDeleteConfirmation = (item) => {
    setUserToDelete(item)
  }
  const handleDeleteUser = () => {
    dispatch(removeUserFromStore({ userId: userToDelete.id }))
    setUserToDelete(null)
  }

  const tableInfo = [
    { label: t('globals.name'), handleClick: () => handleSort('name'), data: (item) => item.name },
    // { label: 'Role' },
    // { label: 'Last active' },
    { label: t('globals.email'), handleClick: () => handleSort('email'), data: (item) => item.email },
    { label: t('globals.groups'), data: (item) => item.circles?.length },
    { label: t('globals.courses'), data: (item) => item.courses?.length },
    {
      label: '', menuOptions: () => [
        { label: t('globals.userInfo'), onClick: openUser },
        { label: t('globals.deleteUser'), onClick: (item) => openDeleteConfirmation(item) },
      ]
    },
  ]

  const openInviteDialog = () => {
    dispatch(setInviteModalIsOpen(true))
  }

  return (
    <SubPage staticElements={
      <PageHeader>
        <PageControls
          onClearSearch={clearSearch}
          onSearch={handleSearch}
          placeholder={t('course.searchUser')}
          disableClear={!searchQuery}
          openModelButton={
            <Button icon='Invite' onClick={openInviteDialog}>{t('invites.inviteUser')}</Button>
          }
        />
      </PageHeader>
    }>
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <ListTable
          tableInfo={tableInfo}
          data={users}
          onRowClick={openUser}
          tableName={'users'}
        />}
      <UserModal open={!!modal} />
      <ConfirmDelete
        onClose={() => setUserToDelete(null)}
        onAcceptDelete={handleDeleteUser}
        open={!!userToDelete}
        description={t('users.removeTotallyFromAccount', { userName: userToDelete?.name })}
      />
    </SubPage>
  );
};

export default UsersList;
