import React from 'react';
import styled from 'styled-components'
import { Flex, theme, Typo, } from '..';
import { ReactComponent as CoursioLogotype } from '../assets/coursio-logotype.svg';
import coursioImg from '../assets/coursio-creen.png';

const CoursioLogo = styled(CoursioLogotype)`
  margin-bottom: ${(props) => props.marginbottom}
`

const CoursioImage = styled.img`
  max-width: 100px;
  margin-bottom: ${(props) => props.marginBottom}
`

const PageHeaderLogo = ({ message, marginBottom, lightBackground }) => {

  return (
    <Flex gap='30' direction='column'>
      {!lightBackground ? <CoursioLogo marginbottom={marginBottom} /> :
        <CoursioImage src={coursioImg} marginBottom={marginBottom} />}
      {message && <Typo.ContentSmallHeader $themeColor={!lightBackground && theme.colors.PRIMARY}>{message}</Typo.ContentSmallHeader>}
    </Flex>
  );
};

export default PageHeaderLogo;
