import React, { useState } from 'react';
import { Button } from '..';
import { Popover } from '@mui/material';

const Dropdown = ({ label, variant, bgcolor, iconName, position = 'top', children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button $variant={variant} $bgcolor={bgcolor} icon={iconName} onClick={handleClick}>
        {label}
      </Button>
      {anchorEl && ( // Conditionally render Popover only if anchorEl is not null
        <Popover
          id="simple-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: position,
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: position === 'top' ? 'bottom' : 'top',
            horizontal: 'left',
          }}
        >
          {children}
        </Popover>
      )}
    </div>
  );
};

export default Dropdown;
