import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typo, Flex, Textinput, Modal } from '../../../../ui';

const EditCtaBanner = ({ open, info, addToPreview, onClose}) => {
  const { t } = useTranslation();

  const [ctaBanner, setCtaBanner] = useState();

  useEffect(() => {
    setCtaBanner(info)
  }, [info])

  const editCtaBannerInfo = useCallback(e => {
    setCtaBanner({ ...ctaBanner, [e.target.dataset.propname]: e.target.value });
  }, [ctaBanner]);

  const onAccept = useCallback(() => {
    addToPreview(ctaBanner)
  }, [ctaBanner, addToPreview]);

  return (
    <Modal
      open={open} 
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel='Add to preview'
    >
      <Flex direction='column' width='100%' align='flex-start'>
        <Typo.ContentSmallHeader $marginBottom='10'>{t('webshop.build.ctaBannerTitle')}</Typo.ContentSmallHeader>
        <Typo.Text>{t('webshop.build.ctaBannerDescription')}</Typo.Text>
        <Textinput
          label={t('globals.title')}
          propName={'title'}
          defaultValue={ctaBanner?.title}
          onBlurValue={editCtaBannerInfo}
        />
        <Textinput
          label={t('globals.description')}
          propName={'description'}
          defaultValue={ctaBanner?.description}
          onBlurValue={editCtaBannerInfo}
          isMultiline
        />
      </Flex>
    </Modal>
  );
};

export default EditCtaBanner;
