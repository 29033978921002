import React from 'react';
import styled from 'styled-components';
import { theme } from '..';
import Icon from '../Icon';
import Textinput from '../Textinput';

const TextFieldWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  
  .MuiTextField-root {
    margin: 0;
    div { border-radius: 10px; }
    input { padding: 8px 48px; }
  }
`;

const IconCloseStyled = styled(Icon.Close)`
  position: absolute;
  top: 50%;
  right: 12px;
  cursor: pointer;
  transform: translateY(-50%);
`;

const SearchIcon = styled.span`
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  display: grid;
  place-items: center;
`;

const SearchBar = ({ handleClear, handleSearch, inputRef, placeholder }) => {
  return (
    <TextFieldWrapper>
      <SearchIcon>
        <Icon.Search color={theme.colors.NEUTRAL_60} />
      </SearchIcon>
      <Textinput
        placeholder={placeholder}
        variant='outlined'
        onChange={handleSearch}
        inputRef={inputRef}
      />
      <IconCloseStyled onClick={handleClear} size={'small'} color={theme.colors.NEUTRAL_60} />
    </TextFieldWrapper>
  );
};

SearchBar.displayName = 'SearchBar'

export default SearchBar;
