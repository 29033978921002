import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Button, Flex, Icon, IconAsButton, ListTable, Modal, SubPage, Textinput, theme, Typo } from '../../../ui';
import { fetchUsersByGroupId, removeUserFromGroup, selectGroupUsers } from '../../users/usersSlice';
import { setUserRoleInGroup } from '../../users/userSlice';
import { removeGroupFromCourse, selectGroupById, updateGroupName, fetchGroupById } from '../groupsSlice';
import { fetchCoursesByGroupId, selectGroupCourses } from '../../courses/coursesSlice';
import { getPeriodByTime } from '../../../utils/ucpPeriods';
import { setInviteModalIsOpen } from '../../invites/invitesSlice';
import Loading from '../../../ui/Loading';
import UserModal from '../../users/components/UserModal';

const ListWrapper = styled.div`
  margin: 20px 0;
  width: 100%;
`;

const Group = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const groupNameRef = useRef();

  const { groupId } = useParams();
  const [editNameView, setEditNameView] = useState(false)
  const [userToDelete, setUserToDelete] = useState(null)
  const [courseToDelete, setCourseToDelete] = useState(null)
  const group = useSelector((state) => selectGroupById(state, groupId));
  const users = useSelector(selectGroupUsers)
  const courses = useSelector(selectGroupCourses)

  const removeUser = () => {
    dispatch(removeUserFromGroup({ userId: userToDelete.id, circleId: groupId })).then(() => {
      setUserToDelete(null);
    })
  }

  const openUser = useCallback((obj) => {
    navigate(`/users/groups/${groupId}/${obj.id}`)
  }, [navigate, groupId]);

  const removeCourse = () => {
    dispatch(removeGroupFromCourse({ courseId: courseToDelete.id, circleId: +groupId })).then(() => {
      setCourseToDelete(null);
    })
  }

  const tableInfoUsers = [
    { label: t('globals.name'), data: (item) => item.name },
    { label: t('globals.email'), data: (item) => item.email },
    {
      label: t('globals.admin'),
      data: (item) =>
        item.role === 'admin' && <Icon.Admin color={theme.colors.NEUTRAL_60} />
    },
    {
      label: '', menuOptions: (item) => [
        { label: item.role !== 'admin' ? t('groups.giveAdminAccess') : t('groups.removeAdminAccess'), onClick: (item) => handleAdminAccess(item) },
        { label: t('course.removeUser'), onClick: () => setUserToDelete(item) },
        { label: t('globals.userInfo'), onClick: openUser },
      ]
    },
  ]

  const tableInfoCourses = [
    { label: t('globals.name'), data: (item) => item.name },
    { label: t('course.createdAt'), data: (item) => item.created && item.created.split(' ')[0] },
    { label: t('globals.ucpPeriod'), data: (item) => t(`course.${getPeriodByTime(item.ucp_period)?.label}`) },
    { label: t('globals.remove'), data: (item) => <IconAsButton iconName='Delete' color={theme.colors.NEUTRAL_60} clickHandle={(e) => setCourseToDelete(item)} />, },
  ]

  useEffect(() => {
    if (groupId) {
      dispatch(fetchGroupById({ id: groupId }))
      dispatch(fetchUsersByGroupId({ circleId: groupId }))
      dispatch(fetchCoursesByGroupId({ circleId: groupId }))
    }
  }, [dispatch, groupId]);

  const addUsersHandler = () => {
    dispatch(setInviteModalIsOpen({ group: group, select: 'users', callbackFunc: 'UsersByGroupId' }))
  }
  const addCoursesHandler = () => {
    dispatch(setInviteModalIsOpen({ group: group, select: 'courses', callbackFunc: 'CoursesByGroupId' }))
  }

  const saveGroupName = () => {
    if (groupNameRef.current.value !== group.name)
      dispatch(updateGroupName({ id: group.id, name: groupNameRef.current.value }))

    setEditNameView(false)
  }

  const handleAdminAccess = (user) => {
    dispatch(setUserRoleInGroup({ userId: user.id, circleId: groupId, role: user.role === 'admin' ? 'user' : 'admin' })).then(() => dispatch(fetchUsersByGroupId({ circleId: groupId })))
  }

  return (<>
    {!groupId ? null :
      <SubPage >
        {!group ? <Loading /> :
          <Flex gap='40' direction='column' align='flex-start' width='100%'>
            <Flex justify='flex-start' with='100%'>
              <Button $asText onClick={() => navigate('/users/groups')} >
                {`< ${t('groups.goBackToList')}`}
              </Button>
            </Flex>
            <Flex justify='center' gap='10' width='100%'>
              <Typo.Text>{t('groups.groupName')}:</Typo.Text>
              {!editNameView ? <>
                <Typo.ContentSmallHeader >{group?.name}</Typo.ContentSmallHeader>
                <IconAsButton iconName='Edit' size='small' clickHandle={() => setEditNameView(true)} />
              </> : <>
                <Textinput
                  placeholder={t('groups.groupName')}
                  innerRef={groupNameRef}
                  defaultValue={group?.name}
                  flatStyle
                  width='60%'
                />
                <IconAsButton iconName='Cancel' color={theme.colors.ERROR_RED} clickHandle={() => setEditNameView(false)} />
                <IconAsButton iconName='Valid' tooltipLabel={t('globals.save')} color={theme.colors.SUCCESS_GREEN} clickHandle={saveGroupName} />
              </>}
            </Flex>
            <ListWrapper>
              <Flex gap='10'>
                <Typo.ContentSmallHeader $textAlign='left'>{t('globals.users')}</Typo.ContentSmallHeader>
                {!!users.length && <IconAsButton tooltipLabel={t('globals.inviteUser')} iconName='Invite' clickHandle={addUsersHandler} />}
              </Flex>
              {!!users.length ? <ListTable
                tableInfo={tableInfoUsers}
                data={users}
                tableName={'groupUsersInfo'}
                onRowClick={openUser}
                defaultRowPerPage={5}
              /> : <Flex direction='column'>
                <Typo.Text>{t('groups.noUser')}</Typo.Text>
                <IconAsButton tooltipLabel={t('globals.inviteUser')} iconName='Invite' clickHandle={addUsersHandler} />
              </Flex>}
            </ListWrapper>
            <ListWrapper>
              <Flex gap='10'>
                <Typo.ContentSmallHeader $textAlign='left'>{t('globals.courses')}</Typo.ContentSmallHeader>
                {!!courses.length && <IconAsButton tooltipLabel={t('globals.addCourse')} iconName='AddCourse' clickHandle={addCoursesHandler} />}
              </Flex>
              {!!courses.length ? <ListTable
                tableInfo={tableInfoCourses}
                data={courses}
                tableName={'groupCoursesInfo'}
                defaultRowPerPage={5}
              /> : <Flex direction='column'>
                <Typo.Text>{t('groups.noCourse')}</Typo.Text>
                <IconAsButton tooltipLabel={t('globals.addCourse')} iconName='AddCourse' clickHandle={addCoursesHandler} />
              </Flex>}
            </ListWrapper>
          </Flex>
        }
        <UserModal open={!!modal} />
        <Modal
          open={!!userToDelete}
          title={t('groups.confirmRemoveUserTitle')}
          acceptLabel={t('globals.remove')}
          onClose={() => setUserToDelete(null)}
          onAccept={removeUser}
          showActions
        ><Typo.Text>{t('groups.confirmRemoveUserDescription')}</Typo.Text>
        </Modal>
        <Modal
          open={!!courseToDelete}
          title={t('groups.confirmRemoveCourseTitle')}
          onClose={() => setCourseToDelete(null)}
          acceptLabel={t('globals.remove')}
          onAccept={removeCourse}
          showActions
        ><Typo.Text>{t('groups.confirmRemoveCourseDescription')}</Typo.Text></Modal>
      </SubPage>}
  </>
  );
};

export default Group;
