import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';

import { Textinput, SearchSelect, Modal, Figure, Loading } from '../../../ui';
import CourseImageWrapper from '../../../ui/CourseCard/CourseImageWrapper';
import { selectCourses } from '../../webshop/coursesSlice';
import ImageUploader from '../../media/uploaders/ImageUploader';
import { createCoursePackage,fetchCourseById, saveCourse } from '../courseSlice';
import { selectCourseDurations } from '../../course/courseSlice';
import UcpPeriodSelector from '../../../components/UcpPeriodSelector';
import { getPeriodByTime } from '../../../utils/ucpPeriods';
import CoursePrice from './components/CoursePrice';
import EditPackageCourseSequence from './components/EditPackageCourseSequence';
import { priceFormater } from '../../../helpers/formatNumbers';
import { selectFeatures } from '../../settings/settingsSlice';


const EditShopCourseSettings = ({ open, courseId, tempCourse, isPackage, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseInfo, setCourseInfo] = useState(null);
  const [selectedCourseArray, setSelectedCourseArray] = useState([]);
  const [duration, setDuration] = useState({});
  const [price, setPrice] = useState({ net: 0, gross: 0, discounted: 0 });
  const [isDisabled, setIsDisabled] = useState(true);
  const [packageName, setPackageName] = useState('');
  const [hasCourseSequence, setHasCourseSequence] = useState(false);
  const [ucpPeriod, setUcpPeriod] = useState(false);
  const [newSequence, setNewSequence] = useState();

  const features = useSelector(selectFeatures);
  const courses = useSelector(selectCourses);
  const courseDurations = useSelector((state) => selectCourseDurations(state, true));

  const descriptionRef = useRef(null);

  const closeModal = () => {
    setCourseInfo(null);
    setSelectedCourseArray([]);
    setHasCourseSequence(false)
    setPrice({ net: 0, gross: 0, discounted: 0 })
    setDuration({})
    setIsDisabled(true)
    setPackageName('')
    setUcpPeriod(false)
    setNewSequence(null)
    setIsModalOpen(false);
    onClose();
  }

  useEffect(()=>{
    !!courseId && dispatch(fetchCourseById({courseId, isPackage})).then((res) => {
      const localCourse = res.payload
      const course = cloneDeep(tempCourse)

      const newPrice = !course.price ? { net: 0, gross: 0, discounted: 0 } : 
        !!course?.price && !!course.price?.hasOwnProperty('net') ? course?.price :
          priceFormater(course?.price)
      //list of courses does not have course id, so we use base of course from store read and add sequence
      course['sequence'] = localCourse.sequence
      course['hasSequence'] = localCourse.hasSequence
      setPrice(newPrice)
      setCourseInfo(course);
      setPackageName(course?.name);
      setIsModalOpen(!!open);
      setUcpPeriod(getPeriodByTime(course?.ucpPeriod))
      setHasCourseSequence(course?.hasSequence)
      setNewSequence(course?.sequence)
      course?.courses && setSelectedCourseArray(course.courses);
    })

    !courseId && open && setIsModalOpen(open);

  }, [courseId, dispatch, tempCourse, open, isPackage])

  const addToSequence = useCallback((courses)=>{
    const sequence = []
    courses.forEach(course => {
      sequence.push(course.id)
    })
    setNewSequence(sequence)

  }, [])

  //if user wants to set to have sequence after course is created
  useEffect(()=>{
    if(hasCourseSequence && !newSequence && courseInfo){
      const sequence = []
      selectedCourseArray.forEach(course => {
        const isIncluded = sequence.includes(course.id)
        if(!isIncluded) sequence.unshift(course.id)
      })
      setNewSequence(sequence)
    }
  }, [courseInfo, hasCourseSequence, selectedCourseArray, newSequence])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isPackage) {
      setIsDisabled(!(selectedCourseArray?.length > 1 && packageName?.length > 0))
    } else {
      setIsDisabled(!packageName?.length > 0)
    }
  }, [selectedCourseArray, packageName, courseId, isPackage])

  const changeName = (e) => setPackageName(e.target.value);
  
  const handleCourseSelectionArray = (value) => {
    setSelectedCourseArray(value)
    addToSequence(value)
  }

  const saveImage = useCallback(image => {
    if (image) setCourseInfo({ ...courseInfo, coverImage: image });
  }, [courseInfo])

  const handleCreatePackage = () => {
    let courseIdsList;
    let newData;
    if (isPackage) {
      courseIdsList = selectedCourseArray.map(course => course.id)
      newData = {
        id: courseId,
        fileId: courseInfo?.coverImage.id,
        name: packageName,
        description: descriptionRef.current && descriptionRef.current.value,
        courses: courseIdsList,
        ucpPeriod: duration.time ? duration.time : ucpPeriod.time,
        price: price?.hasOwnProperty('net') ? price.net : price,
        hasSequence: hasCourseSequence,
        sequence: hasCourseSequence ? newSequence : null
        
      }
    } else {
      newData = {
        id: courseId,
        coverImage: courseInfo?.coverImage,
        description: descriptionRef.current && descriptionRef.current.value,
        price: price?.hasOwnProperty('net') ? price.net : +price,
        ucpPeriod: duration.time ? duration.time : ucpPeriod.time,
        name: packageName,
      }
    }

    courseId ? dispatch(saveCourse({ newData, isPackage }))
      : isPackage && dispatch(createCoursePackage({ newData }))
        
    closeModal()
  };

  const toggleCourseSequence =(e)=>{
    setHasCourseSequence(e.target.checked)
  }

  const imageUrl = !!courseInfo?.coverImage && (courseInfo?.coverImage?.url || courseInfo?.coverImage[0]?.url)
  return (
    <Modal
      open={open}
      showActions
      acceptIsDisabled={isDisabled}
      onAccept={handleCreatePackage}
      acceptLabel={t('globals.save')}
      onClose={closeModal}
      title={isPackage ? !courseId ? t('course.createNewPackage') : t('course.editNewPackage') : t('course.createNewCourse')}
      width='650px'
    >
      {open && !isModalOpen ? <Loading /> : isModalOpen && <>
        <CourseImageWrapper >
          <ImageUploader
            type={'single'}
            id={'courseEdit'}
            saveAction={saveImage}
            imageToPreview={imageUrl}
          >
            {imageUrl && <Figure.Rectangle><img alt={'imageToPreview'} src={imageUrl} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
        </CourseImageWrapper>
        <Textinput
          label={isPackage ? t('course.packageName') : t('course.courseName')}
          propName={'name'}
          onChange={changeName}
          value={packageName}
        />
        <UcpPeriodSelector
          duration={duration?.label ? duration : ucpPeriod}
          options={courseDurations}
          setDuration={setDuration}
        />
        <CoursePrice price={price} setPrice={setPrice} />
        <Textinput
          label={t('globals.description')}
          propName={'description'}
          defaultValue={courseInfo?.description}
          innerRef={ref => descriptionRef.current = ref}
        />
        {isPackage && <>
          <SearchSelect
            id="add-course-to-package"
            setValue={handleCourseSelectionArray}
            selectedOptions={selectedCourseArray}
            options={courses}
            label={t('invites.selectCourses')}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id
            }}
            multiple
          />
         { features?.packageSequence && <EditPackageCourseSequence 
            courses={selectedCourseArray} 
            checked={hasCourseSequence} 
            onChange={toggleCourseSequence}
            sequence={newSequence}
            resortItems={setNewSequence}
          /> }
          {/* Add attach file  */}
        </>}
      </>}
    </Modal>
  );
};

export default EditShopCourseSettings;
