import React from 'react';
import styled from 'styled-components';
import Typo from '../Typo';

const CountWrapper = styled(Typo.SmallText)`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.NEUTRAL_LIGHT};
  margin-left: 10px;
`;

const Count = ({ number }) => {
  return (
    <CountWrapper>
      {number || 0}
    </CountWrapper>
  );
}

export default Count;
