import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
// import Button from '../../../../ui/Button';
import emailReg from '../../../../helpers/emailValidation';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeKey = process.env.REACT_APP_STAGE === 'prod' ? 'pk_live_cJIzjNJJArhwBq1nS1KURscP' : 'pk_test_B1tMc6U3rWXMk3IcedxuZazF';
const stripePromise = loadStripe(stripeKey);

// const ProductDisplay = ({ handleClick }) => (
//   <Button type="button" id="checkout-button" role="link" onClick={handleClick}>
//     Checkout
//   </Button>
// );

/* eslint-disable-next-line */
const Message = ({ message }) => (
  <div>
    <p>{message}</p>
  </div>
);

export default function StripeCheckout({ sessionId, paymentEmail, personalInfoAgreement }) {
  /* eslint-disable-next-line */
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const stripeRedirect = async () => {
    const stripe = await stripePromise;

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  //personal agreement always false => investigate
  // return sessionId && emailReg.test(paymentEmail) && personalInfoAgreement ? <ProductDisplay handleClick={handleClick} /> : null
  return sessionId && emailReg.test(paymentEmail) ? stripeRedirect() : null
}
