import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JoditEditor from "jodit-react";
import { Button, Flex, SubPage, Typo } from '../../../ui';
import { selectSettings, updateSettings } from '../settingsSlice';
import { useTranslation } from 'react-i18next';

const TermsConditions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector(selectSettings)
  const editor = useRef(null);
  const [content, setContent] = useState('');

  const config = {
    readonly: false,
    placeholder: 'Start typing...',
    buttons: ['bold', 'italic', 'underline', 'strikethrough', 'ul', 'ol', 'fontsize', 'lineHeight', 'superscript', 'subscript', 'spellcheck', 'hr', 'table', 'link', 'symbols', 'indent', 'outdent', 'left', 'undo', 'source'],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    toolbarAdaptive: false
  };

  const handleUpdate = (event, other) => {
    const editorContent = event;
    setContent(editorContent);
  };

  useEffect(() => {
    setContent(settings?.docs?.termsAndConditions)
  }, [settings])

  const saveChanges = () => {
    const newSettings = { docs: { termsAndConditions: content } }
    dispatch(updateSettings({ settingsData: newSettings }))
  }

  const onReset = () => {
    setContent(settings?.docs?.termsAndConditions);
  }

  return (
    <SubPage>
      <Flex direction='column' align='flex-start' gap='20'>
        <div>
          <Typo.ContentSmallHeader $textAlign='left'>{t('settings.termsConditions')}</Typo.ContentSmallHeader>
          <Typo.SmallText $marginBottom='1rem'>{t('settings.tocInstruction')}</Typo.SmallText>
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={handleUpdate}
          />
        </div>
        <Flex justify='flex-end' width='100%' gap='10'>
          <Button onClick={onReset}>{t('globals.reset')}</Button>
          <Button onClick={saveChanges}>{t('globals.save')}</Button>
        </Flex>
      </Flex>
    </SubPage>
  );
};

export default TermsConditions;
