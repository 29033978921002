import React from 'react';
import styled from 'styled-components';
import { theme } from '..';

const Wrapper = styled.div` 
  position: relative;
  padding: 1rem 1rem 1rem 3rem;
  p::before {
    content: " ";
    background: ${theme.colors.PRIMARY};
    padding: 0 1rem;
    margin-right: 1rem;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: grid;
    place-items: center;
    border-radius: 25px;
  }
`

const PersonalMessage = ({ children }) => {

  return (
    <Wrapper >
      {children}
    </Wrapper >
  );
}

export default PersonalMessage;
