import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../utils/constants';
import { dashboardApi } from '../../utils/urls';
// LEGACY
import CourseStore from '../../../../legacy/src/js/stores/Course';
//
import { createCourse } from '../course/courseSlice';
import httpClient from '../../services/httpClient';

// ----------------- Thunks -----------------------------
export const fetchDashboard = createAsyncThunk('dashboard/fetchDashboard', async ({ sortBy, sortMode, filters }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      sortBy,
      sortMode,
      filters
    },
    method: 'read'
  };

  const res = await httpClient.post(dashboardApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});


// ----------------- Reducers -----------------------------
const initialState = {
  status: 'idle',
  myCourses: [],
  filter: 0
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardFilter(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        // LEGACY UPDATE
        const legacyGlobalData = CourseStore.getInitialState();
        legacyGlobalData.courses = action.payload;
        legacyGlobalData.course = {};
        legacyGlobalData.page = {};
        CourseStore.triggerForLegacy(legacyGlobalData)
        // FINISH LEGACY UPDATE
        state.myCourses = action.payload;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        state.myCourses.push(action.payload)
      })
  }
});

export const { setDashboardFilter } = dashboardSlice.actions

export default dashboardSlice.reducer;

// ----------------- Selectors -----------------------------
export const selectMyCourses = state => state.dashboard.myCourses;
export const selectFilter = state => state.dashboard.filter;
export const selectDashboardStatus = state => state.dashboard.status;

export const selectFilterOptions = () => [
  { value: 0, label: 'globals.all', fnc: (item) => item },
  { value: 1, label: 'dashboard.notFinished', fnc: (item) => item.progress < 1 },
  { value: 2, label: 'globals.finished', fnc: (item) => item.progress === 1 },
  { value: 3, label: 'dashboard.ownCourses', fnc: (item) => item.canEdit },
  { value: 4, label: 'dashboard.invitedCourses', fnc: (item) => !item.canEdit },
]

export const selectMyCoursesFiltered = createSelector(
  [selectMyCourses, selectFilterOptions, selectFilter],
  (items, filterOptions, filter) => items?.filter(filterOptions[filter].fnc)
)
