import React, { useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Textinput, Typo, Button, Flex, SubPage } from '../../../ui';
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { selectLocalization } from '../../auth/authSlice';
import { CONTENT_SMALL } from '../../../utils/media';

const Controls = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;

const ExportTemplate = ({ sendExport, additionalElement, selectedCircle }) => {
  const { t } = useTranslation();
  const locale = useSelector(selectLocalization);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isInactiveUsers, setIsInactiveUsers] = useState(false);
  const [isProgressData, setIsProgressData] = useState(false);
  const exportDP = useRef({});
  const emailRef = useRef(null);

  const clearData = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    setIsInactiveUsers(false);
    setIsProgressData(false);
  }, []);

  const handleSend = useCallback(() => {
    const { startDate, endDate } = exportDP.current;

    const exportData = {
      startDate: startDate.value,
      endDate: endDate.value,
      withInactive: isInactiveUsers,
      withProgress: isProgressData,
      recipientEmail: emailRef.current.value
    }

    sendExport(exportData);
    clearData();
  }, [clearData, isInactiveUsers, isProgressData, sendExport]);

  return (
    <SubPage size={CONTENT_SMALL} >
      <Flex width={'100%'} gap={40} direction={'column'}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <Flex gap='20'>
            <DatePicker
              inputRef={el => exportDP.current['startDate'] = el}
              label={t('globals.startDate')}
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
            />
            <DatePicker
              inputRef={el => exportDP.current['endDate'] = el}
              label={t('globals.endDate')}
              value={endDate}
              onChange={newValue => setEndDate(newValue)}
            />
          </Flex>
        </LocalizationProvider>
      </Flex>
      <Flex width={'100%'} gap={5} direction={'column'}>
        {additionalElement}
      </Flex>
      <Flex width={'100%'} gap={5} direction={'column'}>
        <Typo.MediumText>{t('export.customExport')}</Typo.MediumText>
        <Textinput
          label={t('globals.email')}
          propName={'text'}
          innerRef={ref => emailRef.current = ref}
        />
      </Flex>
      <Flex width={'100%'} direction={'column'} align={'baseline'}>
        <FormControlLabel
          control={<Checkbox value={isInactiveUsers} onChange={() => setIsInactiveUsers(!isInactiveUsers)} />}
          label={<Typo.MediumText>{t('export.inactiveUsers')}</Typo.MediumText>} />
        <FormControlLabel
          control={<Checkbox value={isProgressData} onChange={() => setIsProgressData(!isProgressData)} />}
          label={<Typo.MediumText>{t('export.progressData')}</Typo.MediumText>} />
      </Flex>
      <Controls>
        <Button onClick={handleSend} disabled={!startDate || !endDate || !selectedCircle}>
          {t('Send')}
        </Button>
      </Controls>
    </SubPage>
  );
}

export default ExportTemplate;
