import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../auth/authSlice';
import { Page } from '../../ui';
import { TAC } from '../webshop/components/CopyForTaC';
import { CONTENT_SMALL } from '../../utils/media';

const CoursioTermsAndConditions = () => {
  const language = useSelector(selectLanguage);
  const pageContent = TAC[language] || TAC['en_US'];

  return (
    <Page align={'left'} size={CONTENT_SMALL}>
      {pageContent}
    </Page>
  )
}

export default CoursioTermsAndConditions;

