import React from 'react';
import Typo from '../Typo';

import { FormControlLabel, Radio } from '@mui/material';
import theme from '../theme';

const RadioButton = ({ labelText, value }) => {

  return (
    <FormControlLabel
      value={value}
      control={<Radio sx={{ '&.MuiRadio-root':{ color: theme.colors.PRIMARY}}} />}
      label={<Typo.Text>{labelText}</Typo.Text>}
    />
  )
}

export default RadioButton;
