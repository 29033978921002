import { createSlice } from '@reduxjs/toolkit'
import { fetchStoreCurrency, updateCurrency } from '../settings/settingsSlice'

// ----------------- Thunks -----------------------------


// ----------------- Reducers -----------------------------

const initialState = {
  menuSliderToggle: false,
  incarnateModal: false,
  loginModal: false,
  module: null,
  subModule: null,
  params: null,
  country: {},
  currency: 'SEK'
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIncarnateModal(state, action) {
      state.incarnateModal = action.payload
    },
    setLoginModal(state, action) {
      state.loginModal = action.payload
    },
    setMenuSliderToggle(state, action) {
      state.menuSliderToggle = action.payload || !state.menuSliderToggle
    },
    setCurrentModule(state, action) {
      state.module = action.payload
    },
    setCurrentSubModules(state, action) {
      state.subModule = action.payload.subModule
      state.params = action.payload.params
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStoreCurrency.fulfilled, (state, action) => {
        state.currency = action.payload.currency
        state.storeId = action.payload.storeId
      })
      .addCase(updateCurrency.fulfilled, (state, action) => {
        state.currency = action.payload.currency
      })
  }
})

export const { setIncarnateModal, setLoginModal, setMenuSliderToggle, setCurrentModule, setCurrentSubModules } = globalSlice.actions

export default globalSlice.reducer

// ----------------- Selectors -----------------------------

export const selectSubModule = state => state.global.subModule;
export const selectIncarnateModal = state => state.global.incarnateModal;
export const selectLoginModal = state => state.global.loginModal;
export const selectMenuSliderToggle = state => state.global.menuSliderToggle
export const selectCurrency = state => state.global.currency;
export const selectStoreId = state => state.global.storeId;
