import React from 'react';
import styled from 'styled-components'
import { Flex, Page, PageHeaderLogo, ListMarker, Section, theme, Typo, MediaPlayer, Figure } from '../../ui';
import { CONTENT_SMALL, media } from '../../utils/media';
import coursioNext from '../../ui/assets/Coursio_next_white.png'
import tabMenu from './assets/tabMenu2023-10-25.png'
import taskManagement from './assets/taskManagement2023-10-25.png'
import insights from './assets/insights2023-10-25.png'
import courseCard from './assets/coursecard2023-10-25.png'
import store from './assets/webshop2023-10-25.png'
import nextPromotion from './assets/LaunchingCoursioNEXT.mp4'

const CoursioImage = styled.img`
  max-width: 100%;
  max-height: ${({ $size }) => $size ? $size : '400px'};
  object-fit: contain;
`

const TextBox = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  gap:20px;
`

const TextBoxContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 20px;
  margin-top:10px;

  ${media.lessThan('tablet')} {
    grid-template-columns: auto;
  }
`
const TextBoxWithBG = styled(TextBox)`
  padding:3rem;
  height:100%;
  background-color:${({ $color }) => $color};
  justify-content: center;
  ${media.lessThan('tablet')} {
    padding:1.5rem;
  }
`

const WhatNextSection = styled.div`
  background-color: ${({ theme }) => theme.colors.PRIMARY_LIGHT};
  gap:30px;
  display:flex;
  flex-direction:column;
  width:100%;
  padding:2rem;
`

const NewReleases = () => {

  return (
    <Page staticElements={<PageHeaderLogo lightBackground />}>
      <Flex direction='column'>
        <Section size={CONTENT_SMALL} noMarginBottom>
          <Typo.PageHeader $marginBottom='1rem'> We are proud to announce: Coursio NEXT! </Typo.PageHeader>
          <TextBox>
            <Typo.Text>Since day one of founding Coursio we have had one mission - to create the best education business platform combined with the optimal learning experience. </Typo.Text>
            <Typo.Text>Coursio NEXT captures the essence of two decades of experience about how to succeed in online education - to always be proactive and focus on your NEXT actions in developing your business.</Typo.Text>

            <Typo.ContentSmallHeader>Meet Coursio NEXT:</Typo.ContentSmallHeader>
          </TextBox>
        </Section>
        <TextBoxContainer>
          <TextBoxWithBG $color={theme.colors.PRIMARY_LIGHT}>
            <Typo.Text>In Coursio NEXT we have built a new version through decades of insight about what it takes to build a successful education business online.</Typo.Text>
            <Typo.Text>Coursio NEXT is everything you need to build, grow and scale your education business.</Typo.Text>
            <Typo.Text>Dive into the new features and improvements of the first release of Coursio NEXT that will create a new experience for you and your students - and learn more about what’s to come</Typo.Text>
          </TextBoxWithBG>

          <TextBoxWithBG $color={theme.colors.PRIMARY}>
            <Figure.Video>
              <MediaPlayer posterImage={coursioNext} previewContent={nextPromotion} width="100%" height="auto" />
            </Figure.Video>
            {/* <CoursioNextImage src={coursioNext} /> */}
          </TextBoxWithBG>

          {/* second block */}
          <TextBoxWithBG $color={theme.colors.MARKER_LIGHT}>
            <CoursioImage src={store} />
          </TextBoxWithBG>

          <TextBoxWithBG $color={theme.colors.MARKER_LIGHT}>
            <Typo.ContentSmallHeader>What's New in Coursio NEXT - first release</Typo.ContentSmallHeader>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>The ultimate learning experience</Typo.TextTitle>
              <Typo.Text> Experience a more modern and inviting look on our platform, making your interaction smoother and more enjoyable.</Typo.Text>
            </ListMarker>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>Brand New store</Typo.TextTitle>
              <Typo.Text>Quicker checkout without pre-registration, which will increase conversion. Promote your courses with greater detail, reach a broader audience, and boost your earnings.</Typo.Text>
            </ListMarker>

          </TextBoxWithBG>

          {/* third block */}
          <TextBoxWithBG $color={theme.colors.PRIMARY_LIGHT}>
            <ListMarker >
              <Typo.TextTitle>Easier Navigation</Typo.TextTitle>
              <Typo.Text>We've reorganized the menu, making it simpler to find what you need. Inside section navigation provides even more seamless exploration.</Typo.Text>
            </ListMarker>
            <ListMarker >
              <Typo.TextTitle>Enhanced User Experience</Typo.TextTitle>
              <Typo.Text>We've separated account settings from profile settings for a clearer understanding. Discover overview tables for quicker access to critical information in users, courses, invitations, quizzes, and tasks.</Typo.Text>
            </ListMarker>

          </TextBoxWithBG>

          <TextBoxWithBG $color={theme.colors.PRIMARY_LIGHT}>
            <CoursioImage src={tabMenu} />
          </TextBoxWithBG>

          {/* forth block */}
          <TextBoxWithBG $color={theme.colors.MARKER_LIGHT}>
            <CoursioImage src={taskManagement} />
          </TextBoxWithBG>
          <TextBoxWithBG $color={theme.colors.MARKER_LIGHT}>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>Effortless Task Management</Typo.TextTitle>
              <Typo.Text>Tasks are now simpler to manage, making it easier to see what's waiting for you.</Typo.Text>
            </ListMarker>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>Transparency in Invitations</Typo.TextTitle>
              <Typo.Text>Pending Invitations now show you exactly what their status is</Typo.Text>
            </ListMarker>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>Centralized Exports</Typo.TextTitle>
              <Typo.Text>Access all export options conveniently in one place</Typo.Text>
            </ListMarker>
          </TextBoxWithBG>

          {/* fith block */}
          <TextBoxWithBG $color={theme.colors.PRIMARY_LIGHT}>
            <ListMarker $color={theme.colors.PRIMARY}>
              <Typo.TextTitle>In-Depth Insights</Typo.TextTitle>
              <Typo.Text>Dive into statistics under the Insights section for better control.</Typo.Text>
            </ListMarker>
            <ListMarker $color={theme.colors.PRIMARY}>
              <Typo.TextTitle>Media Library Upgrades</Typo.TextTitle>
              <Typo.Text>The new media library is easier to organize, featuring drag-and-drop functionality into folders and PDF previews.</Typo.Text>
            </ListMarker>
            <ListMarker $color={theme.colors.PRIMARY}>
              <Typo.TextTitle>Brand Customization</Typo.TextTitle>
              <Typo.Text>Customize your Coursio account with your brand's colors, which will be used in more places. Take your time to personalize your experience</Typo.Text>
            </ListMarker>

          </TextBoxWithBG>
          <TextBoxWithBG $color={theme.colors.PRIMARY_LIGHT}>
            <CoursioImage src={insights} $size='330px' />
          </TextBoxWithBG>

          {/* sith block */}
          <TextBoxWithBG $color={theme.colors.MARKER_LIGHT}>
            <CoursioImage src={courseCard} $size='330px' />
          </TextBoxWithBG>
          <TextBoxWithBG $color={theme.colors.MARKER_LIGHT}>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>Efficient Notification Management</Typo.TextTitle>
              <Typo.Text>You can now archive all notifications at once, and filtering your notifications has become simpler.</Typo.Text>
            </ListMarker>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>Dashboard Links</Typo.TextTitle>
              <Typo.Text>Find a link to edit course settings directly on your dashboard</Typo.Text>
            </ListMarker>
            <ListMarker $color={theme.colors.MARKER}>
              <Typo.TextTitle>Improved Group Management</Typo.TextTitle>
              <Typo.Text>Edit groups have become more functional, allowing you to add or remove courses and users all in one place</Typo.Text>
            </ListMarker>
          </TextBoxWithBG>

        </TextBoxContainer>

        <Section size={CONTENT_SMALL}>
          <WhatNextSection  >
            <Typo.PageHeader>What's Coming NEXT?</Typo.PageHeader>
            <Typo.Text>Our dedication to continuous improvement keeps us moving forward. Here's a sneak peek of what's on the horizon at Coursio</Typo.Text>
            <Flex direction='column' align='flex-start' width='100%'>
              <Typo.TextTitle>New Course Experience</Typo.TextTitle>
              <Typo.Text>A completely new interface for presenting your courses and content - creating the ultimate learning experience and increasing engagement - tailored to different types of “course profiles”.</Typo.Text>
            </Flex>
            <Flex direction='column' align='flex-start' width='100%'>
              <Typo.TextTitle>New Course Creation</Typo.TextTitle>
              <Typo.Text>Faster and more powerful course creator than ever before; with more possibilities in terms of course design and at the same time maintaining simplicity.</Typo.Text>
            </Flex>
            <Flex direction='column' align='flex-start' width='100%'>
              <Typo.TextTitle>Coursio NEXT Dashboard</Typo.TextTitle>
              <Typo.Text>A big part of Coursio NEXT has been to rethink the structure of our platform in order for it to be tailored to creating the best experience for running an online education business. Our new intelligent dashboard provides you with the most relevant analysis and data in order to reach your business goals. It will provide an overall new experience for you - and your students.  </Typo.Text>
            </Flex>
            <Flex direction='column' align='flex-start' width='100%'>
              <Typo.TextTitle>Step-by-Step Design Implementation</Typo.TextTitle>
              <Typo.Text>A fresh design will be rolled out gradually, ensuring an even more enjoyable experience.</Typo.Text>
            </Flex>
            <Flex direction='column' align='flex-start' width='100%'>
              <Typo.TextTitle>Enhanced Role System</Typo.TextTitle>
              <Typo.Text>Enjoy a better-defined system that provides access to specific features.</Typo.Text>
            </Flex>
            <Typo.Text>Stay connected with Coursio to be the first to experience these exciting updates and enhancements. Thank you for being part of our wonderful learning community.</Typo.Text>
          </WhatNextSection>

        </Section>
      </Flex>

    </Page >
  );
};

export default NewReleases
