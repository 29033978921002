import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import { notificationsApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";


//Thunks
export const fetchNotifications = createAsyncThunk('notifications/read', async ({ types, reversed, pageLength }, { getState, dispatch, rejectWithValue }) => {
  const data = {
    reversed,
    includeRead: true
  };

  if (types && ['3', '6', '10'].includes(types)) {
    data.types = [types];
  }

  const body = {
    data: data,
    method: 'read',
    pageLength: pageLength,
    pageStart: 0
  };

  const res = await httpClient.post(notificationsApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

export const markNotification = createAsyncThunk('notifications/mark', async ({ ids, method, markAll }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      ids,
      all: markAll
    },
    method: method //Either markRead or markUnread
  };

  const res = await httpClient.post(notificationsApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : `Notification ${method === 'markRead' ? 'archived' : 'unarchived'} successfully`
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

export const fetchUnreadNotifications = createAsyncThunk('global/unreadNotifications', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    method: 'count'
  };

  const res = await httpClient.post(notificationsApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

//Reducers
const initialState = {
  status: 'idle',
  entities: [],
  filter: '0',
  unreadNotifications: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationFilter(state, action) {
      state.filter = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.entities = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchUnreadNotifications.rejected, (state) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchUnreadNotifications.fulfilled, (state, action) => {
        state.unreadNotifications = action.payload
      })
  }
});

export const { setNotificationFilter } = notificationsSlice.actions

export default notificationsSlice.reducer;


//Selectors
export const selectNotifications = state => state.notifications.entities;
export const selectFilter = state => state.notifications.filter;
export const selectUnreadNotifications = state => state.notifications.unreadNotifications
export const selectStatus = state => state.notifications.status


export const selectNotificationFilter = () => [
  { value: '0', label: 'globals.new', fnc: (item) => item.isRead === 0 },
  { value: '1', label: 'globals.all', fnc: (item) => item },
  { value: '6', label: 'globals.tasks', fnc: (item) => item },
  { value: '3', label: 'globals.discussions', fnc: (item) => item },
  { value: '10', label: 'globals.sales', fnc: (item) => item },
  { value: '4', label: 'globals.archived', fnc: (item) => item.isRead === 1 },
]

export const selectNotificationsFiltered = createSelector(
  [selectNotifications, selectNotificationFilter, selectFilter],
  (items, filterOptions, filterValue) => {
    const filter = filterOptions.find((el) => el.value === filterValue);
    return items?.filter(filter.fnc);
  }
)
