import React, { useCallback, useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex, Modal, Textinput, theme } from '../../../../ui';
import FileUploader from '../../../media/uploaders/FileUploader';
import EditableAttach from '../../../tasks/components/EditableAttach';
import { media } from '../../../../utils/media';

const MyDocViewer = styled(DocViewer)`
  min-height:600px;
  ${media.lessThan('phablet')} {
    max-height:440px;
  }
`;

const CustomFile = ({ info, open, onClose, addToPreview }) => {
  const { t } = useTranslation();
  
  const [customFile, setCustomFile] = useState({});
  const [buttonLabel, setButtonLabel] = useState('');

  useEffect(() => {
    if(info){
      setCustomFile(info?.file)
      setButtonLabel(info?.label)
    }
  }, [info])

  const onAccept = useCallback(() => {
    addToPreview({file:customFile, label:buttonLabel})
  }, [customFile, buttonLabel, addToPreview]);

  const handleButtonLabel = useCallback((e) => {
    setButtonLabel(e.currentTarget.value)
  }, []);

  return (
    <Modal
      open={open}
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel='Add to preview'
      width='80%'
    >
      <Flex direction='column' width='100%' align='center' gap='20'>
        <Textinput
          label={'Label that button will show'}
          propName={`button-label`}
          defaultValue={buttonLabel}
          onBlurValue={handleButtonLabel}
        />
        <FileUploader 
          saveAction={setCustomFile}
          id={'customFile'}
          fileType='other'
          label={t('mediaLibrary.uploadFiles')}
          iconName='Upload'
          variant='customized'
          bgcolor={theme.colors.COURSIO_NEW_EXTRA}
        />
        {customFile && customFile.url ? <MyDocViewer
          documents={[{ uri: customFile.url, fileType: customFile.mime }]}
          prefetchMethod={'GET'}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false
            }
          }}
          pluginRenderers={DocViewerRenderers}
        /> :
         <EditableAttach files={customFile} handleDelete={() => setCustomFile({})} />}
      </Flex>
    </Modal>
  );
};

export default CustomFile;
