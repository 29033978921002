import Config from "../Config";
import SuperAgent from "superagent";
import { history } from "../helpers";
import { enqueueSnackbar } from '../../../../modern/node_modules/notistack';

const HTTP_UNAUTHORIZED = 401;
const HTTP_OK = 200;
const INTERNAL_ERROR = 500;

class SteelCompass {
  constructor() {
    this.token = localStorage && localStorage.getItem("token");
  }

  setAuthToken(token) {
    this.token = token;
  }

  call(object, method, data, callback) {
    SuperAgent.post(Config.steelCompassRoot + object)
      .set("Accept", "application/json")
      .set("Content-Type", "application/json")
      .set("Token", this.token)
      .send(JSON.stringify({ method, data }))
      .end((err, response) => {
        if (err && err.status && response.status === HTTP_UNAUTHORIZED) {
          // MOCKED FOR NOW
          window.location.replace('/logout')
          // history.push("/logout");
        }
        if (response.status >= INTERNAL_ERROR) {
          history.push("/coursio-error");
        }
        if (!response.body) {
          response.body = SuperAgent.parse["application/json"](response.text);
        }
        if (response.body.isError && response.status === HTTP_UNAUTHORIZED) {
          // MOCKED FOR NOW
          window.location.replace('/logout')
          // history.push("/logout");
        }
        if (response.status !== HTTP_OK) {
          response.body.isError = true;
        }
        callback(response.body);
      });
  }

  request(object, method, requestData, pageStart, pageLength) {
    const data = requestData || {};
    return new Promise((resolve, reject) => {
      SuperAgent.post(Config.steelCompassRoot + object)
        .set("Accept", "application/json")
        .set("Content-Type", "application/json")
        .set("Token", this.token)
        .send(JSON.stringify({ method, data, pageStart, pageLength }))
        .end((err, response) => {
          if (err && err.status) {
            if (response.status === HTTP_UNAUTHORIZED) {
              const user = JSON.parse(localStorage.getItem("user"));
              if (user && user.loggedIn) {
                // MOCKED FOR NOW
                window.location.replace('/logout')
                // history.push("/logout");
              }
            }
            if (response.status >= INTERNAL_ERROR) {
              if (response.body.errMsg !== 'xroom.app is not configured (go to settings)') {
                history.push("/coursio-error");
              }
            }
            reject((response.body && response.body.errMsg) || "Ohh noes!");
          } else {
            try {
              if (!response.body) {
                response.body = SuperAgent.parse["application/json"](
                  response.text
                );
              }
            } catch (e) {
              reject("Ohh snap!");
            }
            if (
              response &&
              response.body.isError &&
              response.status === HTTP_UNAUTHORIZED
            ) {
              const user = JSON.parse(localStorage.getItem("user"));
              if (user && user.loggedIn) {
                // MOCKED FOR NOW
                window.location.replace('/logout')
                // history.push("/logout");
              }
              reject(response.body.errMsg);
            } else if (response.status >= INTERNAL_ERROR) {
              history.push("/coursio-error");
            } else if (response && response.body.isError) {
              reject(response.body.errMsg);
            } else if (!response) {
              reject("Ohh snap!");
            } else {
              resolve(response.body.data);
            }
          }
        });
    });
  }

  savePageModule(id, params) {
    this.call("page-module", "update", { id, params }, (response) => {});
  }

  deletePageModule(id, callback) {
    this.call("page-module", "delete", { id }, (response) => {
      if (callback) {
        callback();
      }
    });
  }

  upload(files, options) {
    let request,
      method = (options.encoded && "encoded") || "plain";
    options = options || {};

    request = SuperAgent.post(Config.steelCompassRoot + "upload/" + method).set(
      "Token",
      this.token
    );

    let fd = new FormData();
    for (let i = 0, file; (file = files[i]); i++) {
      fd.append("file[]", file, file.name);
    }
    request
      .send(fd)
      .on("progress", (event) => {
        if (options.progress) {
          options.progress(event);
        }
      })
      .end((err, response) => {
        if (err) {
          enqueueSnackbar(response?.body?.errMsg, {variant: 'error'})
          options.error(response.body);
        } else if (options.success) {
          options.success(response.body);
        }
      });
  }

  login(identity, credential) {
    return new Promise((resolve, reject) => {
      this.request("auth", "login", { identity, credential }).then(
        (data) => {
          if (data) {
            resolve(data);
          } else {
            reject("Unknown error");
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  loginCdk(key) {
    return new Promise((resolve, reject) => {
      this.request("auth", "loginCdk", { hash: key }).then(
        (data) => {
          if (data) {
            resolve(data);
          } else {
            reject("Unknown error");
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  logout() {
    return this.request("auth", "logout");
  }
}

const sc = new SteelCompass();

window.SteelCompass = sc;

export default sc;
