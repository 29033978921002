import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsBundle, useSlugName, } from '../../../hooks/readFromUrls';

import { selectIsIncarnated, selectIsLoggedIn } from '../../auth/authSlice';
import { STATUS_LOADING } from '../../../utils/constants';
import { Page, Loading } from '../../../ui';
import { fetchCourse, selectStatus } from '../courseSlice';
import { selectStoreName } from '../globalSlice';

const EditShopCourse = ({ children }) => {
  const dispatch = useDispatch();

  const [courseError, setCourseError] = useState(null);
  const isIncarnated = useSelector(selectIsIncarnated)


  const loadCoursesStatus = useSelector(state => state.courses.status);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loadStatus = useSelector(selectStatus)

  const slugName = useSlugName()
  const isBundle = useIsBundle()

  const storeName = useSelector(selectStoreName)

  useEffect(() => {
    storeName && slugName && dispatch(fetchCourse({ storeName, slugName, type: isBundle ? 2 : 1 }))
      .then((action) => {
        action.payload.errorCode &&
          setCourseError(action.payload.response.errMsg)
      })
  }, [slugName, storeName, dispatch, isBundle, isLoggedIn, isIncarnated]);

  if (loadCoursesStatus !== 'succeeded') return null

  return (
    loadStatus === STATUS_LOADING ? <Loading /> :
      courseError ? <Page>{courseError}</Page> :
        <div>
            {children}
            {/* <BuildCourse isPackage={isPackage} slug={slugName} /> */}
        </div>
  );
};

export default EditShopCourse;
