import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import StripeCheckout from '../StripeCheckout';
import KlarnaCheckout from '../KlarnaCheckout';
import {
  selectPaymentProcessData,
  selectPrivacyAcceptance,
  selectPaymentEmail, resetPaymentProcessData
} from '../../paymentsSlice';
import { selectPaymentType } from '../../globalSlice';
import { Button } from '../../../../ui';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PaymentProviderSnippet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const paymentType = useSelector(selectPaymentType);
  const privacyAcceptance = useSelector(selectPrivacyAcceptance);
  const paymentEmail = useSelector(selectPaymentEmail);
  const paymentProcessData = useSelector(selectPaymentProcessData);

  const resetPayment = useCallback(() => {
    dispatch(resetPaymentProcessData());
  }, [dispatch])


  return (
    <>
      <Button $asText onClick={resetPayment}>{`<`} {t('webshop.checkout.back')}</Button>
      {paymentType === 9 ?
        <KlarnaCheckout htmlSnippet={paymentProcessData?.snippet} paymentEmail={paymentEmail} personalInfoAgreement={privacyAcceptance} />
        :
        <ButtonWrapper>
          <StripeCheckout sessionId={paymentProcessData?.sessionId} paymentEmail={paymentEmail} personalInfoAgreement={privacyAcceptance} />
        </ButtonWrapper>
      }
    </>
  );
};

export default PaymentProviderSnippet;

