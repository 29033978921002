import AppActions from './App';
import MediaLibraryOverviewActions from './MediaLibraryOverview';
import CourseActions from './Course';
import GroupActions from './Group';
import MediaLibraryActions from './MediaLibrary';
import PaymentActions from './Payment';
import StudentActions from './Student';
import AuthActions from './Auth';
import DashboardActions from './Dashboard';
import InvitationActions from './Invitation';
import MessageActions from './Message';
import SettingsActions from './Settings';
import TransactionActions from './Transaction';
import BundleActions from './Bundle';
import DiscountActions from './Discount';
import InviteActions from './Invite';
import InviteWizardActions from './InviteWizard';
import NotificationActions from './Notification';
import StoreActions from './Store';
import TaskActions from './Task';
import UserActions from './User';
import PageModuleActions from './PageModule';
import ExportActions from './Export';
import ClipboardActions from './Clipboard';
import StudentOverviewActions from './StudentOverview';
import PageLayoutActions from './PageLayout';
import StoreOverviewActions from './StoreOverview';
import KlarnaActions from './Klarna';
import StripeActions from './Stripe';
import CategoryActions from './Category';
import QuizActions from './Quiz';
import QuizDetailActions from './QuizDetail';
import WebinarActions from './Webinar';

export {
  AppActions,
  MediaLibraryOverviewActions,
  CourseActions,
  GroupActions,
  MediaLibraryActions,
  PaymentActions,
  StudentActions,
  AuthActions,
  DashboardActions,
  InvitationActions,
  MessageActions,
  SettingsActions,
  TransactionActions,
  BundleActions,
  DiscountActions,
  InviteActions,
  InviteWizardActions,
  NotificationActions,
  StoreActions,
  TaskActions,
  UserActions,
  PageModuleActions,
  ExportActions,
  ClipboardActions,
  StudentOverviewActions,
  PageLayoutActions,
  StoreOverviewActions,
  KlarnaActions,
  StripeActions,
  CategoryActions,
  QuizActions,
  QuizDetailActions,
  WebinarActions,
};

export default {
  AppActions,
  MediaLibraryOverviewActions,
  CourseActions,
  GroupActions,
  MediaLibraryActions,
  PaymentActions,
  StudentActions,
  AuthActions,
  DashboardActions,
  InvitationActions,
  MessageActions,
  SettingsActions,
  TransactionActions,
  BundleActions,
  DiscountActions,
  InviteActions,
  InviteWizardActions,
  NotificationActions,
  StoreActions,
  TaskActions,
  UserActions,
  PageModuleActions,
  ExportActions,
  ClipboardActions,
  StudentOverviewActions,
  PageLayoutActions,
  StoreOverviewActions,
  KlarnaActions,
  StripeActions,
  CategoryActions,
  QuizActions,
  QuizDetailActions,
  WebinarActions,
};
