import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userExport } from '../exportSlice';
import { SearchSelect } from '../../../ui';
import { fetchCircles, selectCircles } from '../../search/searchSlice';
import ExportTemplate from './ExportTemplate';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const GroupsSelector = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const GroupExports = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const circles = useSelector(selectCircles)
  const [selectedCircle, setSelectedCircle] = useState(null);

  useEffect(() => {
    dispatch(fetchCircles({}));
  }, [dispatch])

  const sendExport = useCallback((data) => {
    const exportData = {
      ...data,
      circleIds: [selectedCircle.id]
    }

    dispatch(userExport({ exportData }))
  }, [dispatch, selectedCircle]);

  return (
    <ExportTemplate sendExport={sendExport} selectedCircle={selectedCircle}
      additionalElement={
        <GroupsSelector>
          <SearchSelect
            options={circles || []}
            selectedOptions={selectedCircle}
            label={t('globals.group')}
            setValue={setSelectedCircle}
          />
        </GroupsSelector>}
    />
  );
}

export default GroupExports;
