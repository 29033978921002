import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { selectUserTheme } from '../../globalSlice';
import { CoverMedia, Section, theme, Typo } from '../../../../ui';
import ShowTermsAndConditions from '../../../../components/ShowTermsAndConditions';
import ListCourses from '../../../../components/ListCourses';
import InfoIconSection from '../../components/InfoIconSection';
import ShopCourseToc from './ShopCourseToc';
import Teacher from './Teacher';
import CtaButton from './CtaButton';
import MainDescription from './MainDescription';
import CTABanner from './CTABanner';
import CourseInPackageInfo from './CourseInPackageInfo';
import { CONTENT_SMALL, media } from '../../../../utils/media';
// import DownloadableAttach from '../../../tasks/components/DownloadableAttach';
import DownloadableFile from '../../../../ui/DownloadableFile';

const FullWidth = styled.div`
  display:flex;
  padding-top:10px;
  width:100%;
  justify-content:center;
`

const Wrapper = styled.div` 
  max-width: ${CONTENT_SMALL}px;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2rem 0;

  ${media.lessThan('laptop')} {
    padding: 2rem 1.5rem;
  } 
`

const ShopCourseLayout = ({courses, addItemHandler, course, isPackage,
  coverMedia, ctaBanner, infoIcons, infoPoints, teacher, highlightedInfo, hideToc, activeModules, sectionTitles, customFile
}) => {
  const { t } = useTranslation();

  const userTheme = useSelector(selectUserTheme);
  const [showCourseModal, setShowCourseModal] = useState(null)

  //FOR PACKAGES ONLY
  const showPackageInfo = useCallback((course) => {
    setShowCourseModal(course)
  }, [])

  const closeModal = () => {
    setShowCourseModal(null)
  }

  // ----------

  const excludeActive = useCallback(() =>
    courses?.filter(item => item.id !== course?.id && !!item.public), [courses, course])();

  if (course?.status !== 'succeeded' || !course) return null

  const {  introduction, courseToc, id, ucpPeriod, packageItems } = course;

  const hasTeacher = (!activeModules || activeModules?.teacher) && !!teacher && !!Object.keys(teacher)?.length
  const showToc = !!activeModules ? activeModules?.toc : !hideToc && !!courseToc?.length
  const showInfoIcons = (!activeModules || activeModules?.infoPoints) && !!infoPoints?.length
  const showDownloadableFile = (!activeModules || !!activeModules?.customFile) && !!customFile && !!Object.keys(customFile).length

  return (
    <>
      <CoverMedia
        videoPreview={coverMedia?.video?.url}
        themeColor={userTheme.color}
        thumbnailImage={coverMedia?.image}
        emptyCoverMedia={!!activeModules && !activeModules?.coverMedia}
      />
      <CTABanner
        themeColor={userTheme.color}
        courseId={id}
        buyButton={true}
        addItemHandler={addItemHandler}
        info={ctaBanner?.hasOwnProperty('title') ? ctaBanner : {title:'Begin your journey today!', description:'Unlock new skills and knowledge with a expert-led course. Enroll now!'}}
      />
      <Section title={introduction?.title} noMarginBottom={!teacher || !Object.keys(teacher)?.length} >
        <MainDescription
          addItemHandler={addItemHandler}
          infoIcons={!!activeModules && !activeModules?.infoIcons ? null : infoIcons}
          info={!!activeModules && !activeModules?.highlightedInfo ? null : highlightedInfo}
          ucpPeriod={ucpPeriod}
          hasBottomMargin
          courseId={id}
        />
        {showDownloadableFile &&
            <DownloadableFile.AsButton label={customFile.label} file={customFile.file}/>
            // <Section title={'program'} align='center' >
            //   <DownloadableAttach files={customFile} />
            // </Section>
        }
      </Section>

      {!isPackage && <Teacher teacherInfo={hasTeacher && teacher} />}

      {isPackage &&
        <Wrapper>
          <Typo.ContentBigHeader $marginBottom='3rem'>
            {sectionTitles?.coursesIncluded || t('webshop.course.coursesIncluded')}
          </Typo.ContentBigHeader>
          <ListCourses showProgress={false} showButtons={false} items={packageItems} showPackageInfo={showPackageInfo} />
          {/* <CtaButton addItemHandler={addItemHandler} courseId={course.id} /> */}
        </Wrapper>
      }

      {hasTeacher &&
        <CtaButton addItemHandler={addItemHandler} courseId={id} compact={!teacher?.hasOwnProperty('about')} extraMargin={!showInfoIcons}/>}

      {showInfoIcons && 
      <Section  align='center'
        title={sectionTitles?.infoPointsTitle || t('webshop.course.infoPointsTitle')} >
        <InfoIconSection
          items={infoPoints}
        />
      </Section>}

      {!isPackage && showToc &&
        <Section
          variant={theme.colors.NEUTRAL_LIGHT}
          title={sectionTitles?.courseContentTitle || t('webshop.course.courseContentTitle')}
        >
          <ShopCourseToc  tocData={courseToc} />
        </Section>}

      <FullWidth width='100%' justify='center' >
        <ShowTermsAndConditions />
      </FullWidth>

      {(teacher || courseToc) && !!courses && !!excludeActive?.length &&
        <CtaButton addItemHandler={addItemHandler} courseId={id} />}

      {<Section title={sectionTitles?.relatedCourses ||  t('webshop.course.relatedCourses')}>
        <ListCourses
          showProgress={false}
          showButtons={true}
          items={excludeActive}
          addItem={addItemHandler}
        />
      </Section>}
      {isPackage && <CourseInPackageInfo onCloseModal={closeModal} course={showCourseModal} /> }
    </>
  );
};

export default ShopCourseLayout;
