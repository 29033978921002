import React, { useState, useCallback, useMemo } from 'react';
import {Button, Flex, IconAsButton, Textinput, Typo, Avatar, theme} from '../index';
import { formatDistanceToNowStrict } from 'date-fns';
import styled from 'styled-components';

const ButtonsWrapper = styled(Flex)`
  border-top: 1px solid ${theme.colors.NEUTRAL_40};
`

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  gap:10px;
  // background-color:pink;
  padding-left:${({$level}) => $level > 0 && `calc(${$level}*40px)`};
`
 
const MessageWrapper = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  background-color:aliceblue;
`

const Message = ({ message, currentUserId, onDelete, onEdit, onReply, level }) => {
  const [editContent, setEditContent] = useState(message.body);
  const [isEditing, setIsEditing] = useState(false);

  const [isReplying, setIsReplying] = useState(false);
  const [replyContent, setReplyContent] = useState('');

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const cancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleSave = useCallback(() => {
    onEdit(message.id, editContent)
    setIsEditing(false);
  }, [onEdit, message.id, editContent]);

  const handleReply = () => {
    setIsReplying(true);
  };

  const handleSendReply = () => {
    onReply(message.id, replyContent);
    setReplyContent('');
    setIsReplying(false);
  };

  const renderMessageFiles = useMemo(() => {
    if (message?.objects?.hasOwnProperty('files')) {
      return message.objects.files.map(file => <Typo.Info key={file.url}>{file.title}</Typo.Info>)
    }
  }, [message])

  return (
    <Wrapper $level={level}>
      <Flex gap='10' width='100%' align='flex-start' >
        <Avatar size='small' name={message.author.name} url={message.author.avatar} />
        <MessageWrapper>
          <Flex direction='column' gap='10' width='100%' align='flex-start'>
            <div>
              <Typo.Text>{message.author.name}</Typo.Text>
              <Typo.Info>{formatDistanceToNowStrict(new Date(message.created.split(' ').join('T') + 'Z'), { addSuffix: true })}</Typo.Info>
            </div>
            {!isEditing ? <> 
              {message.body && <Typo.TextTitle>{message.body}</Typo.TextTitle>}
              { !!renderMessageFiles && <div>
                {renderMessageFiles}
              </div>}
              <ButtonsWrapper gap='5' width='100%' justify={'flex-end'}>
                {currentUserId === message.author.id ? <>
                    <IconAsButton size='small' iconName='Edit' clickHandle={handleEdit} />
                    <IconAsButton size='small' iconName='Delete' clickHandle={() => onDelete(message.id)} />
                  </>
                  : <IconAsButton size='small' iconName='Reply' clickHandle={handleReply} />
                }
              </ButtonsWrapper>
            </> : 
              <>
                <Textinput
                  type="text"
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                />
                { !!renderMessageFiles && <div>
                  {renderMessageFiles}
                </div>}
                <ButtonsWrapper gap='5' width='100%' justify={'flex-end'}>
                  <Button $asText onClick={handleSave}>Save</Button>
                  <Button $asText onClick={cancelEdit}>Cancel</Button>
                </ButtonsWrapper>
              </> 
            }
          </Flex>
        </MessageWrapper>
      </Flex>
      {isReplying && (
        <Flex>
          <Textinput
            type="text"
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
            placeholder="Type your reply here..."
          />
          <IconAsButton size='small' iconName='Send' clickHandle={handleSendReply} />
        </Flex>
      )}

      {message.children?.length > 0 &&
        message.children.map(childMessage => (
          <Message
            key={childMessage.id}
            message={childMessage}
            currentUserId={currentUserId}
            onDelete={onDelete}
            onEdit={onEdit}
            level={++level}
            onReply={onReply}
          />
        ))}
    </Wrapper>
  );
};

export default Message;
