import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { userExport } from  '../exportSlice';
import ExportTemplate from './ExportTemplate';

const UserExports = () => {
  const dispatch = useDispatch();

  const sendExport = useCallback((exportData) => {
    dispatch(userExport({exportData}));
  },[dispatch]);

  return (
    <ExportTemplate sendExport={sendExport} selectedCircle={true} />
  );
}

export default UserExports;
