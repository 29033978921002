
import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { STATUS_FAILED, STATUS_SUCCEEDED, STATUS_LOADING, TOAST_SUCCESS, TOAST_ERROR } from '../../utils/constants';

import { fileApi } from '../../utils/urls';
import { enqueueSnackbar } from 'notistack'
import httpClient from "../../services/httpClient";
import { incarnate } from '../auth/authSlice';

// ----------------- Thunks -----------------------------

export const fetchMedia = createAsyncThunk('file/fetchMedia', async ({ directoryId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      directoryId //Folder
    },
    method: 'listDirectory'
  };

  const res = await httpClient.post(fileApi(), body, getState, dispatch, rejectWithValue, true);

  return res.data;
})

export const createDirectory = createAsyncThunk('file/createDirectory', async ({ directoryId, name }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      directoryId, //Folder id,
      name //folder name
    },
    method: 'createDirectory'
  };

  const res = await httpClient.post(fileApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully created a new folder.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
})

export const moveFile = createAsyncThunk('file/moveFile', async ({ file, directoryId, id, isMessage = true, }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      id, //array of dragged items
      directoryId //Folder id,
    },
    method: 'move'
  };

  const res = await httpClient.post(fileApi(), body, getState, dispatch, rejectWithValue);

  if (isMessage) {
    const message = res.isError ? res.errMsg : 'Successfully changed folder.'
    const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
    enqueueSnackbar(message, { variant })
  }

  return { file, directoryId, id };
})

export const deleteFile = createAsyncThunk('file/deleteFile', async ({ id }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      id
    },
    method: 'delete'
  };

  const res = await httpClient.post(fileApi(), body, getState, dispatch, rejectWithValue, true);
  return res.data;
})

// ----------------- Reducers -----------------------------

const mediaFilesAdapter = createEntityAdapter()
const mediaFoldersAdapter = createEntityAdapter()

const mediaSlice = createSlice({
  name: 'media',
  initialState: {
    status: 'idle',
    files: {
      ids: [],
      entities: {},
    },
    folders: {
      ids: [],
      entities: {},
    },
    breadcrumbs: []
  },
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchMedia.pending, (state) => {
        state.status = STATUS_LOADING
      })
      .addCase(fetchMedia.rejected, (state) => {
        state.status = STATUS_FAILED
      })
      .addCase(fetchMedia.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.breadcrumbs = action.payload.breadcrumbs
        mediaFilesAdapter.setAll(state.files, action.payload.files)
      })
      .addCase(incarnate.pending, (state, action) => {
        mediaFilesAdapter.removeAll(state.files)
        mediaFoldersAdapter.removeAll(state.folders)
      })
      .addCase(deleteFile.rejected, (state, action) => {
        enqueueSnackbar(action.payload.response.errMsg, { variant: TOAST_ERROR });
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        if (action?.payload) {
          mediaFilesAdapter.removeOne(state.files, action.meta.arg.id)
          enqueueSnackbar('File successfully deleted.', { variant: TOAST_SUCCESS });
        }
      })
      .addCase(moveFile.fulfilled, (state, action) => {
        //when just moving file from one side to other we miss 'file'. will wait for new DnD to finish
        const types = selectMediaTypes()
        const mediaType = types.filter(type => type.extensions.includes(action.payload.file?.extension));
        const newFile = { ...action.payload.file, id: action.payload.id, parentId: action.payload.directoryId, type: mediaType[0]?.value }
        mediaFilesAdapter.upsertOne(state.files, newFile)
        state.status = STATUS_SUCCEEDED
      })
      .addCase(createDirectory.fulfilled, (state, action) => {
        const newFolder = { ...action.payload, parentId: action.payload.parent_id, type: action.payload.file_type }
        mediaFoldersAdapter.addOne(state.files, newFolder)
        state.status = STATUS_SUCCEEDED
      })
  }
})

// export const {  } = mediaSlice.actions

export default mediaSlice.reducer


// ----------------- Selectors -----------------------------
export const {
  selectAll: selectFiles,
  selectById: selectFileById,
} = mediaFilesAdapter.getSelectors((state) => state.media.files)
export const {
  selectAll: selectFolders,
  selectById: selectFolderById,
} = mediaFoldersAdapter.getSelectors((state) => state.media.folders)

export const selectStatus = (state) => state.media.status

export const selectMediaTypes = () => [
  { value: 'image', label: 'globals.images', extensions: ['jpg', 'jpeg', 'png', 'gif', 'WebP', 'svg'] },
  { value: 'video', label: 'globals.videos', extensions: ['mp4', 'mov', 'mpeg'] },
  { value: 'audio', label: 'globals.audios', extensions: ['m4a', 'mpeg-4', 'mp3', 'wav'] },
  { value: 'other', label: 'globals.others', extensions: ['doc', 'docx', 'pdf'] },
  { value: 'all', label: 'globals.all', extensions: [] },
];

export const selectMediaFiles = createSelector(
  [selectFiles],
  (items) => items?.filter((item) => item.type !== 'dir')
)

const selectType = (state, { type }) => type
const selectQuery = (state, { type, query }) => query
export const selectMediaByType = createSelector(
  [selectType, selectQuery, selectMediaFiles],
  (type, query, allMedia) => {
    const media = type === 'all' ? allMedia : allMedia?.filter((item) => item.type === type)
    return media.filter((el) => el.name.toLowerCase().includes(query.toLowerCase()))
  }
)

export const selectImages = createSelector(
  [selectFiles],
  (items) => items?.filter((item) => item.type === 'image')
)

export const selectVideos = createSelector(
  [selectFiles],
  (items) => items?.filter((item) => item.type === 'video' || item.type === 'mp4')
)

export const selectMediaFolders = createSelector(
  [selectFiles, selectQuery],
  (items, query) => {
    const folders = items?.filter((item) => item.type === 'dir')
    return folders.filter((el) => el.name.toLowerCase().includes(query.toLowerCase()))
  }
)

export const selectMediaBreadcrumbs = (state) => state.media.breadcrumbs
