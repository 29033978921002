import React from 'react';
import { Flex } from '../../ui';
import styled from 'styled-components'
import { CONTENT_WIDE, media } from '../../utils/media'

const Wrapper = styled(Flex)`
  max-width: ${CONTENT_WIDE}px ;
  margin: auto;
  padding: 1rem 0.5rem;
  ${media.lessThan('desktop')} {
    padding: 1rem;
  }
  ${media.lessThan('mobile')} {
    padding:1rem 0.5rem 0.5rem;
  }
`

const SubMenuWrapper = ({ children, ...props }) => {

  return (
    <Wrapper {...props}>
      {children}
    </Wrapper>
  );
};

export default SubMenuWrapper;
