import { useMemo } from 'react';
import { useModule } from './readFromUrls';

export const NO_HEADER_MODULES = [
  'coursio-error', 'login', 'restore-password', 'invitation',
  'gift', 'course', 'down', 'validate-diploma', 'new-releases', 'faq', 'resume-registration'
]

export const useNoHeaderModules = () => {

  const module = useModule();

  const noHeader = useMemo(() => {
    return !module || NO_HEADER_MODULES.includes(module)
  }, [module]);

  return noHeader;
}
