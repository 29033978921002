import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { Flex, Typo, ListTable, Page, theme, Symbols, Loading } from '../../ui';
import { selectIsIncarnated, selectLocalization, selectMyStore } from '../auth/authSlice';
import {
  fetchAnalytics,
  fetchInvitations,
  fetchSales,
  selectAnalytics,
  selectInvitations,
  selectSales
} from '../statistics/statisticsSlice';
import { currencyFormatter, fractionsFormat } from '../../helpers/formatNumbers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { media } from '../../utils/media';
import { fetchStore } from '../webshop/globalSlice';
import { selectCourses } from '../webshop/coursesSlice';
import CourseUsageAnalytics from './CourseUsageAnalytics';

dayjs.extend(quarterOfYear)

const InsightsCard = styled(Flex)`
  background-color:${theme.colors.PRIMARY_LIGHT}; 
  padding: 38px;
  border-radius: 10px;
  gap: 45px;
`
const FlexContainer = styled(Flex)`
  gap: 100px;
  ${media.lessThan('phablet')} {
    flex-direction: column;
    gap:20px;
  }
`
const SymbolWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Insights = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const locale = useSelector(selectLocalization)
  const sales = useSelector(selectSales)
  const analytics = useSelector(selectAnalytics)
  const invitations = useSelector(selectInvitations)
  const isIncarnated = useSelector(selectIsIncarnated);
  const courses = useSelector(selectCourses)
  const [dataSales, setDataSales] = useState();
  const storeName = useSelector(selectMyStore);

  const [startDate, setStartDate] = useState(dayjs().startOf('quarter'));
  const [endDate, setEndDate] = useState(dayjs());
  const [totalCourses, setTotalCourses] = useState(0);
  const [courseModalisOpen, setCourseModalisOpen] = useState();

  useEffect(() => {
    !!locale && dayjs.locale(locale)
  }, [locale])

  useEffect(() => {
    if (endDate && startDate) {
      const dates = { startDate: startDate.toISOString().split('T')[0], endDate: endDate.toISOString().split('T')[0] }
      dispatch(fetchSales(dates));
      dispatch(fetchAnalytics({ ...dates, fetchStore: true }));
      dispatch(fetchInvitations(dates));
    }
  }, [dispatch, isIncarnated, startDate, endDate])

  useEffect(() => {
    storeName && dispatch(fetchStore({ store: storeName }));
  }, [dispatch, storeName])

  useEffect(() => {

    const populateSalesDataWithGA = (
      id,
      slug,
      name,
      salesCount,
      salesAmount,
      invites,
      invitesAccepted
    ) => {

      const gaData = {
        id,
        slug,
        name,
        pageViews: 0,
        time: { minutes: 0, seconds: 0 },
        conversionRate: 0,
        quatity: salesCount,
        amount: salesAmount,
        invites,
        invitesAccepted
      };

      let viewsCount = 0
      let timeCount = 0
      let count = 0

      for (let i = 0; i < analytics.length; i++) {
        if (analytics[i] && analytics[i].dimensions[0].includes(slug)) {
          count++
          viewsCount += +Number(analytics[i].metrics[0])
          timeCount += +Number(analytics[i].metrics[1])
        }
      }

      gaData.pageViews = viewsCount
      const averageTime = count > 10 ? timeCount / viewsCount : timeCount
      const minutes = Math.floor(averageTime / 60);
      const seconds = Math.floor(averageTime - minutes * 60);
      gaData.time = { minutes, seconds }
      gaData.conversionRate = viewsCount > 0 && salesCount > 0 ? ((salesCount / viewsCount) * 100) : 0

      return gaData
    }

    let totalCourses = 0
    const newData = courses.map((course) => {
      const courseSales = sales?.salesPerObject?.find(element => +element.id === course.id)
      const courseInvites = invitations?.invPerCourse?.find(element => element.id === course.id)

      if (course.type === 1 || course.type === 2) {
        if (courseSales) totalCourses += courseSales.salesCount

        return populateSalesDataWithGA(
          course.id,
          course.slug,
          course.name,
          courseSales?.salesCount || 0,
          courseSales?.salesAmount || 0,
          courseInvites?.count || 0,
          courseInvites?.acceptedCount || 0
        )
      }
      return null
    })
    setTotalCourses(totalCourses)
    newData.length && setDataSales(newData)

  }, [sales, analytics, courses, invitations, isIncarnated])

  const openAnalyticDetails = (course) => {
    setCourseModalisOpen(course)
  }

  const tableInfoSales = useMemo(() => ([
    { label: t('globals.course'), data: (course) => course.name },
    { label: t('insights.quantity'), data: (course) => course.quatity },
    {
      label: t('insights.amount'),
      data: (course) => course.amount && currencyFormatter(+course.amount, { symbol: sales.storeCurrency, significantDigits: 2 })
    },
    { label: t('insights.invitesSent'), data: (course) => course.invites },
    { label: t('insights.invitesAccepted'), data: (course) => course.invitesAccepted },
    {
      label: t('insights.pageViews'),
      data: (course) => course.pageViews
    },
    { label: t('insights.timeSpent'), data: (course) => `${course.time.minutes}.${course.time.seconds}min` },
    { label: t('insights.conversion'), data: (course) => `${fractionsFormat(course.conversionRate)}%` },

  ]), [sales.storeCurrency, t]);

  return (
    <Page gap='60' staticElements={
      locale && <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <Flex gap='40' justify={'center'}>
          <DatePicker
            label={t('globals.startDate')}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue)
            }}
          />
          <DatePicker
            label={t('globals.endDate')}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue)
            }}
          />
        </Flex>
      </LocalizationProvider>
    }>
      <FlexContainer>
        <InsightsCard >
          <Flex direction='column' align='flex-start'>
            <Typo.ContentSmallHeader $textAlign='left'>{t('insights.soldCourses')} </Typo.ContentSmallHeader>
            <Flex gap='5'>
              <Typo.PageHeader $textAlign='left'>{totalCourses}</Typo.PageHeader>
              {/* <Typo.PageHeader color={theme.colors.COURSIO}>.</Typo.PageHeader> */}
            </Flex>
          </Flex>
          <SymbolWrapper>
            <Symbols.Books size='gigantic' color={theme.colors.NEUTRAL_PRIMARY} />
          </SymbolWrapper>
        </InsightsCard>
        <InsightsCard>
          <Flex direction='column' align='flex-start'>
            <Typo.ContentSmallHeader $textAlign='left'>{t('insights.totalSales')} </Typo.ContentSmallHeader>
            <Flex gap='5' align='baseline'>
              <Typo.PageHeader $textAlign='left'>{currencyFormatter(sales.totalValue, { symbol: '' })}</Typo.PageHeader>
              <Typo.TextTitle>{sales.storeCurrency}</Typo.TextTitle>
              {/* <Typo.PageHeader color={theme.colors.COURSIO}>.</Typo.PageHeader> */}
            </Flex>
          </Flex>
          <SymbolWrapper>
            <Symbols.BarChart size='gigantic' color={theme.colors.NEUTRAL_PRIMARY} />
          </SymbolWrapper>
        </InsightsCard>
        <InsightsCard >
          <Flex direction='column' align='flex-start'>
            <Typo.ContentSmallHeader $textAlign='left'>{t('insights.sentInvites')}</Typo.ContentSmallHeader>
            <Flex gap='5'>
              <Typo.PageHeader $textAlign='left'>{invitations.count}</Typo.PageHeader>
              {/* <Typo.PageHeader color={theme.colors.COURSIO}>.</Typo.PageHeader> */}
            </Flex>
          </Flex>
          <SymbolWrapper>
            <Symbols.Invitation size='gigantic' color={theme.colors.NEUTRAL_PRIMARY} />
          </SymbolWrapper>
        </InsightsCard>
      </FlexContainer>

      {!dataSales ? <Loading /> : !!dataSales?.length &&
        <ListTable
          tableInfo={tableInfoSales}
          data={dataSales}
          tableName={'sales'}
          onRowClick={openAnalyticDetails}
        />}

      <CourseUsageAnalytics startDate={startDate.toISOString().split('T')[0]} endDate={endDate.toISOString().split('T')[0]} courseToShow={courseModalisOpen} onClose={() => setCourseModalisOpen()} />
    </Page >
  );
};

export default Insights;
