import React from 'react';
import { Typo, Flex, Modal } from '../../../ui';
import AnswerStatusIcon from '../../tasks/components/AnswerStatusIcon';

const QuizResultsModal = ({ userAnswers, questions, onClose }) => {

  return (
    <Modal
      open={!!userAnswers}
      width='600px'
      onClose={onClose}
      title={userAnswers?.name}
    >
      <Flex direction='column' gap='25' align='flex-start'>
        {questions?.map((question) => {
          const options = Object.values(question?.options)
          const answer = userAnswers?.questions[question.id]
          return <Flex direction='column' gap='10' align='flex-start' key={question.id} >
            <div>
              <Typo.TextTitle>{question?.name}</Typo.TextTitle>
              <Flex>
                <Typo.SmallText>
                  {options.map((option, id) => {
                    return `${option}${id < options.length - 1 ? ' /  ' : ''}`
                  })}
                </Typo.SmallText>
              </Flex>
            </div>
            <Flex gap='10'>
              <AnswerStatusIcon grade={answer?.grade} taskType={question?.type} />
              <div>
                {answer?.value.map((item) => <Typo.Text key={item.id}>{options[item]}</Typo.Text>)}
                {answer?.created && <Typo.Info>{answer?.created}</Typo.Info>}
              </div>
            </Flex>
          </Flex>
        })}
      </Flex>
    </Modal>
  )
};

export default QuizResultsModal;
