import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)
dayjs.extend(duration)

export const toRelativeCalendar = (date) =>
  date ? dayjs(date).fromNow() : '-'

export const durationLike = (duration) =>
  duration ? dayjs.duration(duration).format('mm:ss') : '-'
