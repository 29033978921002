import React from 'react';
import { useTranslation } from 'react-i18next';
import {  useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Flex, Typo } from '../../../../ui';
import { priceFormater } from '../../../../helpers/formatNumbers';
import { selectCurrentStoreHideVat } from '../../globalSlice';
import { selectCourseById } from '../../coursesSlice';

const Wrapper = styled(Flex)`
  margin: ${({ $noMargin }) => $noMargin ? '0 auto': '2.5rem auto 0' };
  margin-bottom: ${({ $withBottomMargin }) => $withBottomMargin && '2.5rem' };
`;

const CtaButton = ({ courseId, compact, inverted, $themeColor, $bgColor, addItemHandler, extraMargin }) => {
  const { t } = useTranslation();
  const course = useSelector(state => selectCourseById(state, courseId))
  const hideVAT = useSelector(selectCurrentStoreHideVat)

  return (+course?.price?.gross > 0 ?
    inverted ?
    <Flex gap={15} width='100%' maxWidth={'38%'} justify='flex-end' breakPoint={{ size: 'phablet', value: 'max-width:100%;' }}>
      <Flex align='flex-end' gap='5' direction='column'>
        <Typo.TextTitle $textAlign='right' $themeColor={$bgColor}>{priceFormater(course?.price, hideVAT, course?.currency)}</Typo.TextTitle>
        {hideVAT && <Typo.Info $themeColor={$bgColor}>{t('globals.priceExcVat')}</Typo.Info>}
      </Flex>
      <Button $themeColor={$themeColor} $variant='themed' onClick={() => addItemHandler(course)} >{t('webshop.buy')}</Button>
    </Flex> :
    <Wrapper align='center' direction='column' $noMargin={compact} $withBottomMargin={extraMargin}>
      <Flex align='baseline' gap='5'>
        <Typo.TextTitle>{priceFormater(course?.price, hideVAT, course?.currency)}</Typo.TextTitle>
        {hideVAT && <Typo.Info>{t('globals.priceExcVat')}</Typo.Info>}
      </Flex>
      <Button
        onClick={() => addItemHandler(course)}
        $variant='action'
      >
        {t('webshop.buy')}
      </Button>
    </Wrapper> : <></>
  );
};

export default CtaButton;
