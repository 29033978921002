import React from 'react';
import { AccordionDetails, List, ListItem } from '@mui/material';
import UserAnswer from './UserAnswer';

const AnswersList = ({ answers, parentId, taskType, userName, handleOpenFeedback }) => {

  return (
    <AccordionDetails>
      <List sx={{ width: '100%' }}>
        {Object.values(answers).map((answer) => {
          return <ListItem
            key={parentId + '-' + answer.id}
            disablePadding
          >
            <UserAnswer
              key={answer.id}
              handleOpenFeedback={() => handleOpenFeedback({ answer, userName: userName })}
              answer={answer}
              taskType={taskType}
            />
          </ListItem>
        }
        )}
      </List>
    </AccordionDetails>
  );
};

export default AnswersList;
