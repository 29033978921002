import React from 'react';
import Typo from '../Typo';

import { Checkbox, FormControlLabel } from '@mui/material';
import theme from '../theme';

const CheckboxButton = ({ labelText, value, checked, handleChange, disabled }) => {

  return (
    <FormControlLabel
      control={
        <Checkbox
          size='small' disabled={disabled}
          sx={{  '&.MuiCheckbox-root':{ color: theme.colors.PRIMARY}}}
          checked={!!checked} onChange={handleChange} name={value} />
      }
      label={<Typo.Text>{labelText}</Typo.Text>}
    />
  )
}

export default CheckboxButton;
