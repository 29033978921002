import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllItems, selectIsValidDiscount } from '../../cartSlice';
import { fetchStore, setDiscountCode } from '../../globalSlice';
import { Flex } from '../../../../ui';
import Discount from './Discount';
import { useWebshopName } from "../../../../hooks/readFromUrls";
import BuyAsGift from './BuyAsGift';
import { selectIsAGift } from '../../paymentsSlice';


const CheckoutExtraOptions = () => {
  const dispatch = useDispatch();

  const cartItems = useSelector(selectAllItems);
  const isValidDiscount = useSelector(selectIsValidDiscount);
  const webshop = useWebshopName();
  const isAGift = useSelector(selectIsAGift);
  const [storeCurrency, setStoreCurrency] = useState('');

  useEffect(() => {
    !!cartItems?.length && !storeCurrency && setStoreCurrency(cartItems[0]?.currency)
  }, [cartItems, storeCurrency])

  const clearDiscount = useCallback(() => {
    dispatch(setDiscountCode(null));
    dispatch(fetchStore({ store: webshop }))
  }, [dispatch, webshop])

  return (
    <Flex direction={'column'}>
      { !isValidDiscount && <Discount clearDiscount={clearDiscount} />}
      <BuyAsGift isAGift={isAGift} />
    </Flex> 

  );
};

export default CheckoutExtraOptions;
