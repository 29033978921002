import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components'
import { Typo, Button } from '../../../../ui';
import { media } from '../../../../utils/media';
import { selectStoreName, selectUserTheme } from '../../globalSlice';
import { selectAllItems } from '../../cartSlice';
import CheckoutCartSummary from './CheckoutCartSummary';
import CheckoutExtraOptions from './CheckoutExtraOptions';
import CheckoutUserInfo from './CheckoutUserInfo';
import MessageContainer from './MessageContainer';
import AcceptTermsGoToPayment from './AcceptTermsGoToPayment';

const GridWrapper = styled.div`
  width:100%;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 15% auto 15%;
  

  ${media.lessThan('phablet')} {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  width:100%;
  max-width:450px;
  align-items: flex-start;
  margin:auto;
`

const CheckoutDataContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const storeName = useSelector(selectStoreName);
  const cartItems = useSelector(selectAllItems);
  const userTheme = useSelector(selectUserTheme);

  const goToStore = useCallback(() => {
    navigate(`/store/${storeName}`);
  }, [navigate, storeName]);


  return (
    <>
      {cartItems.length ?
        <GridWrapper>
          <div />
          <Content >
            <CheckoutUserInfo />
            <CheckoutCartSummary  />
            <CheckoutExtraOptions />
            <AcceptTermsGoToPayment cartItems={cartItems} />
          </Content>
          <div />
        </GridWrapper>
        :
        <MessageContainer>
          <Typo.TextTitle>{t('webshop.cart.cartIsEmpty')}</Typo.TextTitle>
          <Button $themeColor={userTheme.color} $variant='themed' onClick={goToStore}>{t('webshop.cart.backToShopping')}</Button>
        </MessageContainer>}
    </>
  );
};

export default CheckoutDataContainer;

