import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListTable, Loading, Modal } from '../../ui';
import { fetchBrokendownAnalytics } from '../statistics/statisticsSlice';
import { useTranslation } from 'react-i18next';


const CourseUsageAnalytics = ({ courseToShow, onClose, endDate, startDate }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const [breakdownSession, setBreakdownSession] = useState();
  const [breakdownEvents, setBreakdownEvents] = useState();
  const [breakdownMerged, setBreakdownMerged] = useState();

  useEffect(() => {
    if (courseToShow) {
      dispatch(fetchBrokendownAnalytics({
        courseId: courseToShow.id,
        startDate, endDate,
        dimensions: 'ga:pagePath,ga:eventName',
        metrics: 'ga:eventCount',
        filters: courseToShow.slug,
        sort: '-ga:eventCount'
      }))
        .then((res) =>
          setBreakdownEvents(res.payload)
        )

      dispatch(fetchBrokendownAnalytics({
        courseId: courseToShow.id,
        startDate, endDate,
        dimensions: 'ga:pagePath',
        metrics: 'ga:sessions, ga:averageSessionDuration',
        filters: courseToShow.slug,
        sort: '-ga:averageSessionDuration'
      }))
        .then((res) =>
          setBreakdownSession(res.payload)
        )
    }

  }, [dispatch, startDate, endDate, courseToShow])

  useEffect(() => {
    const newArray = []
    if (breakdownSession && breakdownEvents) {
      breakdownSession.map((item) => {
        const events = breakdownEvents.find(element => element.id === item.id);
        newArray.push({ ...item, gaDataSession: [...item?.gaData], gaDataEvent: [...events?.gaData] })
        return null
      })
    }

    if (newArray.length)
      setBreakdownMerged(newArray)


  }, [breakdownSession, breakdownEvents])

  const tableInfoSales = [
    { label: t('insights.page'), data: (course) => course.sectionName + ' - ' + course.name },
    { label: t('insights.sessions'), data: (course) => course.gaDataSession ? course.gaDataSession[0]?.sessions : '0' },
    {
      label: t('insights.AvTimeSpent'), data: (course) => {

        const convertToMin = (time) => {
          const minutes = Math.floor(time / 60);
          const seconds = Math.floor(time - minutes * 60);
          return `${minutes}.${seconds} min`
        }

        return (course.gaDataSession && course.gaDataSession[0]) ? convertToMin(course.gaDataSession[0].averageSessionDuration) : '0.0 min'
      }
    },
    { label: t('insights.interaction'), data: (course) => course.gaDataEvent && course.gaDataEvent[0]?.eventCount },
    { label: t('insights.type'), data: (course) => course.gaDataEvent && course.gaDataEvent[0]?.eventName },

  ]

  const closeHandle = () => {
    onClose()
    setBreakdownMerged()
    setBreakdownSession()
    setBreakdownEvents()
  }

  return (
    <Modal
      open={!!courseToShow}
      onClose={closeHandle}
      title={t('insights.usageStats', { stats: courseToShow?.name })}
    >
      { !breakdownMerged ? <Loading /> : <ListTable
        tableInfo={tableInfoSales}
        data={breakdownMerged}
        tableName={'usage'}
      />}
    </Modal>

  );
};

export default CourseUsageAnalytics;
