import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import Typo from '../Typo'
import { CONTENT_STANDARD, CONTENT_FULL, CONTENT_MINI, media } from '../../utils/media'

const SectionWrapper = styled.section`
  background-color: ${({ variant }) => variant || 'transparent'};
  position: relative;
  overflow: hidden;
`

const SectionContent = styled.div`
  max-width: ${({ size }) => size !== CONTENT_FULL ? `${size}px` : size};
  display:flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  margin: auto;
  padding: ${({ $noMarginBottom }) => $noMarginBottom ? '40px 0 0' : '40px 0'}; 

  ${media.lessThan('laptop')} {
    padding: ${({ $noMarginBottom, size }) => `40px ${size !== CONTENT_MINI ? '1.5rem' : '0'} ${$noMarginBottom ? '0':''}` }; 
  }

  ${media.lessThan('tablet')} {
    padding: ${({ $noMarginBottom, size }) => `40px 1.5rem ${$noMarginBottom ? '0':''}` }; 
  }
`

const Section = ({
  title,
  variant,
  children,
  align,
  size,
  noMarginBottom
}) => {

  return (
    <SectionWrapper variant={variant} >
      <SectionContent align={align || 'center'} size={size || CONTENT_STANDARD} $noMarginBottom={noMarginBottom}>
        {title && <Typo.SectionHeader $marginBottom='40px' $themeColor={variant} >{title}</Typo.SectionHeader>}
        {children}
      </SectionContent>
    </SectionWrapper>
  );

};

export const NEUTRAL = theme.colors.NEUTRAL_0
export const LIGHT = theme.colors.NEUTRAL_LIGHT
export const DARK = theme.colors.NEUTRAL_PRIMARY

export default Section;

//missing in mobile version to check course content text width
