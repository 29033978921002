import React from 'react';
import { useMemo, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthState } from './features/auth/authSlice';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectSettings } from './features/settings/settingsSlice';

let rendererModule = {
  status: 'pending',
  promise: null,
  result: null,
};

export default function lazyLegacyRoot(getLegacyComponent, customeCss) {
  let componentModule = {
    status: 'pending',
    promise: null,
    result: null,
  };

  return function Wrapper(props) {
    const createLegacyRoot = readModule(rendererModule, () =>
      import('../../legacy/src/createLegacyRoot')
    ).default;
    const Component = readModule(componentModule, getLegacyComponent).default;
    const containerRef = useRef(null);
    const rootRef = useRef(null);
    const authState = useSelector(selectAuthState);
    const settings = useSelector(selectSettings);
    const navigate = useNavigate();
    const location = useLocation();
    const { i18n } = useTranslation();
    const params = useParams();
    const pageId = location.pathname.split('/')[location.pathname.split('/').length - 1];
    location.state = {
      ...location.state,
      'pageId': Number(pageId) ? pageId : null,
      'ownerSlug': location.pathname.split('/')[2],
      'slug': location.pathname.split('/')[3]
    }
    const context = useMemo(() => ({
      language: i18n.language,
      settings,
      authState,
      navigate,
      params,
      location
    }), [authState, location, navigate, params, settings, i18n.language]);

    // Create/unmount.;
    useLayoutEffect(() => {
      if (!rootRef.current) {
        rootRef.current = createLegacyRoot(containerRef.current);
      }
      const root = rootRef.current;
      function compUnmount() {
        root.unmount();
      }
      return () => {
        compUnmount();
      }
    }, [createLegacyRoot]);

    // Mount/update.
    useLayoutEffect(() => {
      if (rootRef.current) {
        rootRef.current.render(Component, props, context);
      }
    }, [Component, props, context]);

    const className = `modern-legacy-bridge ${customeCss ? customeCss : ''}`

    return <div className={className} style={{ height: '100vh' }} ref={containerRef} />;
  };
}

// This is similar to React.lazy, but implemented manually.
// We use this to Suspend rendering of this component until
// we fetch the component and the legacy React to render it.
function readModule(record, createPromise) {
  if (record.status === 'fulfilled') {
    return record.result;
  }
  if (record.status === 'rejected') {
    throw record.result;
  }
  if (!record.promise) {
    record.promise = createPromise().then(
      value => {
        if (record.status === 'pending') {
          record.status = 'fulfilled';
          record.promise = null;
          record.result = value;
        }
      },
      error => {
        if (record.status === 'pending') {
          record.status = 'rejected';
          record.promise = null;
          record.result = error;
        }
      }
    );
  }
  throw record.promise;
}
