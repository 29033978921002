import React from 'react';
import styled from 'styled-components';
import { percentageFormat } from '../../helpers/formatNumbers';
import Typo from '../Typo';

const ProgressOuter = styled.div`
  width: 100%;
  height: 24px;
  background-color: transparent;
  border-radius: 50px;
  border: 3px solid ${(props) => props.theme.colors.PRIMARY};
  display:flex;
  margin-bottom:10px;
`;

const ProgressInner = styled.div`
  width: ${(props) => props.progress > 5 || props.progress === 0 ? props.progress : 5}%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.PRIMARY_LIGHT};
  border-radius: 50px;
`;

const Progress = ({ progress }) => {
  return (
    <ProgressOuter>
      <ProgressInner progress={progress}>
        <Typo.SmallText $textAlign='center'>{percentageFormat(progress, 0)}%</Typo.SmallText>
      </ProgressInner>
    </ProgressOuter>
  )
};

Progress.displayName = 'Progress'

export default Progress;
