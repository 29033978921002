import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Flex, Modal, Typo } from '../../ui';
import { SwiperSlide } from 'swiper/react';
import { selectHasRequiredLevel } from '../auth/authSlice';
import Carousel from '../../ui/Carousel';
import giftCodeImg1 from './assets/feature-gift-code1.png'
import giftCodeImg2 from './assets/feature-gift-code2.png'
import giftCodeImg3 from './assets/feature-gift-code3.png'

const Controls = styled(Flex)` 
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  padding-top:30px;
`

const ImageWrapper = styled.div`
  margin: 0;
  max-width: 600px;

  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

`
const TextWrapper = styled.div`
  padding-bottom:20px;

`

const NewFeatures = ({enabled}) => {
    const [hideMessage, setHideMessage] = useState(false)
    const isTeacher = useSelector((state)=>selectHasRequiredLevel(state, 'teacher'))
    const isEditor = useSelector((state)=>selectHasRequiredLevel(state, 'editor'))
    const show = (isTeacher || isEditor)  && enabled

    //change value so it is unique every time we show a new feature
    const currentProperty = 'downloadGiftCodes';

    useEffect(() => {
      const dontShowNewFeature = JSON.parse(localStorage.getItem('dontShowNewFeature'));
      setHideMessage(dontShowNewFeature && dontShowNewFeature[currentProperty])
  
    }, [setHideMessage])
  
    const onCloseModal = () => {
      setHideMessage(true)
    }

    const onSkipIt = () => {
      localStorage.setItem('dontShowNewFeature', JSON.stringify({ [currentProperty]: true }));
      onCloseModal()
    }

    const CardInfo = ({imageSrc, title, text, alt})=> {
      return (
       
        <Flex maxWidth='600px' direction='column' gap='20' align='flex-start'>
          <ImageWrapper >
            <img alt={alt} src={imageSrc} loading='lazy' />
          </ImageWrapper>
         {title && <TextWrapper>
          <Typo.TextTitle>{title}</Typo.TextTitle>
          <Typo.MediumText dangerouslySetInnerHTML={{ __html: text }}></Typo.MediumText>
          </TextWrapper>}
        </Flex>
      )
    }

  return ( 
    <Modal
      open={!hideMessage && show}
      // onClose={onCloseModal} 
      noBottomMargin={true}
      maxWidth='685px'
    >
      <Carousel
        navigation={false}
      >
        <SwiperSlide key={1}>{CardInfo({imageSrc:giftCodeImg1})}</SwiperSlide>
        <SwiperSlide key={2}>
          {CardInfo({
            imageSrc:giftCodeImg2, 
            alt:'what feature',
            title:'How to find the feature', 
            text:'Naviagte to the menu - Courses - find the course you would like to create gift codes to, and select the three dots on the right side.'
          })}
        </SwiperSlide>
        <SwiperSlide key={3}>
          {CardInfo({
            imageSrc:giftCodeImg3,
            alt:'create gift codes',
            title:'Create gift codes',
            text:'Now you can add the number gift codes you would like to create. You will be presented with an csv file to download'
          })}
        </SwiperSlide>
        <SwiperSlide key={4}>
          {CardInfo({
            imageSrc:giftCodeImg3,
            alt:'how to use it',
            title:'How to use it',
            text:`Share the gift code along with this link <a href='https://app.coursio.com/gift' rel="noopener noreferrer" target='_blank' >https://app.coursio.com/gift</a> <br />Recipients can simply click the "Reclaim Gift" button to add it to their Coursio account or register for one if they don't have an account yet.`
          })}
        </SwiperSlide>
      </Carousel>
      <Controls>
        <Button onClick={onCloseModal} >Remind me later</Button>
        <Button onClick={onSkipIt} >Skip it</Button>
      </Controls>

    </Modal>
  )
};

export default NewFeatures;
