import Reflux from 'reflux';

export default Reflux.createActions([
  'moveToNewSection',
  'overSection',
  'resetOverSection',
  'resetDragging',
  'endDragging',
  'setDraggingPos',
  'openModuleMenu',
  'closeModuleMenu',
  'setFilterCategory',
  'setFilterTag',
  'setFilter',
  'setSearchText',
  'read',
  'readComplete',
  'update',
  'publish',
  'updateComplete',
  'toggleFilterMode',
  'setDiscountCode',
  'start',
  'findBySlug',
  'findBySlugComplete',
  'addCart',
  'addToCart',
  'clearCart',
  'startPayment',
  'completePayment',
  'paymentCompleted',
  'paymentStarted',
  'setPaymentMethod',
  'clearPaymentMethod',
  'resetPayment',
  'reset',
  'clearSelected',
  'editMode',
  'listMode',
  'addModule',
  'selectSection',
  'selectModule',
  'unselect',
  'moveSectionUp',
  'moveSectionDown',
  'moveModuleLeft',
  'moveModuleRight',
  'updateModule',
  'updateModuleDebounced',
  'updateSection',
  'setDialogMode',
  'deleteModule',
  'undo',
  'changeStoreItem',
  'pushStoreItem',
  'pushStoreItemComplete',
  'discard',
  'setDiscountCode',
  'setGiftEmail',
  'clearError'
]);
