import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Typo, Modal, Icon, Flex, Button, theme } from '../../../ui';
import { fetchUserById, fetchUserStatistics, fetchUserGroups, selectUserById, updateUserRoles } from '../userSlice';
import User from './User';
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectFeatures } from '../../settings/settingsSlice';
import Teacher from './Teacher';

const Clickable = styled.div`
  cursor: pointer;
  display:flex;
`

const UserModal = ({ open, isTeacher }) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showRoles, setShowRoles] = useState(false);
  const features = useSelector(selectFeatures)
  const user = useSelector((state) => selectUserById(state, userId));

  const onClose = () => {
    navigate(-1)
    setShowRoles(false)
  }

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserById({ id: userId }))
      dispatch(fetchUserStatistics({ userId }))
      dispatch(fetchUserGroups({ userId }))
    }
  }, [dispatch, userId]);

  useEffect(() => {
    user?.roles && setSelectedRoles(user?.roles)
  }, [user])

  const rolesOptions = [
    { name: t('roles.assistant'), value: 'assistant', id: 0, icon: 'Assistant', notEditable: true },
    { name: t('roles.tester'), value: 'tester', id: 1, icon: 'Lab' },
    { name: t('roles.owner'), value: 'owner', id: 2, icon: 'Owner', notEditable: true },
    { name: t('roles.editor'), value: 'editor', id: 3, icon: 'CreateEdit' },
    { name: t('roles.accountant'), value: 'accountant', id: 4, icon: 'Accountant' },
    { name: t('roles.teacher'), value: 'teacher', id: 5, icon: 'Teacher' },
    { name: t('roles.reader'), value: 'reader', id: 6, icon: 'User', notEditable: true }]

  const handleSelection = (value) => {
    const currentIndex = selectedRoles.indexOf(value.value);
    const newChecked = [...selectedRoles];

    if (currentIndex === -1) {
      newChecked.push(value.value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedRoles(newChecked);
  }

  const saveRoles = () => {
    dispatch(updateUserRoles({ id: user.id, roles: selectedRoles }))
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      titleHasLine
      width='800px'
      titleChildren={<>
        <Typo.ContentSmallHeader $textAlign='left'>{user?.name}</Typo.ContentSmallHeader>
        <Flex gap='10'>
          <Typo.Info $caps={true} fontWeight={'600'} >{t(`roles.${selectedRoles[0]}`)}</Typo.Info>
          {features.addTeacherToCourse && <Clickable><Icon.Settings size='x-small' color={theme.colors.NEUTRAL_60} onClick={() => setShowRoles(!showRoles)} /></Clickable>}
        </Flex>
        <div hidden={!showRoles}>
          <List>
            {rolesOptions.map((value) => {
              const labelId = `checkbox-list-secondary-label-${value.id}`;
              const ModuleIcon = Icon[value.icon]
              return <ListItem
                key={value.id}
                disablePadding
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleSelection(value)}
                    checked={selectedRoles.includes(value.value)}
                    inputProps={{ 'aria-labelledby': labelId }}
                    disabled={value.notEditable}
                  />
                }
              >
                <ListItemButton onClick={() => !value.notEditable ? handleSelection(value) : console.log("Can't change this value")}>
                  <ListItemIcon><ModuleIcon /></ListItemIcon>
                  <Typo.SmallText>{t(`roles.${value.name}`)}</Typo.SmallText>
                </ListItemButton>
              </ListItem>
            })}
          </List>
          <Flex justify='flex-end'>
            <Button onClick={saveRoles}>{t('globals.save')}</Button>
          </Flex>
        </div>
      </>
      }
    >
      <div>
        {!!isTeacher ? <Teacher user={user} /> : <User user={user} />}
      </div>
    </Modal >

  );
};

export default UserModal;
