import {
  createSlice,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { fetchStore } from './globalSlice'
import { addItemGa } from '../../helpers/GATracker';

// ----------------- Thunks -----------------------------


// ----------------- Reducers ---------------------------
const cartItemsAdapter = createEntityAdapter()

const initialState = {
  validDiscount: false,
  ids: [],
  entities: {},
  cartVisible: false,
  hasDiscountApplied: false
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart(state, action) {
      if (Array.isArray(action.payload)) {
        cartItemsAdapter.setMany(state, action.payload)
      } else {
        cartItemsAdapter.setOne(state, action.payload)
        //GA
        addItemGa({
          id: action.payload.id,
          name: action.payload.name,
          price: action.payload.price.discounted,
          quantity: 1
        })
        state.cartVisible = true;
      }
    },

    removeItemFromCart(state, action) {
      cartItemsAdapter.removeOne(state, action.payload)
    },
    clearCart(state) {
      cartItemsAdapter.removeAll(state)
    },
    setCartVisible(state, action) {
      state.cartVisible = action.payload
    },
    setIsValidDiscount(state, action) {
      state.validDiscount = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStore.fulfilled, (state, action) => {
        const items = action.payload.items
        const itemWithPrice = items.find((item) => item?.price.gross > 0)
        state.hasDiscountApplied = itemWithPrice?.price.gross > itemWithPrice?.price.discounted
      })
  }
})

export const { addItemToCart, removeItemFromCart, clearCart, setIsValidDiscount, setCartVisible } = cartSlice.actions

export default cartSlice.reducer


// ----------------- Selectors --------------------------
export const {
  selectAll: selectAllItems,
  selectById: selectItemById,
  selectTotal: selectCartItemsCount,
  selectIds: selectCartItemsIds,
} = cartItemsAdapter.getSelectors((state) => state.webshop.cart)


export const selectCartVisible = state => state.webshop.cart.cartVisible;

const returnType = (state, type) => type || 'gross'
export const selectCartItemsSum = createSelector(
  [selectAllItems, returnType],
  (cartItems, type) => cartItems.reduce((a, b) => a + b?.price[type], 0)
)

export const selectIsValidDiscount = (state) => state.webshop.cart.hasDiscountApplied
