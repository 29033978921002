//Helper for images
//Use this to get perfect measurements for the wrapping element.
//If we use <Figure.Circle> in a div with 400px width we will get a circular image that is 400x400px
//Size is controlled through the parent wrapper
//If you want a smaller image, lower the width of the parent wrapper 


import styled from 'styled-components';

const Figure = {
  Rectangle: styled.figure`
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
    max-height: ${(props) => props.$maxHeight};

    &:before {
      content: '';
      display: block;
      padding-top: 60%;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: ${(props) => props.$objectfit || 'cover'};
    }
    `,
  Video: styled.figure`
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
    //React-player creates 2 wrapping divs. This breaks the figure so we fix it by making the divs absolute
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }

    video, iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    `,
  Landscape: styled.figure`
    width: 100%;
    position: relative;
    margin: 0;

    &:before {
      content: '';
      display: block;
      padding-top: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: ${(props) => props.$objectfit || 'cover'};
    }
    `,
  Portrait: styled.figure`
    width: 100%;
    position: relative;
    margin: 0;

    &:before {
      content: '';
      display: block;
      padding-top: 125%;
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    `,
  Square: styled.figure`
    width: 100%;
    position: relative;
    margin: 0;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img, video, iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: ${(props) => props.objectfit || 'cover'};
    }
    `,
  Circle: styled.figure`
    width: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin: 0;
    box-shadow: ${(props) => props.shadow && props.theme.shadows.BOX_SHADOW};
    background-color: ${(props) => props.background && props.background};

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img, video, iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: ${(props) => props.$objectfit || 'cover'};
    }
    `,
};

export default Figure;
