import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section, Symbols } from '../../ui';
import { logOut, selectIsLoggedIn } from '../auth/authSlice';
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ifLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    dispatch(logOut())
  }, [dispatch])

  useEffect(() => {
    if (!ifLoggedIn) {
      navigate('/login', { replace: true })
    }
  }, [ifLoggedIn, navigate])

  return (
    <Section><Symbols.Loading /></Section>
  );
}

export default Logout;
