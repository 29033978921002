import React from 'react';
import styled from 'styled-components'
import { Chip } from '@mui/material';
import theme from '../theme';

const ChipStyled = styled(Chip)`
  &.MuiButtonBase-root{
    border-radius: 4px;
  }
  .MuiChip-label{
    font-family:${theme.fonts.TEXT_FONT}, sans-serif;
    color:${theme.colors.NEUTRAL_PRIMARY};
    font-weight: 300;
    line-height: 18px;
    font-size: ${(props) => props.fontSize || theme.fontSizes.small};
  }
`

const PillTag = ({ label, variant, fontSize, ...props }) => {
  return (
    <ChipStyled
      variant={variant || 'fill'}
      fontSize={fontSize}
      label={label}
      {...props}
    />
  );
};

export default PillTag;
