/* global gapi*/
import Config from '../Config';
import SteelCompass from '../services/SteelCompass';
import AuthActions from '../actions/Auth';

class Google {
  signIn() {
    if (this.auth && this.user) {
      const token = this.user.getAuthResponse().id_token;
      if (token) {
        return new Promise((resolve, reject) => {
          this.login(token, response => {
            if (response.isError) {
              reject(response.errMsg);
            } else {
              resolve(response.data);
            }
          });
        });
      }
    } else {
      return this.load();
    }
  }

  login(idToken, callback) {
    return SteelCompass.request('auth', 'loginGoogle', { idToken }).then(
      data => {
        if (data) {
          AuthActions.loginSuccess(data);
        } else {
          AuthActions.loginError();
        }
      },
      error => {
        AuthActions.loginError(error);
      }
    );
  }

  signOut() {
    this.auth && this.auth.signOut().then(() => {
      this.user = null;
      this.auth = null;
    });
  }

  load() {
    return new Promise((resolve, reject) => {
      gapi.load('auth2', () => {
        gapi.auth2
          .init({
            client_id: Config.googleClientId,
            // cookiepolicy: 'single_host_origin',
            fetch_basic_profile: true
          })
          .then(
            auth => {
              const user = auth.currentUser.get();
              this.auth = auth;
              this.user = user;
              auth.signIn().then(
                googleUser => {
                  const idToken = googleUser.getAuthResponse().id_token;
                  this.login(idToken).then(
                    response => {
                      resolve(response);
                    },
                    error => {
                      reject(error);
                    }
                  );
                },
                error => {
                  reject(error);
                  // error
                }
              );
            },
            error => {
              reject(error);
            }
          );
      });
    });
  }
}

export default new Google();
