import Reflux from 'reflux';

const MediaLibraryActions = Reflux.createActions([
  'moveFiles',
  'listDirectory',
  'createDirectory',
  'find',
  'refresh',
  'findById',
  'findComplete',
  'genericError',
  'filter',
  'setFilter',
  'resetFilter',
  'setSort',
  'resetSort',
  'delete',
  'deleteComplete',
  'toggleStyle',
  'clearSelected',
  'getFileInfo',
  'getFileInfoComplete',
  'toggleMode'
]);

export default MediaLibraryActions;
