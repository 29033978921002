const stringSort = (arr, prop) =>
  [...arr].sort((a, b) => a[`${prop}`].localeCompare(b[`${prop}`]));

const dateSort = (arr, prop) =>
  [...arr].sort((a, b) => Date.parse(a[`${prop}`]) - Date.parse(b[`${prop}`]));

const numberSort = (arr, prop) =>
  [...arr].sort((a, b) => a[`${prop}`] - b[`${prop}`]);

const lengthSort = (arr, prop) =>
  [...arr].sort((a, b) => a[`${prop}`].length - b[`${prop}`].length);

const stringSortReverse = (arr, prop) =>
  [...arr].sort((a, b) => b[`${prop}`].localeCompare(a[`${prop}`]));

const dateSortReverse = (arr, prop) =>
  [...arr].sort((a, b) => Date.parse(b[`${prop}`]) - Date.parse(a[`${prop}`]));

const numberSortReverse = (arr, prop) => [...arr].sort((a, b) => b[`${prop}`] - a[`${prop}`]);

const lengthSortReverse = (arr, prop) =>
  [...arr].sort((a, b) => b[`${prop}`].length - a[`${prop}`].length);


// const statusSort = (arr, info) => {
//   const { propVal, nest } = info;
//   return [...arr].sort((a, b) => {
//     const first = a[`${nest}`][0][`${propVal}`];
//     const second = b[`${nest}`][0][`${propVal}`];
//
//     if (first === 'QUIZ_SUCCESS' && second === 'QUIZ_SUCCESS' || first === 'QUIZ_FAIL' && second === 'QUIZ_FAIL') {
//       return 0;
//     } else if (first === 'QUIZ_SUCCESS' && second === 'QUIZ_FAIL') {
//       return -1;
//     } else {
//       return 1;
//     }
//   })
// }

export const handleSort = ({ sortedArr, resultSetter, tableSetter, additionalData, listDataProp, sorting, order }) => {
  if (sortedArr && resultSetter && tableSetter) {

    let sortedData;
    const { sortedMethod, propVal } = sorting;
    if (order === 'asc') {
      switch (sortedMethod) {
        case 'string':
          sortedData = stringSort(sortedArr, propVal);
          break;
        case 'number':
          sortedData = numberSort(sortedArr, propVal);
          break;
        case 'date':
          sortedData = dateSort(sortedArr, propVal);
          break;
        case 'length':
          sortedData = lengthSort(sortedArr, propVal);
          break;
        default:
          break;
      }
    } else {
      // sortedData = [...sortedArr].reverse();
      switch (sortedMethod) {
        case 'string':
          sortedData = stringSortReverse(sortedArr, propVal);
          break;
        case 'number':
          sortedData = numberSortReverse(sortedArr, propVal);
          break;
        case 'date':
          sortedData = dateSortReverse(sortedArr, propVal);
          break;
        case 'length':
          sortedData = lengthSortReverse(sortedArr, propVal);
          break;
        default:
          break;
      }
    }
    if (additionalData) {
      resultSetter({ ...additionalData, [listDataProp]: sortedData })
    } else {
      resultSetter(sortedData)
    }
  }
};

// const courseQuizTable = (title) => {
//   switch (title) {
//     case 'User':
//       return {sortedMethod: stringSort, propVal: 'name'};
//     case 'Finished':
//       return {sortedMethod: dateSort, propVal: 'endTime', nest: 'statistics'};
//     case 'Status':
//       return {sortedMethod: numberSort, propVal: 'status', nest: 'statistics'};
//     case 'Score':
//       return {sortedMethod: numberSort, propVal: 'score', nest: 'statistics'};
//     case 'Duration':
//       return {sortedMethod: numberSort, propVal: 'duration', nest: 'statistics'};
//     default:
//       break;
//   }
// }
