import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'
import CtaButton from '../CtaButton';
import { Typo, Figure, Flex, Button } from '../../../../../ui';
import { media, CONTENT_MINI } from '../../../../../utils/media';
import { imageChecker } from '../../../../../helpers/imageChecker';
import FaddedText from './FaddedText';
import ShowAccessTime from '../ShowAccessTime';

const ButtonStyled = styled(Button)`
  display:${({ $hideText}) => $hideText ? 'block' : 'none'};
`
const ButtonsWrapper = styled.div`
  display:flex;
  gap:10px;
  flex-direction:column;
  width:100%;
`

const CourseInfo = styled.div`
  flex: ${({ $showFull }) => $showFull ? '0 1 100%' : '0 1 50%'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ $showFull }) => $showFull ? '0' : '0 0 0 50px'};
  align-items: ${({ $showFull }) => $showFull ? 'center' : 'flex-start'};

  ${media.lessThan('tablet')} {
    padding: ${({ $showFull }) => $showFull ? '0': '40px 0 0'};
    margin-right: 0;
  }
`

const TextWrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap: 10px;
  position:relative;
`

const Wrapper = styled.div`
  display:flex;
  width: 100%;
  max-width:${({ $showFull }) => $showFull ? `${CONTENT_MINI}px` : '100%'};
  position: relative;
  z-index: 1;
  margin-bottom: ${({ $hasBottomMargin }) => !!$hasBottomMargin && '1.5rem'};

  ${media.lessThan('tablet')} {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`

const ImageContainer = styled.div`
  flex: 0 1 50%;
  max-width: 560px;
  width: 100%;
  height: 100%;

  ${media.lessThan('tablet')} {
    max-width: 100%;
  }
`

const MainDescription = ({ info, hasBottomMargin, ucpPeriod, courseId, infoIcons, addItemHandler }) => {
  
  const ref = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const [infoHeight, setInfoHeight] = useState(0)
  const [heightLimit, setHeightLimit] = useState(0)
  const [hideText, setHideText] = useState(true)

  useEffect(() => {
    setInfoHeight(ref3?.current?.clientHeight + ref2?.current?.clientHeight + ref?.current?.clientHeight + 20)
  }, [ref3.current?.clientHeight, ref.current?.clientHeight, ref2.current?.clientHeight])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHeightLimit(400 - ref2?.current?.clientHeight - ref?.current?.clientHeight - 20)
  }, [ ref2?.current?.clientHeight, ref.current?.clientHeight])// eslint-disable-line react-hooks/exhaustive-deps
  
  const onReadMore = ()=>{
    setHideText(!hideText)
  }

  return (
    <Wrapper $hasBottomMargin={hasBottomMargin} $showFull={!info?.image}>
      {info?.image && <ImageContainer>
          <Figure.Rectangle>
            <img id={info?.image?.id} src={imageChecker(info)} alt="courseImage" loading='lazy' />
          </Figure.Rectangle>
          <CtaButton courseId={courseId} addItemHandler={addItemHandler}/>
        </ImageContainer>
      }
      <CourseInfo $showFull={!info?.image}>
        <Flex gap={10} direction='column' align='flex-start' width='100%'>
          <TextWrapper >
            <Typo.ContentSmallHeader $textAlign='left' ref={ref2} >{info?.title}</Typo.ContentSmallHeader>
            <FaddedText $ref={ref3} infoText={info?.text} hideText={hideText} infoIcons={infoIcons} exceedLimit={infoHeight > 400} heightLimit={heightLimit} />
          </TextWrapper>
          <ButtonsWrapper ref={ref} >
            <ButtonStyled $hideText={hideText && infoHeight > 400} onClick={onReadMore}  $asText={true}>{'read more >'}</ButtonStyled>
            <ButtonStyled $hideText={!hideText} onClick={onReadMore} $asText={true}>{'read less <'}</ButtonStyled>
            {ucpPeriod && <ShowAccessTime ucpPeriod={ucpPeriod} />}
          </ButtonsWrapper>
        </Flex>
        {!info?.image && <CtaButton courseId={courseId} />}
      </CourseInfo>
    </Wrapper>
  );
};

export default MainDescription;
