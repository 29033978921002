import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components'

import {
  List,
  Box,
  Drawer,
  Divider,
  ListItemIcon,
  ListItemButton,
  Toolbar,
  ListSubheader,
} from '@mui/material';

import { Icon, theme, Typo, Flex } from '../../ui';
import { selectAuthState, selectIsAdmin, selectIsIncarnated, selectMyStore, wakeUp } from '../../features/auth/authSlice';
import { selectPaymentType, setPaymentType } from '../../features/webshop/globalSlice';
import { setIncarnateModal, setMenuSliderToggle } from '../../features/global/globalSlice';
import { drawerWidth, MODULES_LIST } from '../../utils/constants';
import { selectUnreadNotifications } from '../../features/notifications/notificationsSlice';

const ListItemButtonStyled = styled(ListItemButton)`
  &.Mui-selected{
    background-color:${theme.colors.COURSIO_LIGHT} !important;
  }
`

const ItemDescription = styled(Typo.MediumText)`
  font-weight:400;
`

const FlexStyled = styled(Flex)`
  margin:1rem 0 0.5rem;
`

function ResponsiveMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [isClosing, setIsClosing] = useState(false);

  // const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedIndex = 0

  const myStore = useSelector(selectMyStore);

  const authState = useSelector(selectAuthState);
  const { roles } = authState?.userInfo || {};
  const unreadNotifications = useSelector(selectUnreadNotifications);

  const isAdmin = useSelector(selectIsAdmin);
  const isIncarnated = useSelector(selectIsIncarnated);
  const paymentType = useSelector(selectPaymentType);
  const isBasicUser = roles && roles[0] === 'reader';



  const handleDrawerClose = (event) => {

    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(setMenuSliderToggle(false))
    // setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    // setIsClosing(false);
  };

  // const handleDrawerToggle = () => {
  //   if (!isClosing) {
  //     setMobileOpen(!mobileOpen);
  //   }
  // };

  const handleListItemClick = useCallback((index, to) => {
    navigate(to)
  }, [navigate])


  const handleTestPayment = useCallback(() => {
    dispatch(setPaymentType(paymentType === 9 ? 6 : 9))
  }, [dispatch, paymentType]);

  const openIncarnationModal = useCallback(() => {
    dispatch(setIncarnateModal(true))
  }, [dispatch]);

  const wakeUpFromIncarnation = useCallback(() => {
    dispatch(wakeUp())
  }, [dispatch])

  const handleSupportLink = useCallback(() => {
    // const link = lang === 'sv' ? 'https://support.coursio.com/hc/sv' : 'https://support.coursio.com/hc/en-us';
    let supportLink = document.createElement('a')
    supportLink.href = 'https://support.coursio.com/hc/sv'
    supportLink.target = '_blank';
    supportLink.rel = 'noopener noreferrer';
    supportLink.click();
  }, [])

  const handleLogOut = useCallback(() => {
    navigate('/logout')
    // dispatch(logOut());
  }, [navigate])

  const ListItem = useCallback((item) => {
    const ModuleIcon = Icon[item.icon]
    return (<ListItemButtonStyled key={item.index}
      selected={selectedIndex === item.index}
      onClick={() => {
        const url = typeof item.url === 'string' ? item.url : item.url(myStore)
        return handleListItemClick(item.index, url)
      }}
    >
      <ListItemIcon><ModuleIcon color={theme.colors.PRIMARY} /></ListItemIcon>
      <ItemDescription>{item.label(t, unreadNotifications)}</ItemDescription>
    </ListItemButtonStyled >)

  }, [selectedIndex, t, handleListItemClick, unreadNotifications, myStore]);

  const subModuleRender = sectionName => {
    return Object.keys(MODULES_LIST).map((key) => {
      const item = MODULES_LIST[key]
      if (ifSameRoles(roles, MODULES_LIST[key].accessRights))
        return item.section === sectionName && ListItem(item)

      return null
    })
  }
  const subSectionRender = (action, icon, description) => {
    const PassedIcon = Icon[icon];
    return (
      <ListItemButtonStyled onClick={action}>
        <ListItemIcon>
          <PassedIcon color={theme.colors.PRIMARY} />
        </ListItemIcon>
        <ItemDescription>{description}</ItemDescription>
      </ListItemButtonStyled>
    )
  }

  const sectionRender = (sectionName, withoutSectionName) => {
    return (
      withoutSectionName ?
        <List key={sectionName}>{subModuleRender(sectionName)}</List>
        :
        <Fragment key={sectionName}>
          <Divider />
          <List subheader={
            <ListSubheader>
              <FlexStyled gap='5' align='center' >
                <Typo.ContentSmallHeader color={theme.colors.COURSIO}>:</Typo.ContentSmallHeader>
                <Typo.ContentSmallHeader>{t(`menu.${sectionName}`)}</Typo.ContentSmallHeader>
              </FlexStyled>
            </ListSubheader>
          }>
            {subModuleRender(sectionName)}
          </List>
        </Fragment>
    )
  };

  const ifSameRoles = (roles, givenRoles) => roles?.filter(el => givenRoles.indexOf(el) >= 0).length > 0;


  const renderPanelItems = (roles) => {
    let panelItems = [];
    for (let key in MODULES_LIST) {
      if (ifSameRoles(roles, MODULES_LIST[key].accessRights)) {
        panelItems = [...panelItems, {
          section: MODULES_LIST[key].section,
          withoutSectionName: MODULES_LIST[key].withoutSectionName
        }]
      }
    }
    return [...new Map(panelItems.map(v => [v.section, v])).values()]
  }

  const drawer = (
    <>
      <Toolbar />
      {roles && renderPanelItems(roles).map(item => sectionRender(item.section, item.withoutSectionName))}
      <Divider />
      <List>
        {(isAdmin || isIncarnated) &&
          <>
            {subSectionRender(handleTestPayment, 'ToggleOn', `Payments with: ${paymentType === 9 ? 'Klarna' : 'Stripe'}`)}
            {!isIncarnated && subSectionRender(openIncarnationModal, 'Fingerprint', t('globals.incarnate'))}
            {isIncarnated && subSectionRender(wakeUpFromIncarnation, 'Yoga', t('auth.wakeUp'))}
          </>
        }
      </List>
      <List>
        {isBasicUser && subSectionRender(handleSupportLink, 'Support', t('globals.support'))}
        {subSectionRender(handleLogOut, 'Logout', t('auth.logout'))}
      </List>
    </>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >

        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            width: drawerWidth,
            flexShrink: 0,

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

    </>
  );
}

export default ResponsiveMenu;
