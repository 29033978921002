import React from 'react';
import ImageUploader from '../../features/media/uploaders/ImageUploader';
import CourseImageWrapper from './CourseImageWrapper';
import CourseCardWrapper from './CourseCardWrapper';
import CourseInfoWrapper from './CourseInfoWrapper';
import Flex from '../Flex';
import Figure from '../Figure';

const CourseCardEdit = ({ saveImage, onSaveInfo, course, label }) => {

  return (
    <Flex width='50%' justify='center' breakPoint={{ size: 'tablet', value: 'width:100%;' }}>
      <CourseCardWrapper >
        <CourseImageWrapper >
          <ImageUploader
            type={'single'}
            id={'courseEdit'}
            saveAction={saveImage}
            imageToPreview={course?.coverImage?.url}
            label={label}
            iconName='Add'
          >
            {course?.coverImage?.url && <Figure.Rectangle><img alt={'imageToPreview'} src={course?.coverImage?.url} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
        </CourseImageWrapper>
        <CourseInfoWrapper
          course={course}
          editMode
          saveCourseInfo={onSaveInfo}
          ownerAvatar={course?.theme?.logoUrl}
          ownerColor={course?.theme?.color}
        />
      </CourseCardWrapper>
    </Flex >
  );
};

export default CourseCardEdit;
