import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex, SubPage, Textinput, Typo, Button } from '../../../ui';
import { CONTENT_MINI } from '../../../utils/media';
import { selectSettings, updateSettings } from '../settingsSlice';

const ButtonStyled = styled(Button)`
  align-self: flex-end; 
`

const Tracking = () => {
  const { t } = useTranslation();
  const codesRef = useRef({});
  const dispatch = useDispatch();

  const settings = useSelector(selectSettings)

  useEffect(() => {
    codesRef.current['analytics'].value = settings?.ga && settings?.ga?.id;
    codesRef.current['ga4'].value = (settings?.ga && settings?.ga?.ga4) || '';
    codesRef.current['tags'].value = settings?.ga && settings?.ga?.gtm;
    codesRef.current['facebook'].value = settings?.js && settings?.js?.pixel;
  }, [settings])

  const saveCodes = () => {
    const { analytics, tags, facebook, ga4 } = codesRef.current;

    const codes = { ga: { id: analytics.value, gtm: tags.value, ga4: ga4.value }, js: { pixel: facebook.value } }
    dispatch(updateSettings({ settingsData: codes }))

  }

  return (
    <SubPage size={CONTENT_MINI}>
      <Flex direction='column' align='flex-start' gap='30' width='100%'>
        <Typo.ContentSmallHeader>{t('globals.codes')}</Typo.ContentSmallHeader>

        <Textinput
          label={t('globals.GA')}
          autoFocus
          propName={'analytics'}
          inputRef={ref => codesRef.current['analytics'] = ref}
        />
        <Textinput
          label={t('globals.Gtag')}
          autoFocus
          propName={'tags'}
          inputRef={ref => codesRef.current['tags'] = ref}
        />
        <Textinput
          label={t('globals.GA4')}
          autoFocus
          propName={'ga4'}
          inputRef={ref => codesRef.current['ga4'] = ref}
        />
        <Textinput
          label={t('globals.pixel')}
          autoFocus
          propName={'facebook'}
          inputRef={ref => codesRef.current['facebook'] = ref}
        />
        <ButtonStyled onClick={saveCodes}>{t('globals.save')}</ButtonStyled>
      </Flex>
    </SubPage>
  );
};

export default Tracking;
