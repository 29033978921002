import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { enqueueSnackbar } from 'notistack';
import httpClient from '../../services/httpClient';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_INFO } from '../../utils/constants';
import { diplomaApi } from '../../utils/urls';

// ----------------- Thunks -----------------------------

export const checkDiplomaInfo = createAsyncThunk('diploma/checkDiplomaInfo', async ({ code }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { code },
    method: 'validateUniqueCode'
  };

  const res = await httpClient.post(diplomaApi(), body, getState, dispatch, rejectWithValue);
  return res.data;
})

export const getDiplomaList = createAsyncThunk('diploma/getDiplomaList', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {},
    method: 'list'
  };

  const res = await httpClient.post(diplomaApi(), body, getState, dispatch, rejectWithValue);
  return res.data;
})
export const downloadDiploma = createAsyncThunk('diploma/downloadDiploma', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { courseId },
    method: 'download'
  };

  const res = await httpClient.post(diplomaApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const emailDiploma = createAsyncThunk('diploma/emailDiploma', async ({ courseId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { courseId },
    method: 'mail'
  };

  const res = await httpClient.post(diplomaApi(), body, getState, dispatch, rejectWithValue);
  const message = res.isError ? res.errMsg : 'Diploma will be send to your email'
  const variant = res.isError ? TOAST_ERROR : TOAST_INFO
  enqueueSnackbar(message, { variant })
  return res.data;
})


// ----------------- Reducers ---------------------------
const diplomasAdapter = createEntityAdapter()

const initialState = {
  status: 'idle',
  info: {},
  list: {
    ids: [],
    entities: {}
  }
}

const diplomaSlice = createSlice({
  name: 'diploma',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(checkDiplomaInfo.pending, (state, action) => {
        state.status = STATUS_LOADING;
        state.info = {}
      })
      .addCase(downloadDiploma.pending, (state, action) => {
        enqueueSnackbar('Preparing diploma for download', { variant: TOAST_INFO })
      })
      .addCase(checkDiplomaInfo.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(checkDiplomaInfo.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.info = action.payload
      })
      .addCase(getDiplomaList.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        diplomasAdapter.setAll(state.list, action.payload)
      })
      .addCase(getDiplomaList.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(getDiplomaList.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
  }
})

// export const {  } = diplomaSlice.actions

export default diplomaSlice.reducer

// ----------------- Selectors --------------------------
export const {
  selectAll: selectDiplomaList,
  selectById: selectDiplomaById,
} = diplomasAdapter.getSelectors((state) => state.diploma.list)

export const selectStatus = state => state.diploma.status;
export const selectDiploma = state => state.diploma.info;

