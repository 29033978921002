import React from 'react';

import { FormControl,  RadioGroup } from '@mui/material';

const RadioButtonGroup = ({ handleChange, selectedValue, children, orientation, fullWidth }) => {

  return (
    <FormControl fullWidth={fullWidth}>
      <RadioGroup
        row={orientation === 'row'}
        aria-labelledby="demo-form-control-label-placement"
        name="position"
        defaultValue="end"
        onChange={handleChange}
        value={selectedValue}
      >
        {children}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButtonGroup;
