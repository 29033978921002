import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { searchApi } from '../../utils/urls';
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const fetchCircles = createAsyncThunk('search/fetchCircles', async (_, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { sortBy: 'name', sortMode: 'asc' },
    method: 'filterCircles'
  };

  const res = await httpClient.post(searchApi(), body, getState, dispatch, rejectWithValue);

  return res.isError ? { error: res.isError, message: res.errMsg } : res.data;
});

export const fetchUserCourses = createAsyncThunk('search/fetchUserCourses', async ({ userId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { userId, sortBy: 'name', sortMode: 'asc' },
    method: 'filterCourses'
  };

  const res = await httpClient.post(searchApi(), body, getState, dispatch, rejectWithValue);

  return res.isError ? { error: res.isError, message: res.errMsg } : res.data;
});

// ----------------- Reducers ---------------------------

const initialState = {
  data: {}
};

const searchSlice = createSlice({
  name: 'section',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(fetchCircles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.circles = action.payload;
      })
  }
})

export default searchSlice.reducer

// ----------------- Selectors --------------------------
export const selectCircles = state => state.search.circles;

