import React from 'react';
import { theme, Page, PageHeaderLogo, PageContainerVCenter, PageBoxContent } from '../../ui';
import SupportLink from '../../features/auth/components/SupportLink';
import GiftForm from './GiftForm';

const ReclaimGift = () => {

  return (
    <Page backgroundColor={theme.colors.PRIMARY} fullheight={true}>
      <PageHeaderLogo />
      <PageContainerVCenter>
        <PageBoxContent>
          <GiftForm />
          <SupportLink />
        </PageBoxContent>
      </PageContainerVCenter >
    </Page>
  );
}

export default ReclaimGift;
