import React, { useMemo } from 'react';
import styled from 'styled-components'
import coursioImg from '../assets/coursio-gray.png';
import powredbyImg from '../assets/Poweredby.png';
import Typo from '../Typo';
import { media } from '../../utils/media'
import { theme } from '..';
import { getDarkerThemeColor } from '../theme/colors';
import { useTranslation } from 'react-i18next';


const Footerwrapper = styled.footer`
  background-color: ${(props) => props.$colorBg};
  height: ${(props) =>  props.theme.footerSize};
  position:relative;
  display:flex;
  ${media.lessThan('tablet')} {
    flex-direction: column;
  }
`
const CopyRights = styled(Typo.Info)`
  width:100%;
  align-self:center;

`
const LogoImage = styled.img.attrs(props => ({
  src: props.src,
}))`
  display:block;
  margin:auto;
  padding: 0.25rem;
`;

const CoursioWrapper = styled.div`
  align-self:center;

  ${media.greaterThan('tablet')} {
    flex-direction: column;
    position:absolute;
    right: 0rem;
    bottom: 0rem;
    margin: 1.1rem 0.8rem;
  }
`
const Footer = ({
  ownerName,
  currentYear,
  themeColor
}) => {

  const { t } = useTranslation();
  const colorBg = useMemo(() => getDarkerThemeColor(themeColor), [themeColor]);

  return (
    <Footerwrapper $colorBg={colorBg}>
      {ownerName && <CopyRights color={theme.colors.NEUTRAL_0} $textAlign='center' >{t('globals.copyright', { currentYear: currentYear, ownerName: ownerName })}</CopyRights>}
      <CoursioWrapper>
        <LogoImage src={powredbyImg} />
        <LogoImage src={coursioImg} />
      </CoursioWrapper>
    </Footerwrapper>
  );
};

Footer.displayName = 'Footer'

export default Footer;
