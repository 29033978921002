import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typo, Page, ListTable, IconAsButton } from '../../ui';
import { fetchTransactions, resendReceipt, selectTransactions } from './transactionsSlice';
import { API_STATUS } from '../../utils/constants';
import { selectIsIncarnated } from '../auth/authSlice';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../ui/Page/PageHeader';
import PageControls from '../../ui/Page/PageControls';

const Transactions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const isIncarnated = useSelector(selectIsIncarnated);
  const [searchQuery, setSearchQuery] = useState('');
  const [localTransactions, setLocalTransactions] = useState(null);
  const transactions = useSelector(selectTransactions)

  const resend = useCallback((id) => {
    dispatch(resendReceipt(id))
  }, [dispatch])

  const [tableInfo, setTableInfo] = useState([
    { label: t('transactions.buyerName'), hasSorting: { sortedMethod: 'string', propVal: 'buyer' }, data: (item) => item.buyer },
    { label: t('globals.course'), hasSorting: { sortedMethod: 'string', propVal: 'caption' }, data: (item) => item.caption },
    { label: t('transactions.amount'), data: (item) => `${item.amount} ${item.currency}` },
    { label: t('globals.date'), hasSorting: { sortedMethod: 'date', propVal: 'date' }, data: (item) => item.date && item.date.split(' ')[0] },
    // { label: 'where' },
    {
      label: t('globals.status'), hasSorting: { sortedMethod: 'number', propVal: 'status' }, data: (item) => item.status === API_STATUS.STATUS_REFUND
        ? t('transactions.refunded')
        : item.status === API_STATUS.STATUS_PAID || API_STATUS.STATUS_GOOD
          ? t('transactions.paid')
          : item.status === API_STATUS.STATUS_ERROR
            ? t('globals.error')
            : '-'
    },
    { label: t('transactions.resendReceipt'), data: (item) => <IconAsButton iconName='Receipt' tooltipLabel={t('transactions.resendReceipt')} clickHandle={() => resend(item.id)} /> },
  ]);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch, isIncarnated])

  useEffect(() => {
    if (transactions?.length) {
      setLocalTransactions(transactions)
    }
  }, [transactions])

  const handleSearch = useCallback((value) => {
    setLocalTransactions(transactions.filter((el) => el.buyer.toLowerCase().includes(value)))
    setSearchQuery(value)
  }, [transactions, setSearchQuery]);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    transactions?.length && setLocalTransactions(transactions);
  }, [setSearchQuery, transactions]);

  return (
    <Page staticElements={
      <PageHeader >
        <PageControls
          onClearSearch={clearSearch}
          onSearch={handleSearch}
          placeholder={t('transactions.search')}
          disableClear={!searchQuery}
        />
      </PageHeader>
    }>
      {transactions.length ?
        <ListTable
          tableInfo={tableInfo}
          data={localTransactions}
          tableName={'transactions'}
          // resultSetter={setLocalTransactions}
          tableSetter={setTableInfo}
        />
        : <Typo.TextTitle>{t('transactions.noTransactions')}</Typo.TextTitle>}
    </Page>
  );
};

export default Transactions;
