import Reflux from 'reflux';

const UserActions = Reflux.createActions([
  'findReaders',
  'findReadersComplete',
  'select',
  'addToCircle',
  'addToCircleComplete',
  'setCircleRole',
  'setCircleRoleComplete',
  'listPwned',
  'listPwnedComplete',
  'course',
  'courseComplete'
]);

export default UserActions;
