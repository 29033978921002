/* eslint-disable-next-line */
/*global __TARGET__*/
let Config;

if (process.env.__TARGET__ === 'd') {
    Config = {
        thisHost: '//d-app.s.coursio.com',
        discountHost: '//d-app.s.coursio.com',
        coursioWebsite: '//coursio.com',
        steelCompassRoot: 'https://d-api.s.coursio.com/api/',
        zenCoderRoot: 'https://app.zencoder.com/api/v2/',
        soundCloudRoot: 'http://soundcloud.com/',
        facebookPixelId: '456322654879473',
        googleAnalyticsId: 'UA-806038-20',
        googleClientId:
            '315357729136-oofnelcssgsgoohbnh8227esjhidfgvo.apps.googleusercontent.com',
        taxamoPublicKey: 'public_test_9LuwgbbG3kF3C6SYVXAeBAz7x0JJ8CKAiBYyJ13cUxc',
        intercomAppId: null,
        hotjarId: 553600
    };
} else if (process.env.__TARGET__ === 't' || process.env.REACT_APP_STAGE === 'dev') {
    Config = {
        thisHost: '//t-app.s.coursio.com',
        discountHost: '//t-app.s.coursio.com',
        steelCompassRoot: 'https://t-api.s.coursio.com/api/',
        soundCloudRoot: 'http://soundcloud.com/',
        zenCoderRoot: 'https://app.zencoder.com/api/v2/',
        coursioWebsite: '//coursio.com',
        facebookPixelId: '456322654879473',
        googleAnalyticsId: 'UA-806038-20',
        googleClientId:
            '315357729136-oofnelcssgsgoohbnh8227esjhidfgvo.apps.googleusercontent.com',
        taxamoPublicKey: 'public_test_9LuwgbbG3kF3C6SYVXAeBAz7x0JJ8CKAiBYyJ13cUxc',
        intercomAppId: 't73jspsv',
        hotjarId: 560124
    };
} else if (process.env.__TARGET__ === 'a') {
    Config = {
        thisHost: '//a-app.s.coursio.com',
        discountHost: '//a-app.s.coursio.com',
        steelCompassRoot: 'https://a-api.s.coursio.com/api/',
        soundCloudRoot: 'http://soundcloud.com/',
        zenCoderRoot: 'https://app.zencoder.com/api/v2/',
        coursioWebsite: '//coursio.com',
        facebookPixelId: '456322654879473',
        googleAnalyticsId: 'UA-806038-20',
        googleClientId:
            '315357729136-oofnelcssgsgoohbnh8227esjhidfgvo.apps.googleusercontent.com',
        taxamoPublicKey: 'public_OdI2FdbEYcL8v4TxGuoeIpQI3Z2qAzO5EwquVkaHrOk',
        intercomAppId: 'chwsmww4',
        hotjarId: 560125
    };
} else if (process.env.__TARGET__ === 'p' || process.env.REACT_APP_STAGE === 'prod') {
    Config = {
        thisHost: '//app.coursio.com',
        discountHost: '//app.coursio.com',
        steelCompassRoot: 'https://api-3.coursio.com/api/',
        soundCloudRoot: 'http://soundcloud.com/',
        zenCoderRoot: 'https://app.zencoder.com/api/v2/',
        coursioWebsite: '//coursio.com',
        googleAnalyticsId: 'UA-806038-20',
        googleClientId:
            '315357729136-oofnelcssgsgoohbnh8227esjhidfgvo.apps.googleusercontent.com',
        facebookPixelId: '456322654879473',
        taxamoPublicKey: 'public_OdI2FdbEYcL8v4TxGuoeIpQI3Z2qAzO5EwquVkaHrOk',
        intercomAppId: 'chwsmww4',
        hotjarId: 553600,
        stripeKey: 'pk_live_cJIzjNJJArhwBq1nS1KURscP'
    };
} else {
    Config = {
        thisHost: '//localhost:3000',
        discountHost: '//localhost:3000',
        coursioWebsite: '//coursio.com',
        steelCompassRoot: 'https://t-api.s.coursio.com/api/',
        zenCoderRoot: 'https://app.zencoder.com/api/v2/',
        soundCloudRoot: 'http://soundcloud.com/',
        facebookPixelId: '456322654879473',
        googleAnalyticsId: 'UA-806038-20',
        googleClientId:
            '315357729136-oofnelcssgsgoohbnh8227esjhidfgvo.apps.googleusercontent.com',
        taxamoPublicKey: 'public_test_9LuwgbbG3kF3C6SYVXAeBAz7x0JJ8CKAiBYyJ13cUxc',
        intercomAppId: null,
        hotjarId: 560124,
        stripeKey: 'pk_test_OApJrN5kCO4e2bx43zGNLDIG'
    };
}

export default Config;
