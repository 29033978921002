import Reflux from 'reflux';

const InvitationActions = Reflux.createActions([
  'showInfo',
  'showInfoComplete',
  'genericError',
  'findByGroupId',
  'findByCourseId',
  'findByGroupIdComplete',
  'findByCourseIdComplete',
  'delete',
  'deleteComplete',
  'resend',
  'resendComplete',
  'check',
  'checkGiftCode',
  'accept',
  'acceptGift',
  'checkComplete',
  'acceptComplete',
  'getInvitationEmailStatus',
  'getInvitationEmailStatusComplete',
  'countByCourseId',
  'validateUniqueCode'
]);

export default InvitationActions;
