import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { enqueueSnackbar } from 'notistack'
import { groupApi } from '../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED, TOAST_ERROR, TOAST_SUCCESS } from '../../utils/constants';
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------


export const fetchEditableGroups = createAsyncThunk('groups/fetchEditableGroups', async ({ disableCounts }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { disableCounts },
    method: 'findBuildable'
  };

  const res = await httpClient.post(groupApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})

export const fetchGroupById = createAsyncThunk('groups/fetchGroupById', async ({ id, withCounts }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { withCounts, id },
    method: 'findById'
  };

  const res = await httpClient.post(groupApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
})
export const createGroup = createAsyncThunk('group/createGroup', async (name, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { name },
    method: 'create'
  };

  const res = await httpClient.post(groupApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Group created with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
})

export const deleteGroup = createAsyncThunk('group/deleteGroup', async (id, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: { id },
    method: 'delete'
  };

  const res = await httpClient.post(groupApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Group deleted with success'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
})

export const addGroupToCourse = createAsyncThunk('group/addGroupToCourse', async ({ circleId, ucpPeriod, courseIds }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: { circleId, ucpPeriod, courseIds },
    method: 'addToCourse'
  };

  const res = await httpClient.post(groupApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully added course(s) to group.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;

});

export const removeGroupFromCourse = createAsyncThunk('group/removeGroupFromCourse', async ({ courseId, circleId }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      courseId,
      circleId
    },
    method: 'removeFromCourse'
  };

  const res = await httpClient.post(groupApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully removed course from group.'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});
export const updateGroupName = createAsyncThunk('group/updateGroupName', async ({ id, name }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: {
      id,
      name
    },
    method: 'update'
  };

  const res = await httpClient.post(groupApi(), body, getState, dispatch, rejectWithValue);

  const message = res.isError ? res.errMsg : 'Successfully updated group name'
  const variant = res.isError ? TOAST_ERROR : TOAST_SUCCESS
  enqueueSnackbar(message, { variant })

  return res.data;
});

// ----------------- Reducers ---------------------------
const groupsAdapter = createEntityAdapter()

const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    status: 'idle',
    ids: [],
    entities: {},
    createStatus: 'idle',
    newlyCreated: {}
  },
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchEditableGroups.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchEditableGroups.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchEditableGroups.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        groupsAdapter.setAll(state, action.payload)
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.createStatus = STATUS_FAILED;
      })
      .addCase(createGroup.pending, (state, action) => {
        state.createStatus = STATUS_LOADING;
        state.newlyCreated = {}
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.createStatus = STATUS_SUCCEEDED;
        state.newlyCreated = action.payload
        groupsAdapter.setOne(state, action.payload)
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        groupsAdapter.removeOne(state, action.meta.arg)
      })
      .addCase(updateGroupName.fulfilled, (state, action) => {
        groupsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload })
      })
      .addCase(fetchGroupById.fulfilled, (state, action) => {
        groupsAdapter.setOne(state, action.payload)
      })

  }
})

//   export const { } = groupsSlice.actions

export default groupsSlice.reducer

// ----------------- Selectors --------------------------
export const {
  selectAll: selectGroups,
  selectById: selectGroupById,
} = groupsAdapter.getSelectors((state) => state.groups);
export const selectStatus = state => state.groups.status;
export const selectCreateStatus = state => state.groups.createStatus;
export const selectNewlyCreated = state => state.groups.newlyCreated;
