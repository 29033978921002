import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { sectionApi } from '../../utils/urls';
import httpClient from "../../services/httpClient";

// ----------------- Thunks -----------------------------

export const updateSection = createAsyncThunk('course/fetchCountByCourseId', async ({ sectionData }, { getState, dispatch, rejectWithValue }) => {
  const body = {
    data: sectionData,
    method: 'update'
  };

  const res = await httpClient.post(sectionApi(), body, getState, dispatch, rejectWithValue);

  return res.isError ? { error: res.isError, message: res.errMsg } : res.data;
});

// ----------------- Reducers ---------------------------

const initialState = {
  data: {}
};

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(updateSection.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateSection.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // state.section = action.payload;
      })
  }
})

export default sectionSlice.reducer

// ----------------- Selectors --------------------------
// export const selectSection = state => state.section.data;

