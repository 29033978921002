import React from 'react';
import styled from 'styled-components'

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  // height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const CourseImageWrapper = ({ children }) => {

  return (
    <ImageWrapper>
      {children}
    </ImageWrapper>
  );
};

export default CourseImageWrapper;
