import Reflux from 'reflux';

export default Reflux.createActions([
  'changeDir',
  'gridMode',
  'listMode',
  'read',
  'readComplete',
  'changeSearchFilter'
]);
