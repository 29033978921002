import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import { setLoginModal, selectLoginModal } from '../../global/globalSlice';
import { Modal, PageHeaderLogo } from '../../../ui';

const LoginModal = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loginOpen = useSelector(selectLoginModal);
  const [forgotPass, setForgotPass] = useState(false)

  const closeLoginModal = useCallback(() => {
    dispatch(setLoginModal(false));
  }, [dispatch]);

  return (
    <Modal
      onClose={closeLoginModal}
      open={loginOpen}
      titleChildren={
        <PageHeaderLogo message={forgotPass ? t('auth.forgotPassword') : t('auth.signIn')} lightBackground />
      }
    >
      {forgotPass ?
        <ForgotPassword onCancel={() => setForgotPass(false)} noHeader />
        : <Login onForgotPass={() => setForgotPass(true)} noHeader onLogginCallBack={closeLoginModal} />}

    </Modal>
  )
}

export default LoginModal;

