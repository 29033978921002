import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary, Switch } from '@mui/material';
import { Button, Flex, Icon, Modal, PageHeaderLogo, Typo } from '..';
import { useTranslation } from 'react-i18next';

const Banner = styled.div`
  z-index:900;
  bottom:0;
  position:fixed;
  background: #ffffffdb;
  box-shadow: ${(props) => props.theme.shadows.BOX_SHADOW_INVERTED};
  width:100%;
  display: ${({ $visible }) => $visible ? 'block' : 'none'}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 100%;
  gap: 0px;
  flex-wrap: nowrap;
  margin: 15px;
`

const CookiesBanner = ({ visible, accept }) => {

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false)

  const [expanded, setExpanded] = useState(false);
  const [cookiesLevel, setCookiesLevel] = useState({ necessary: true, functional: false, analitycs: false });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleSettingsModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleSelectedPreferences = useCallback((event, cookies) => {
    event.stopPropagation(); //this is not working
    const level = !cookiesLevel[cookies]
    setCookiesLevel({ ...cookiesLevel, [cookies]: level })
  }, [cookiesLevel])

  const saveSelection = () => {
    accept(cookiesLevel)
    toggleSettingsModal()
  }

  const saveAll = ({ closeModal }) => {
    accept({ necessary: true, functional: true, analitycs: true })
    closeModal && toggleSettingsModal()
  }

  return (
    <>
      <Banner $visible={visible}>
        <Wrapper >
          <Typo.MediumText>{t('cookies.mainMessage')}</Typo.MediumText>
          <Flex gap='10'>
            <Button onClick={saveAll}>{t('cookies.accept')}</Button>
            <Button $variant='basic' onClick={toggleSettingsModal} >{t('cookies.settings')}</Button>
          </Flex>
        </Wrapper>
      </Banner>
      <Modal
        onClose={toggleSettingsModal}
        open={modalOpen} // make if visible is false this is false too
        titleChildren={
          <PageHeaderLogo message={'About cookies on this site'} lightBackground />
        }
      >
        <Flex direction='column' gap='20'>
          <Typo.Text>
            Cookies used on the site are categorized and below you can read about each category and allow or deny some or all of them.
            When categories than have been previously allowed are disabled, all cookies assigned to that category will be removed from your browser.
            Additionally you can see a list of cookies assigned to each category and detailed information in the cookie declaration.
          </Typo.Text>
          <Flex direction='column' align='flex-start' width='100%'>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary >
                <Flex gap='10' align='center' width='100%'>
                  <Icon.ExpandMore />
                  <Typo.TextTitle>Strictly Necessary Cookies (always active)</Typo.TextTitle>
                </Flex>
              </AccordionSummary>
              <AccordionDetails>
                <Typo.MediumText>These cookies are necessary for the website to function and cannot be switched off in our systems.
                They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences,
                logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.
                  These cookies do not store any personally identifiable information.</Typo.MediumText>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary >
                <Flex gap='10' align='center' width='100%'>
                  <Icon.ExpandMore />
                  <Flex justify='space-between' width='100%'>
                    <Typo.TextTitle>Functional Cookies</Typo.TextTitle>
                    <Switch
                      checked={cookiesLevel['functional']}
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={(event) => handleSelectedPreferences(event, 'functional')}
                    />
                  </Flex>
                </Flex>
              </AccordionSummary>
              <AccordionDetails>
                <Typo.MediumText>These cookies are set to support website functionality and features, as well as your preferences - such as selected currency, region, and language.</Typo.MediumText>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary  >
                <Flex gap='10' align='center' width='100%'>
                  <Icon.ExpandMore />
                  <Flex justify='space-between' width='100%'>
                    <Typo.TextTitle>Performance & analytics cookies</Typo.TextTitle>
                    <Switch
                      checked={cookiesLevel['analitycs']}
                      onChange={(event) => handleSelectedPreferences(event, 'analitycs')}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Flex>
                </Flex>
              </AccordionSummary>
              <AccordionDetails>
                <Typo.MediumText>These cookies help us understand and improve the use and performance of our services including what links visitors clicked on the most, and how they interact</Typo.MediumText>
              </AccordionDetails>
            </Accordion>

          </Flex>
          <Flex width='100%' justify='flex-end' gap='10'>
            <Button onClick={() => saveAll({ closeModal: true })}>{t('cookies.accept')}</Button>
            <Button onClick={saveSelection} $variant='basic' >{t('cookies.saveChanges')}</Button>
          </Flex>
        </Flex>

      </Modal>
    </>
  );
}

export default CookiesBanner;
