import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Figure, Flex, Modal, Textinput, Typo } from '../../../../ui';
import ImageUploader from '../../../media/uploaders/ImageUploader';

const EditMainDescription = ({ open, info, onClose, addToPreview }) => {

  const { t } = useTranslation();
  
  const [mainDescriptionInfo, setMainDescriptionInfo] = useState();

  useEffect(() => {
    setMainDescriptionInfo(info)
  }, [info])
 
  const editHighlightedInfo = useCallback(e => {
    setMainDescriptionInfo({ ...mainDescriptionInfo, [e.target.dataset.propname]: e.target.value });
  }, [mainDescriptionInfo]);

  const addImageToHighlightedInfo = useCallback(image => {
    setMainDescriptionInfo({ ...mainDescriptionInfo, image: image?.url, })
  }, [mainDescriptionInfo])

  const onAccept = useCallback(() => {
    addToPreview(mainDescriptionInfo)
  }, [mainDescriptionInfo, addToPreview]);

  return (
    <Modal
      open={open} 
      showActions={true}
      onClose={onClose}
      onCancel={onClose}
      onAccept={onAccept}
      acceptLabel='Add to preview'
      width='80%'
    >
      <Flex direction='column' width='100%' align='flex-start' gap='10'>
        <Flex direction='column' gap='10' align='flex-start'>
          <Typo.ContentSmallHeader>{t('webshop.build.highlightedInfoTitle')}</Typo.ContentSmallHeader>
          <Typo.Text>{t('webshop.build.highlightedInfoDescription')}</Typo.Text>
        </Flex>
        <Flex width='100%' gap='20' breakPoint={{ size: 'phablet', value: 'flex-direction:column;' }} >
          <Flex auto direction='column' width={'100%'}>
            <Textinput
              key={`title-main-description`}
              label={t('globals.headline')}
              propName={'title'}
              defaultValue={mainDescriptionInfo?.title}
              onBlurValue={editHighlightedInfo}
            />
            <Textinput
              label={t('globals.description')}
              propName={'text'}
              defaultValue={mainDescriptionInfo?.text}
              onBlurValue={editHighlightedInfo}
              isMultiline
            />
          </Flex>
          <ImageUploader
            saveByDelete={true}
            type={'highlighted'}
            id={'highlightedInfo'}
            saveAction={addImageToHighlightedInfo}
            imageToPreview={mainDescriptionInfo?.image}
          >
            {mainDescriptionInfo?.image && <Figure.Rectangle ><img alt={'imageToPreview'} src={mainDescriptionInfo?.image} loading='lazy' /></Figure.Rectangle>}
          </ImageUploader>
        </Flex>
      </Flex>
    </Modal> 
  );
};

export default EditMainDescription;
