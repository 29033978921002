import React from 'react';
// import { useTranslation } from 'react-i18next';
import CheckboxButton from '../../../../ui/CheckboxButton';
import { List, ListItem } from '@mui/material';
import { IconAsButton, Typo } from '../../../../ui';


const EditPackageCourseSequence = ({ checked, onChange, courses, resortItems, sequence }) => {
  // const { t } = useTranslation();
  // const dispatch = useDispatch();

  const moveItem = (elementId, direction) => {
    if (['up', 'down'].includes(direction) === false) return false

    const order = [...sequence]
    const index = order.indexOf(elementId)
    order.splice(index, 1)

    if (direction === 'down')
      order.splice(index + 1, 0, elementId)
    else if (direction === 'up')
      order.splice(index - 1, 0, elementId)

    resortItems(order)
  }

  return (
    <>
      <CheckboxButton
        labelText="Open courses sequentially"
        checked={checked}
        handleChange={onChange}
      />
      <Typo.Info $marginBottom="10px">
        This allows you to set the order in which courses will be available to students after they complete each course.
      </Typo.Info>
      { !checked ? <></> :  !sequence || sequence.length < 2 ? <Typo.Info $marginBottom="10px">Select at least two courses to be able to set a sequence</Typo.Info>
      : <>
          <Typo.TextTitle>Set order of appearance of the courses</Typo.TextTitle>
          <List>
            {sequence.map((id, index) => {
              const courseToUse = courses.find(course => course.id === id);

              if (!courseToUse) return null;

              return (
                <ListItem key={courseToUse.id}>
                  <Typo.Text>{courseToUse.name} - {courseToUse.id}</Typo.Text>
                  <IconAsButton
                    disabled={index === 0}
                    tooltipLabel="Move up"
                    iconName="ArrowUp"
                    clickHandle={() => moveItem(courseToUse.id, 'up')}
                  />
                  <IconAsButton
                    disabled={index === sequence.length - 1}
                    tooltipLabel="Move down"
                    iconName="ArrowDown"
                    clickHandle={() => moveItem(courseToUse.id, 'down')}
                  />
                </ListItem>
              );
            })}
          </List>
        </>
      }
    </>
  );
}

export default EditPackageCourseSequence;
