import Reflux from 'reflux';

const StudentActions = Reflux.createActions([
  'findReaders',
  'findReadersComplete',
  'fetchAll',
  'filter',
  'filterComplete',
  'genericError',
  'setFilter',
  'resetFilter',
  'setSort',
  'resetSort',
  'findByGroupId',
  'findByGroupIdComplete',
  'findByCourseId',
  'findByCourseIdComplete',
  'findById',
  'findByIdComplete',
  'delete',
  'removeFromCourse',
  'removeFromGroup',
  'removeFromCourseComplete',
  'removeFromGroupComplete',
  'removeTag',
  'removeTagComplete',
  'addTag',
  'addTagComplete',
  'export',
  'exportComplete',
  'update',
  'updateComplete',
  'countByCourseId',
  'expel',
  'expelComplete',
  'exportStatistic',
  'exportStatisticComplete'
]);

export default StudentActions;
