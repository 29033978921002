import React from 'react';
import styled from 'styled-components'
import Typo from '../Typo';
import Figure from '../Figure';
import { media } from '../../utils/media'
import Button from '../Button';
import { useTranslation } from 'react-i18next';

const CardWrapper = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  overflow: hidden;

  ${media.lessThan('tablet')} {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const ImageWrapper = styled.div`
  width:100%;
  flex-shrink: 1;
  box-shadow: ${(props) => props.theme.shadows.BOX_SHADOW};

  ${media.greaterThan('tablet')} {
    max-width:552px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0 1rem 4rem;
  width:100%;
  flex-shrink: 2;

  button {
    margin-top: 1rem;
  }

  ${media.lessThan('tablet')} {
    align-items: flex-start;
    text-align: left;
    padding: 2rem 0rem;
    p {
      text-align:left;
    }
  }
`

const SpotlightCourseCard = ({ course, goToCoursePage, themeColor }) => {
  const { t } = useTranslation();
  return (
    course ? <CardWrapper>
      <ImageWrapper>
        {course.coverImage && course.coverImage[0]?.url && <Figure.Rectangle>
          <img src={course.coverImage[0].url} alt='course cover' loading='lazy' />
        </Figure.Rectangle>}
      </ImageWrapper>
      <InfoWrapper className="course-text" >
        <Typo.ContentBigHeader $themeColor={themeColor} $marginBottom='1rem' $textAlign='left'>{course.name}</Typo.ContentBigHeader>
        <Typo.Text $themeColor={themeColor}>{course.description}</Typo.Text>
        <Button $themeColor={themeColor} $variant='themed' onClick={goToCoursePage} >
          {t('webshop.seeCourse')}
        </Button>
      </InfoWrapper>
    </CardWrapper>
      : <div />
  );
};

export default SpotlightCourseCard;
