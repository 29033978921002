import { Tooltip } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Flex, Icon, Typo, theme, IconAsButton } from '../../../ui';
import { useDrop } from 'react-dnd'
import { useSelector } from 'react-redux';
import { selectFeatures } from '../../settings/settingsSlice';

const Content = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:240px;
  border-radius:14px;
  
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div > i { margin-right: 0.5rem; }
`;

export const ItemTypes = {
  All: 'all',
}

const DropableFolder = ({ data, openFolder }) => {

  const features = useSelector(selectFeatures)


  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.All,
    drop: () => (data),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const isActive = canDrop && isOver

  let backgroundColor = theme.colors.NEUTRAL_LIGHT
  if (isActive) {
    backgroundColor = theme.colors.NEUTRAL_20;
  } else if (canDrop) {
    // backgroundColor = 'darkkhaki'
  }

  const deleteFolder = (e) => {
    e.preventDefault();
    e.stopPropagation()
  }

  return (
    <Tooltip title={data.name} >
      <Content ref={drop} style={{ backgroundColor }} onClick={() => openFolder(data)} >
        <Flex justify='space-between' width='100%'>
          <Flex>
            <Icon.Folder size='small' color={theme.colors.NEUTRAL_60} />
            <Typo.SmallText>{data.name}</Typo.SmallText>
          </Flex>
          {!!features.deleteMediaFolders && <IconAsButton
            aria-label="delete"
            id="delete-button"
            clickHandle={deleteFolder}
            iconName='Delete'
            size='small'
          />}
        </Flex>
      </Content>
    </Tooltip>
  )
}

export default DropableFolder;
