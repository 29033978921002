import Reflux from 'reflux';

const PageModuleActions = Reflux.createActions([
  'markModuleSaving',
  'savePageModule', //
  'savePageModuleComplete', //
  'savePageModuleUserData',
  'savePageModuleUserDataComplete',
  'deletePageModule', //
  'changePageModuleMode', //
  'changePageModule'
]);

export default PageModuleActions;
