
import React from 'react'
import styled from 'styled-components'
import { CONTENT_STANDARD, media } from '../../utils/media';

const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.NEUTRAL_20};
  margin: 0 auto;

  ${media.greaterThan('tablet')} {
    max-width: 488px;
  }
`
const StyledSection = styled.div`
  max-width:${CONTENT_STANDARD}px;
  margin: auto;
  padding: 0;

  ${media.lessThan('laptop')} {
    padding: 0 1.5rem;
  }
`

const SectionSeparation = () => <StyledSection><BorderLine /></StyledSection>

export default SectionSeparation;
