import React from 'react';
import styled from 'styled-components';
import { Modal, Loading } from '../../../ui';

const ListWrapper = styled.div` 
flex-direction:column;
  gap:30px; 
  align:flex-start; 
  width:100%;

  .CourseTasksTesultsList{
    > :first-child{
      z-index:2 !important;
    }
  }
  & .MuiAccordionSummary{
    gap:15px;
  }
`
const TaskModal = ({ children, title, onClose, open }) => {

  return (
    <Modal
      id='TaskResult'
      open={open}
      onClose={onClose}
      title={title}
      minWidth='600px'
      compactOnMobile={true}
    >
      <ListWrapper>{!children ? <Loading /> : children}</ListWrapper>
    </Modal>
  );
};

export default TaskModal;
