import React from 'react';
import styled from 'styled-components'

import { Section } from '../../../../ui'
import InfoPointCard from '../../../../ui/InfoPointCard';
import { media } from '../../../../utils/media';

const InfoPointsGrid = styled.div`
  width:100%;  
  /* Grid Fallback */
  display: ${({ $showGrid }) => !$showGrid ? 'flex' : 'grid'};
  flex-wrap: wrap;
  grid-template-columns: ${({ $showGrid }) => !$showGrid ? null : 'repeat(3, 1fr)'};
  grid-gap: ${({ $showGrid }) => !$showGrid ? null : '2rem 6rem'};
  justify-content:center;
  gap:${({ $showGrid }) => !$showGrid ? '8rem' : null};

  ${media.lessThan('tablet')} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem 5rem;
  }

  ${media.lessThan('phablet')} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem 4rem;
  }
`
const InfoPointsSection = ({ items, title, refContent }) => {
  return (
    <>
      {!!items.length && <Section title={title} align='center' >
        <InfoPointsGrid $showGrid={items.length > 2} ref={refContent} >
          {items.map((item, i) =>
            <InfoPointCard
              infoBlock={item}
              key={i}
            />
          )}
        </InfoPointsGrid>
      </Section>}
    </>
  );
};

export default InfoPointsSection;
