import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { selectAllItems, selectCartItemsSum, selectIsValidDiscount } from '../../cartSlice';
import { fetchStore, selectCurrentStoreHideVat, selectDiscountCode, setDiscountCode } from '../../globalSlice';
import { Typo, Flex, theme } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import { useWebshopName } from "../../../../hooks/readFromUrls";
import CartItemsList from './CartItemsList';
import { currencyFormatter } from '../../../../helpers/formatNumbers';

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
  margin: 0 0 1rem;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.3rem 0;
  width:50%;
  
  &.discount {
    margin: 0;
    p { color: ${(props) => props.theme.colors.ACTION}; }  
  }
`;

const RemoveWrapper = styled(Typo.SmallText)`
  cursor: pointer;
  padding-bottom: 1rem;
  text-decoration: underline;
  align-self:flex-start;
`;

const CheckoutCartSummary = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeHideVat = useSelector(selectCurrentStoreHideVat);
  const cartItems = useSelector(selectAllItems);
  const discountCode = useSelector(selectDiscountCode);
  const isValidDiscount = useSelector(selectIsValidDiscount);
  const cartItemsGrossSum = useSelector((state) => selectCartItemsSum(state, 'gross'));
  const cartItemsNetSum = useSelector((state) => selectCartItemsSum(state, 'net'));
  const cartItemsDiscountedSum = useSelector((state) => selectCartItemsSum(state, 'discounted'));
  const webshop = useWebshopName();

  const [storeCurrency, setStoreCurrency] = useState('');

  useEffect(() => {
    !!cartItems?.length && !storeCurrency && setStoreCurrency(cartItems[0]?.currency)
  }, [cartItems, storeCurrency])

  const clearDiscount = useCallback(() => {
    dispatch(setDiscountCode(null));
    dispatch(fetchStore({ store: webshop }))
  }, [dispatch, webshop])

  return (
      <Flex direction='column' width='100%' align='flex-start' >
        <CartItemsList cartItems={cartItems} storeHideVat={storeHideVat} />
        <Summary>
          {isValidDiscount &&
            <Flex width='100%' direction='column' align='flex-end'>
              <Typo.TextTitle>{currencyFormatter(cartItemsGrossSum, {symbol: storeCurrency})} </Typo.TextTitle>
              <Flex width='100%' justify='space-between' align='flex-end'>
                <Typo.TextTitle color={theme.colors.ACTION} >{t('webshop.checkout.discountCode')}: {discountCode}</Typo.TextTitle>
                <Typo.TextTitle $textAlign='right' color={theme.colors.ACTION}>-{currencyFormatter((cartItemsGrossSum - cartItemsDiscountedSum), {symbol: storeCurrency})}</Typo.TextTitle>
              </Flex>
              <RemoveWrapper onClick={clearDiscount}>
                {t('webshop.checkout.removeDiscount')}
              </RemoveWrapper>
            </Flex>
          }
          <Flex align='flex-end' direction='column'>
            <PriceWrapper>
              <Typo.TextTitle>{t('globals.total')}</Typo.TextTitle>
              <Typo.TextTitle>{currencyFormatter(cartItemsDiscountedSum, {symbol: storeCurrency})} </Typo.TextTitle>
            </PriceWrapper>
            {cartItemsDiscountedSum > 0 && <Flex justify='space-between' width='50%'>
              <Typo.SmallText>{t('globals.vatIncluded')} </Typo.SmallText>
              <Typo.SmallText>{currencyFormatter((cartItemsGrossSum - cartItemsNetSum), {symbol: storeCurrency})} </Typo.SmallText>
            </Flex>}
          </Flex>
        </Summary>
      </Flex>
  );
};

export default CheckoutCartSummary;
