import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

import { useSelector, useDispatch } from 'react-redux';
import {
  fetchStore,
  setStoreName,
  selectDiscountCode,
} from '../webshop/globalSlice';
import { selectAuthState, setIsIncarnated } from '../auth/authSlice';
import FooterContainer from './Footer';
import { fetchUnreadNotifications } from '../../features/notifications/notificationsSlice';

import { fetchSettings, fetchStoreCurrency } from '../settings/settingsSlice';
import { useModule, useWebshopName } from '../../hooks/readFromUrls';
import { useNoHeaderModules } from '../../hooks/noHeaderModules';
import { AppBar, Box, Toolbar } from '@mui/material';
import ResponsiveMenu from '../../components/ResponsiveMenu';
import IncarnateModal from '../../components/IncarnateModal';
import SendInvite from '../invites/components/SendInvite';
import LoginModal from '../auth/components/LoginModal';
import WarningMessages from './WarningMessages';
import ManageCookiesBanner from '../manageCookiesBanner';
import { Typo } from '../../ui';
import { drawerWidth } from '../../utils/constants';
import { useNoMenuModules } from '../../hooks/noMenuModules';


const ContentWrapp = styled.div`
  min-height: ${(props) => props.$noHeader ? '100vh' : `calc(100vh - ${props.theme.headerSize})`} ;
  width:100%;
`

const FixedMenuContainer = ({ children }) => {

  const dispatch = useDispatch();
  const [incarnated, setIncarnated] = useState(false)

  const authState = useSelector(selectAuthState);
  const module = useModule();
  const noHeader = useNoHeaderModules();
  const noMenu = useNoMenuModules();
  const webshop = useWebshopName()
  const discountCode = useSelector(selectDiscountCode);


  useEffect(() => {
    if (authState.loggedIn) {
      dispatch(fetchStoreCurrency())
      dispatch(fetchUnreadNotifications())
      dispatch(fetchSettings())
    }
  }, [dispatch, authState]);

  useEffect(() => {
    if (module === 'store' || module === 'store_edit') {
      dispatch(setStoreName(webshop))
      dispatch(fetchStore({ store: webshop, discountCode }))
    }
  }, [webshop, discountCode, dispatch, module])

  useEffect(() => {
    const isIncarnated = JSON.parse(localStorage.getItem('user'))?.incarnated
    if (isIncarnated && !incarnated) {
      setIncarnated(true)
      dispatch(setIsIncarnated(true))
    }
  }, [dispatch, incarnated])

  return (

    <Box sx={{ display: 'flex' }}>
      <IncarnateModal />
      <SendInvite />
      <LoginModal />
      {/* <Cart /> */}
      <WarningMessages show={false && !(noHeader || module === 'store')} />

      {!noMenu && <ResponsiveMenu />}
      {/* <ScrollToTop /> */}
      <Box
        component="main"
        sx={{ flexGrow: 1 }}
      >
        {!noHeader && <>
          <AppBar position="fixed" sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` }, zIndex: 1200,
            backgroundColor: "white",
            boxShadow: "#a5a5a5 0px 16px"
          }} >
            <Toolbar><Typo.Text>New Top bar 2 </Typo.Text></Toolbar>
          </AppBar>
          <Toolbar />
        </>}
        <ContentWrapp $noHeader={noHeader} >
          {children}
        </ContentWrapp>
        {module === 'store' && <FooterContainer />}

      </Box>
      <ManageCookiesBanner />
    </Box>
  );
};

export default FixedMenuContainer;
