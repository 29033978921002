import React, { useMemo, useRef } from 'react';
import styled from 'styled-components'
import { CONTENT_WIDE, media } from '../../utils/media'
import theme from '../theme';
import { Flex, Button, IconAsButton } from '..';
import Typo from '../Typo';
import { useTranslation } from 'react-i18next';

const StoreLogoWrapper = styled.div`
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  position: absolute;
  margin:  ${(props) => `0 calc(50% - ${props.$elemWidth}px / 2)`};
  z-index:2;
`
const Headerwrapper = styled.header`
  max-height: ${(props) => props.theme.headerSize};
  height:100%;
  position:fixed;
  width:100%;
  top:0;
  left:0;
  background-color: ${(props) => props.$background || props.theme.colors.NEUTRAL_0};
  box-shadow: ${({ theme, $inStore }) => $inStore ? theme.shadows.BOX_SHADOW : `0px 10px ${theme.colors.PRIMARY}`};
  z-index: 1199;
`
const HeaderBar = styled.div`
  position:absolute;
  top:0;
  width:100%;
  max-width:${CONTENT_WIDE}px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;  
  padding: 1rem 0.5rem;
  display:flex;
  justify-content:space-between;
  align-items:center;
  height:100%;

  ${media.lessThan('desktop')} {
    padding: 1rem;
  }

  ${media.lessThan('mobile')} {
    padding: 0.5rem;
  }
`

const LogoImage = styled.img.attrs(props => ({
  src: props.src,
}))`
  max-height: 55px;
  max-width: 200px;
  object-fit: contain;

  ${media.greaterThan('tablet')} {
    max-width: 290px;
  }
  ${media.lessThan('mobile')} {
    max-width: 132px;
  }
`;

const Infowrapper = styled.div `
  margin-left:56px;
`

const Header = ({
  cartItems,
  openCart,
  headerLogo,
  goToHome,
  ownerName,
  loggedIn,
  openLoginModal,
  inStore,
  bgColor,
  title,
}) => {

  const { t } = useTranslation();

  const logoRef = useRef(null)
  const elemWidth = useMemo(() => logoRef?.current?.offsetWidth, [logoRef?.current?.offsetWidth]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Headerwrapper $background={bgColor} $inStore={inStore} >
      {inStore && <StoreLogoWrapper justify='center' $elemWidth={elemWidth} ref={logoRef}>
        {headerLogo ? <LogoImage src={headerLogo} onClick={goToHome} />
          : <Button $asLink onClick={goToHome} >{ownerName}</Button>}
      </StoreLogoWrapper>}
      <HeaderBar>
        <Flex gap='10'>
          {loggedIn ?
            <Infowrapper>
              {/* <Avatar url={userInfo?.avatarUrl} name={userInfo.displayName} onClick={openUserMenu} size='small' /> */}
              <Typo.TextTitle >{title}</Typo.TextTitle>
            </Infowrapper>
            : <Button icon='Person' $asLink onClick={openLoginModal}>{t('auth.login')}</Button>
          }
        </Flex>
        {!!(cartItems || inStore) && <IconAsButton
          hasBadge={!!cartItems}
          tooltipLabel={t('globals.openCart')}
          badgeInfo={cartItems}
          iconName='Cart'
          variant='primary'
          color={theme.colors.NEUTRAL_PRIMARY}
          clickHandle={openCart}
        />}
      </HeaderBar>
    </Headerwrapper >
  );
};

export default Header;
