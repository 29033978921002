import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Modal, Typo } from '../../ui';
import styled from 'styled-components';

import { media } from '../../utils/media';
import MediaBreadCrumbs from './components/MediaBreadcrumbs';
import FoldersDirectory from './components/FoldersDirectory';
import SearchMediaData from './components/SearchMediaData';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedia, selectMediaBreadcrumbs, selectMediaByType, selectMediaFolders, selectStatus } from './mediaSlice';
import { useTranslation } from 'react-i18next';
import SimpleFile from './components/SimpleFile';
import { STATUS_FAILED } from '../../utils/constants';

const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  margin-top: 25px;
  overflow: auto;
  width:100%;
  cursor: pointer;

  ${media.lessThan('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const MediaPicker = ({
  saveAction,
  mediaType,
  isOpen,
  closeModal,
}) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const breadcrumbs = useSelector(selectMediaBreadcrumbs).slice().reverse();
  const [selectedFolder, setSelectedFolder] = useState('0');
  const [query, setSearchQuery] = useState('')
  const foldersData = useSelector((state) => selectMediaFolders(state, { query }));
  const mediaData = useSelector(state => selectMediaByType(state, { type: mediaType, query }));
  const mediaStatus = useSelector(selectStatus)


  const handleOpenFolder = (obj) => {
    setSearchQuery('')
    setSelectedFolder(obj?.id ? obj.id : '0')
  }

  useEffect(() => {
    isOpen && dispatch(fetchMedia({ directoryId: selectedFolder }));
  }, [dispatch, selectedFolder, isOpen])

  const pickFile = useCallback((e, file) => {
    saveAction({ id: file.id, url: file.url, name: file.name });
    closeModal(e);
  }, [saveAction, closeModal])

  const handlClose = (e) => {
    closeModal(e)
    setSelectedFolder(0)
  }

  return (
    <Modal
      width='600px'
      onClose={handlClose}
      open={isOpen}
    >
      <Flex direction='column' gap='20' align='flex-start' >
        <Flex direction='column' gap='20' align='flex-start' width='100%'>
          {/* we may need a hook for the search. need to investigate it */}
          <SearchMediaData setQuery={setSearchQuery} />

          <MediaBreadCrumbs onOpenFolder={handleOpenFolder} breadcrumbs={breadcrumbs} />
          <FoldersDirectory selectedFolder={selectedFolder} data={foldersData} openFolder={handleOpenFolder} />
        </Flex>
        <Flex direction='column' align='flex-start' width='100%'>
          <Typo.ContentSmallHeader $textAlign='left' >{t('mediaLibrary.files')}</Typo.ContentSmallHeader>
          {mediaStatus === STATUS_FAILED ?
            <Typo.Text $textAlign='center' >{t('globals.genericError')}</Typo.Text> : mediaData &&
            <FilesGrid>
              {mediaData?.map(file =>
                <SimpleFile file={file} key={file.id} onClick={(e) => pickFile(e, file)} />
              )}
            </FilesGrid >
          }
        </Flex>
      </Flex>
    </Modal>
  )
};

export default MediaPicker;
