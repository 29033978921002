import React from 'react';
import styled from 'styled-components'
import MiuAvatar from '@mui/material/Avatar';
import { Badge } from '@mui/material';
import { theme } from '..';

const sizes = { 'small': '40px', 'standard': '60px' }

const AvatarStyled = styled(MiuAvatar)` 
  cursor:${(props) => props.onClick && 'pointer'};
  img{
    object-fit:${(props) => props.$objectfit || 'cover'};
  }
`

const BadgeStyled = styled(Badge)` 
 & .MuiBadge-badge {
    background-color:${(props) => props.$color};
    height: 2rem;
    border-radius: 1rem;
    right:-5px;
    bottom:12px;
  }
`

const Avatar = ({ name, url, size, onClick, children, hasBadge, objectfit, bgcolor }) => {
  return (
    <BadgeStyled
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={hasBadge && children}
      $color={theme.colors.NEUTRAL_LIGHT}
    >
      <AvatarStyled
        $objectfit={objectfit}
        onClick={onClick}
        src={url}
        alt={name}
        sx={{ bgcolor: bgcolor, width: sizes[size] || sizes.standard, height: sizes[size] || sizes.standard }}
      >
        {!hasBadge && children}
      </AvatarStyled>
    </BadgeStyled>

  )
}

export default Avatar;
