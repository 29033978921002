import Reflux from 'reflux';

export default Reflux.createActions([
  'next',
  'previous',
  'reset',
  'restart',
  'selectCourses',
  'selectGroups',
  'selectStudents',
  'setInvitees'
]);
