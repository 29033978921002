import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, theme, Typo, PageHeaderLogo } from '../../ui';
import PageContainerVCenter from '../../ui/Page/PageContainerVCenter';


const CoursioDown = () => {

  const { t } = useTranslation();

  return (
    <Page backgroundColor={theme.colors.PRIMARY} fullheight={true}>
      <PageHeaderLogo />
      <PageContainerVCenter>
        <Typo.ContentSmallHeader $themeColor={theme.colors.PRIMARY} >{t('downPage.genericMessage')}</Typo.ContentSmallHeader>
      </PageContainerVCenter>
    </Page>
  );
};

export default CoursioDown;
