import { useLocation } from 'react-router';

export const useNewViewCourseId = () => {
    const location = useLocation();
    const urlSplit = location.pathname.split('/')
    return urlSplit[3];
}

export const useNewViewPageId = () => {
    const location = useLocation();
    const urlSplit = location.pathname.split('/')
    return urlSplit[4];
}

export const useSlugName = () => {
    const location = useLocation();
    const urlSplit = location.pathname.split('/')
    return urlSplit[3] === 'package' ? urlSplit[4] : urlSplit[3];
}

export const useCourseId = () => {
    const location = useLocation();
    return location.pathname.split('/')[2];
}

export const useIsBundle = () => {
    const location = useLocation();
    const urlSplit = location.pathname.split('/')
    return urlSplit[3] === 'package'
}

export const useModule = () => {
    const location = useLocation();
    const module = location.pathname.split("/")[1]
    const isEdit = module === 'store' && (location.pathname.split("/")[3] === 'edit' || location.pathname.split("/")[4] === 'edit')
    return `${module}${!!isEdit ? '_edit' : ''}`
}

export const useWebshopName = () => {
    const location = useLocation();
    return decodeURI(location.pathname.split("/")[2])
}
