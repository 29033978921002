import Reflux from 'reflux';

const TransactionActions = Reflux.createActions([
  'read',
  'readComplete',
  'findById',
  'findByIdError',
  'findByIdComplete',
  'genericError',
  'refund',
  'refundError',
  'refundComplete',
  'resend',
  'resendComplete',
  'export',
  'exportComplete'
]);

export default TransactionActions;
