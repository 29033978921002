import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useIsBundle, useSlugName, } from '../../hooks/readFromUrls';
import { Page, Loading } from '../../ui';
import { selectStoreName, selectCanIEdit, selectStoreOwner } from './globalSlice';
import { fetchCourse, selectCourseCoverMedia, selectCourseIsPublic, selectStatus } from './courseSlice';
import { selectIsIncarnated, selectIsLoggedIn } from '../auth/authSlice';
import { STATUS_LOADING } from '../../utils/constants';
import GATracker from "../../helpers/GATracker";
import SEO from '../../components/SEO';

const WebshopCourseContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [courseError, setCourseError] = useState(null);

  const coverMedia = useSelector(selectCourseCoverMedia);
  const loadCoursesStatus = useSelector(state => state.courses.status);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loadStatus = useSelector(selectStatus);
  const isIncarnated = useSelector(selectIsIncarnated)
  const storeOwner = useSelector(selectStoreOwner)

  const slugName = useSlugName()
  const isBundle = useIsBundle()

  const storeName = useSelector(selectStoreName)
  const isPublic = useSelector(selectCourseIsPublic)

  const isCourseEditor = useSelector(selectCanIEdit)

  useEffect(() => {
    if (!isLoggedIn && i18n.language !== storeOwner.locale) {
      i18n.changeLanguage(storeOwner.locale)
    }
  }, [storeOwner, i18n, isLoggedIn])

  useEffect(() => {
    storeName && slugName && dispatch(fetchCourse({ storeName, slugName, type: isBundle ? 2 : 1 }))
      .then((action) => {
        action.payload.errorCode &&
          setCourseError(action.payload.errorCode === 403 ?
            t('webshop.course.courseNotAvailable')
            : action.payload.response.errMsg)
      })

  }, [slugName, storeName, dispatch, isBundle, isLoggedIn, t, isIncarnated]);

  if (loadCoursesStatus !== 'succeeded') return null

  return (
    loadStatus === STATUS_LOADING ? <Loading /> :
      (!isPublic && !isCourseEditor) || courseError ? <Page>{courseError ? courseError : t('webshop.course.courseNotAvailable')}</Page> :
        <>
          <div className="course-main-section-container">
            <div className="mid-section-container">
              {/* {isPackage ? <ViewCoursePackage /> : <ViewCourse />} */}
              {children}
            </div>
          </div>
          <SEO title={storeName} image={coverMedia?.image?.url} name={storeName} type={'summary_large_image'} />
          <GATracker />
        </>
  );
};

export default WebshopCourseContainer;
