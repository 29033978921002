
import React from 'react';
import { Navigate, generatePath, useParams } from 'react-router-dom';

const NavigateWithParams = ({ to, ...props }) => {
  const params = useParams();
  return <Navigate {...props} to={generatePath(to, params)} />;
};

export default NavigateWithParams;
